import React from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";

export const customInputText = ({ customvalue, input, placeholder = "", disabled = false, label, id, type, autoComplete, classes, meta: { touched, error }, ...rest }, name) => {
	return (
		<CustomInput
			{...input}
			{...rest}
			labelText={label}
			id={id}
			name={name}
			value={input.value}
			formControlProps={{
				fullWidth: true
			}}
			inputProps={{
				placeholder: placeholder,
				"aria-label": placeholder,
				value: customvalue ? customvalue : input.value,
				onChange: input.onChange,
				disabled: disabled,
				type: { type },
				autoComplete: { autoComplete }
			}}
		/>
	);
};
