import React from 'react';
// MaterialUI
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// Components Core
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button.jsx';
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../inputs/typeText";
import {customInputSelect} from "../inputs/typeSelect";
import {Redirect} from "react-router-dom";
// Acciones
import {onerosoGetRequesting, onerosoFindById, onerosoSetCreditIdRequesting, clearCurrentOneroso} from './../../redux/oneroso/actions';
import notificationsStyle from "./../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

class FormOneroso extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const {detail,clearCurrentOneroso} = this.props;
        if (detail)
            this.handlePreloadOneroso(detail.id);
        else {
            clearCurrentOneroso();
        }
    }

    componentDidMount() {
        const {onerosoGetRequesting} = this.props;
        onerosoGetRequesting();
    }

    handleSubmit = (values) => {
        const {
            credit: {credit},
            oneroso: {currentOneroso},
            onerosoSetCreditIdRequesting
        } = this.props;
        onerosoSetCreditIdRequesting(currentOneroso.id, credit.id);
    };

    handleFindOnerosoById = (e) => {
        const {onerosoFindById} = this.props;
        onerosoFindById(e.target.value);
    };

    handlePreloadOneroso = (id) => {
        const {onerosoFindById} = this.props;
        onerosoFindById(id);
    };

    render() {
        const {
            client: {token},
            credit: {credit},
            oneroso: {oneroso, currentOneroso},
            handleSubmit,
            classes,
            policyForm
        } = this.props;
        return (
            <>
                <Card profile>
                    <CardBody>
                        <h1 className={classes.TitleH1Left}>Información oneroso</h1>
                        <Form onSubmit={handleSubmit(this.handleSubmit)}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"selectOneroso"}
                                        name={"selectOneroso"}
                                        type={"select"}
                                        label={"Seleccionar oneroso"}
                                        autoComplete={"on"}
                                        onChange={(e) => this.handleFindOnerosoById(e)}
                                        component={customInputSelect}
                                        data={oneroso}
                                        classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"nombre"}
                                        name={"nombre"}
                                        type={"text"}
                                        label={"Nombre *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"att"}
                                        name={"att"}
                                        type={"text"}
                                        label={"att *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"direccion"}
                                        name={"direccion"}
                                        type={"text"}
                                        label={"Dirección *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"telefonos"}
                                        name={"telefonos"}
                                        type={"number"}
                                        label={"Telefono *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"ciudad"}
                                        name={"ciudad"}
                                        type={"number"}
                                        label={"Ciudad *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"correo"}
                                        name={"correo"}
                                        type={"text"}
                                        label={"Correo *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        disabled={true}
                                        id={"nit"}
                                        name={"nit"}
                                        type={"text"}
                                        label={"nit *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                            </GridContainer>
                            <Button
                                disabled={!currentOneroso.hasOwnProperty("id") && policyForm.values.beneficiario_honeroso}
                                type={"submit"}
                                color="primary"
                                className={classes.updateProfileButton}
                                onClick={this.handleCleanValues}
                            >
                                {this.props.title || 'Siguiente'}
                            </Button>
                        </Form>
                    </CardBody>
                </Card>
            </>
        );
    }
}

FormOneroso.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
    initialValues: state.oneroso.currentOneroso,
    client: state.client,
    credit: state.credit,
    oneroso: state.oneroso,
    familyMember: state.familyMember,
    clients: state.clients,
    stateForm: state.form,
    policyForm: state.form.policy
});

const formed = reduxForm({
    form: "formOneroso",
    enableReinitialize: true,
    reset
})(withStyles(userProfileStyles)(FormOneroso));

const connected = connect(mapStateToProps, {
    onerosoGetRequesting,
    onerosoFindById,
    onerosoSetCreditIdRequesting,
    clearCurrentOneroso
})(withStyles(notificationsStyle)(formed));

export default connected;

