import React from 'react';
// Components Core
import Card from '../../Card/Card.jsx';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import Button from '../../CustomButtons/Button.jsx';
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../../inputs/typeText";
import {customInputSelect} from "../../inputs/typeSelect";
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {handleFormattedNumber} from "../../../helpers/number";
// Styles
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
// Acciones
import { legalPersonCreateRequesting , legalPersonUpdateRequesting , legalPersonCleanValues } from './../../../redux/legalPerson/actions';

class FormLegalPerson extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            egresos: '',
            ingresos: ''
        };
        this.handleCheckClientData = this.handleCheckClientData.bind(this);
        this.handleCheckClientData();
    }

    componentWillUnmount(){
        this.props.legalPersonCleanValues();
    }

    handleCheckClientData(){
        const {
            stateForm,
            clients: { client },
            legalPerson: { person }
        } = this.props;
        if (person.hasOwnProperty("id")){
            this.props.stateForm.legalPerson = {
                values: person
            };
        }
    }

    handleSubmit = (values) => {
        const {
            client: { token },
            clients: { client },
            personType,
            legalPersonCreateRequesting,
            legalPersonUpdateRequesting,
            legalPerson
        } = this.props;
        values.personType = personType;

        if (legalPerson.person.hasOwnProperty("id"))
            legalPersonUpdateRequesting(token,values,client.id,legalPerson.person.id);
        else
            legalPersonCreateRequesting(token,values,client.id);
    };

    render(){
        const {
            classes,
            handleSubmit,
            legalPerson,
            stateForm
        } = this.props;
        return (
            <Form ref={this.formRef} onSubmit={handleSubmit(this.handleSubmit)}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"nombreDeLaEmpresa"}
                            name={"nombreDeLaEmpresa"}
                            type={"text"}
                            label={"Nombre de la empresa *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"nit"}
                            name={"nit"}
                            type={"number"}
                            label={"NIT *"}
                            autoComplete={"on"}
                            value="not ksdsd"
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"tipo"}
                            name={"tipo"}
                            type={"select"}
                            label={"Empresa *"}
                            autoComplete={"on"}
                            component={customInputSelect} classes={classes}
                            data={[
                                {id: 1, nombre: "Publica"},
                                {id: 2, nombre: "Privada"},
                                {id: 3, nombre: "Mixta"}
                            ]}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"actividadEconomica"}
                            name={"actividadEconomica"}
                            type={"text"}
                            label={"Actividad economica *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"ciiu"}
                            name={"ciiu"}
                            type={"text"}
                            label={"CIIU *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"totalIngresosMensuales"}
                            name={"totalIngresosMensuales"}
                            type={"number"}
                            label={"Total ingresos mensuales *"}
                            autoComplete={"on"}
                            customvalue={this.state.ingresos}
                            onChange={(e) => {
                                this.setState({ingresos: handleFormattedNumber(e.target.value)})
                            }}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"totalEgresosMensuales"}
                            name={"totalEgresosMensuales"}
                            type={"number"}
                            label={"Total egresos mensuales *"}
                            autoComplete={"on"}
                            customvalue={this.state.egresos}
                            onChange={(e) => {
                                this.setState({egresos: handleFormattedNumber(e.target.value)})
                            }}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"numeroDeEmpleados"}
                            name={"numeroDeEmpleados"}
                            type={"number"}
                            label={"Numero de empleados *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"direccionEmpresa"}
                            name={"direccionEmpresa"}
                            type={"text"}
                            label={"Dirección empresa *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"barrio"}
                            name={"barrio"}
                            type={"text"}
                            label={"Barrio/Vereda/Localidad *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"departamento"}
                            name={"departamento"}
                            type={"text"}
                            label={"Departamento *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"ciudad"}
                            name={"ciudad"}
                            type={"text"}
                            label={"Ciudad *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"telefono"}
                            name={"telefono"}
                            type={"number"}
                            label={"Telefono fijo/celular *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"celular"}
                            name={"celular"}
                            type={"text"}
                            label={"Celular *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                        <Field
                            id={"correo"}
                            name={"correo"}
                            type={"text"}
                            label={"Correo *"}
                            autoComplete={"on"}
                            component={customInputText} classes={classes}
                        />
                    </GridItem>
                </GridContainer>
                {!legalPerson.person.hasOwnProperty("id") &&
                <Button
                        type={"submit"}
                        color="primary"
                        className={classes.updateProfileButton}
                >
                    Siguiente
                </Button>}

                {legalPerson.person.hasOwnProperty("id") &&
                <Button
                        type={"submit"}
                        color="success"
                        className={classes.updateProfileButton}
                >
                    Actualizar
                </Button>}
            </Form>
        );
    }
}

const mapStateToProps = state => ({
    client: state.client,
    legalPerson: state.legalPerson,
    clients: state.clients,
    stateForm: state.form
});

const connected = connect(mapStateToProps, {
    legalPersonCreateRequesting,
    legalPersonUpdateRequesting,
    legalPersonCleanValues
})(withStyles(userProfileStyles)(FormLegalPerson));

const formed = reduxForm({
    form: "legalPerson",
    reset
})(connected);

export default formed;
