export const SEND_EMAIL_REQUESTING = "SEND_EMAIL_REQUESTING";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";

export const SEND_TOKEN_REQUESTING = "SEND_TOKEN_REQUESTING";
export const SEND_TOKEN_SUCCESS = "SEND_TOKEN_SUCCESS";
export const SEND_TOKEN_ERROR = "SEND_TOKEN_ERROR";

export const RESET_PASSWORD_REQUESTING = "RESET_PASSWORD_REQUESTING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const FORGOT_RESET_STATES = "FORGOT_RESET_STATES";