/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import User from "@material-ui/icons/AccountBox";
import Identification from "@material-ui/icons/AssignmentInd";
import Phone from "@material-ui/icons/PhoneIphone";
import Address from "@material-ui/icons/Home";
import ShowPasswordIcon from '@material-ui/icons/Visibility';
import HidePasswordIcon from '@material-ui/icons/VisibilityOff';
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Background from "./../../assets/img/19154-[Convertido].jpg";
import Logo from "./../../assets/img/LogoNotLetter.png";

// @material-ui/icons
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";
// Integration
import { Field, Form, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { registerRequesting } from "../../redux/auth/register/actions";
import { identificationTypesGetRequesting } from "../../redux/identificationType/actions";
import { customInputSelect } from "../../components/inputs/typeSelect";
import DocumentsInput from "../../components/inputs/documents";
import ImagesInput from "../../components/inputs/images";
import { documentTypesGetRequesting } from "../../redux/documentType/actions";
import { citiesGetRequesting } from "../../redux/city/actions";
import { Redirect } from "react-router-dom";

const customInputEmail = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      name={name}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <Email className={classes.inputAdornmentIcon}/>
          </InputAdornment>
        )
      }}
    />
  );
};

const customInputName = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      name={name}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <User className={classes.inputAdornmentIcon}>
              lock_outline
            </User>
          </InputAdornment>
        )
      }}
    />
  );
};

const customInputIdentification = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      name={name}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <Identification className={classes.inputAdornmentIcon}>
              lock_outline
            </Identification>
          </InputAdornment>
        )
      }}
    />
  );
};

const customInputPhone = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <Phone className={classes.inputAdornmentIcon}>
              lock_outline
            </Phone>
          </InputAdornment>
        )
      }}
    />
  );
};

const customInputPassword = ({ input, show, setShow , label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <a href="#">
              {show && <ShowPasswordIcon onClick={() => {setShow(false)}} className={classes.inputAdornmentIcon}>
                lock_outline
              </ShowPasswordIcon>}
              {!show && <HidePasswordIcon onClick={() => {setShow(true)}} className={classes.inputAdornmentIcon}>
                lock_outline
              </HidePasswordIcon>}
            </a>
          </InputAdornment>
        ),
        type: show ? "text" : "password",
        autoComplete: "off"
      }}
    />
  );
};

const customInputAddress = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <Address className={classes.inputAdornmentIcon}>
              lock_outline
            </Address>
          </InputAdornment>
        )
      }}
    />
  );
};

class CheckInPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      checked: []
    };
    const {
      identificationTypesGetRequesting,
      documentTypesGetRequesting,
      citiesGetRequesting
    } = this.props;
    identificationTypesGetRequesting();
    // documentTypesGetRequesting();
    citiesGetRequesting();
    this.handleSetShow = this.handleSetShow.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
    this.state = {
      show: false,
      viewLogin: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  handleSubmit = (values) => {
    const {
      registerRequesting,
      identificationType: { identificationTypes }
    } = this.props;
    let cc = identificationTypes.find((identificationType) => identificationType.nombre === "Cédula de Ciudadanía").id;
    let typeIdentification = values.hasOwnProperty("tipo_identificacion") ? values.tipo_identificacion : cc;
    registerRequesting(values, typeIdentification);
  };

  handleViewLogin = () => {
    this.setState({
      viewLogin: true
    });
  };

  handleSetShow = (value) => {
    this.setState({
      show: value
    });
  }

  render() {
    const {
      classes,
      handleSubmit,
      identificationType: { identificationTypes },
      documentType: { documentTypes },
      city: { cities },
      register: { success }
    } = this.props;
    if (success) {
      return <Redirect to={"/"}/>;
    }
    if (this.state.viewLogin) {
      return (
        <Redirect to={"/"}/>
      );
    }
    return (
      <div className="login-back" style={{
        backgroundImage: `url(${Background})`, backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}>
        <div className={classes.container}>
          <GridContainer justify="center">

            <GridItem xs={12} sm={6} md={4}>
              <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Card login className={classes[this.state.cardAnimaton]}>

                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <GridContainer>

                      <GridItem className="style_img_login" xs={12} sm={6} md={12}>
                        <div style={{backgroundColor: "#fff", overflow: "hidden", width: "50%", paddingTop: 5, paddingBottom: 5, borderRadius: 7, margin: "auto"}}>
                           <img src={Logo} alt="Logo"/>
                        </div>
                      </GridItem>

                      <GridItem className="style_text" xs={12} sm={6} md={12}>
                        <br/>
                        <h2 className={classes.cardTitle} style={{color: "#fff"}}>Danko</h2>
                        <h4 className={classes.cardTitle} style={{color: "#fff"}}>Registro como asesor</h4>
                      </GridItem>

                    </GridContainer>
                  </CardHeader>

                  <CardBody>
                    {/*<p>Foto/Logo </p>*/}
                    {/*<Field name={"photos"} maxFiles={1} className={"filepond"}*/}
                           {/*component={ImagesInput}/>*/}
                    <Field classes={classes} id={"name"} name={"nombre"} label={"Nombre"}
                           type={"text"} component={customInputName}/>
                    <Field classes={classes} id={"email"} name={"correo"} label={"Correo"}
                           type={"text"} component={customInputEmail}/>
                    {identificationTypes.length > 0 && (
                      <Field id={"identificationType"} name={"tipo_identificacion"} type={"select"}
                             label={"Tipo de identificación"}
                             autoComplete={"on"} component={customInputSelect} classes={classes}
                             data={identificationTypes}/>
                    )}
                    <Field classes={classes} id={"identification"} name={"identificacion"}
                           label={"Identificación"} type={"number"}
                           component={customInputIdentification}/>
                    <Field classes={classes} id={"phone"} name={"telefono"}
                           label={"Teléfono"} type={"number"}
                           component={customInputPhone}/>
                    <Field classes={classes} id={"password"} name={"contraseña"}
                           label={"Contraseña"} type={"password"}
                           show={this.state.show} setShow={this.handleSetShow}
                           component={customInputPassword}/>
                    {cities.length > 0 && (
                      <Field id={"city"} name={"ciudad"} type={"select"}
                             label={"Ciudad"}
                             autoComplete={"on"} component={customInputSelect} classes={classes}
                             data={cities}/>
                    )}
                    <Field classes={classes} id={"address"} name={"direccion"}
                           label={"Dirección"} type={"text"}
                           component={customInputAddress}/>
                    {/*<p>Documentos</p>*/}
                    {/*<p>Cédula de identificación *</p>*/}
                    {/*<Field name={"document_Cédula de identificación"} maxFiles={1}*/}
                           {/*className={"filepond"}*/}
                           {/*component={DocumentsInput}*/}
                           {/*label={"<span class=\"filepond--label-action\">Subir</span>"}/>*/}
                  </CardBody>


                  <CardFooter className={classes.justifyContentCenter}>
                    <Button color={"primary"} block onClick={() => this.handleViewLogin()}>
                      ¿Ya tienes una cuenta?
                    </Button>
                    <Button color="primary" type={"submit"} block>
                      Registrarse
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </GridItem>


          </GridContainer>
        </div>
      </div>
    );
  }
}

CheckInPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  identificationType: state.identificationType,
  documentType: state.documentType,
  city: state.city,
  register: state.register
});

const connected = connect(mapStateToProps, {
  registerRequesting,
  identificationTypesGetRequesting,
  documentTypesGetRequesting,
  citiesGetRequesting
})(withStyles(loginPageStyle)(CheckInPage));

const formed = reduxForm({
  form: "create"

})(connected);


export default formed;
