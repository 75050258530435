import {
	DOCUMENTS_CREATE_ERROR,
	DOCUMENTS_CREATE_REQUESTING, DOCUMENTS_CREATE_SUCCESS,
	DOCUMENTS_USER_GET_ERROR,
	DOCUMENTS_USER_GET_REQUESTING,
	DOCUMENTS_USER_GET_SUCCESS,
	DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING,
	DOCUMENT_FINANCING_PACKAGE_CREATE_SUCCESS,
	DOCUMENT_FINANCING_PACKAGE_CREATE_ERROR,
	DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING,
	DOCUMENT_CHECK_DIGITAL_SIGNATURE_SUCCESS,
	DOCUMENT_CHECK_DIGITAL_SIGNATURE_ERROR,
	DOCUMENT_AUTHORIZE_CREATE_REQUESTING,
	DOCUMENT_AUTHORIZE_CREATE_SUCCESS,
	DOCUMENT_AUTHORIZE_CREATE_ERROR,
	DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING,
	DOCUMENT_SENT_MAIL_SIGNATURE_SUCCESS,
	DOCUMENT_SENT_MAIL_SIGNATURE_ERROR,
} from "./constants";

const initialState = {
	id: "",
	requesting: false,
	success: false,
	error: "",
	documentsUser: [],
	verifyData: ""
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case DOCUMENTS_USER_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
				documentsUser: []
			};
		case DOCUMENTS_USER_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				documentsUser: action.documents
			};
		case DOCUMENTS_USER_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		case DOCUMENTS_CREATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
			};
		case DOCUMENTS_CREATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
			};
		case DOCUMENTS_CREATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		case DOCUMENT_FINANCING_PACKAGE_CREATE_REQUESTING:
		    return {
		    	...state,
		    	requesting: true,
		    	success: false,
		    	error: ""
		    };	
		case DOCUMENT_FINANCING_PACKAGE_CREATE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: ""
		    };
		case DOCUMENT_FINANCING_PACKAGE_CREATE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    }; 
		case DOCUMENT_AUTHORIZE_CREATE_REQUESTING:
		    return {
		    	...state,
		    	requesting: true,
		    	success: false,
		    	error: ""
		    };
		case DOCUMENT_AUTHORIZE_CREATE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: ""
		    };
		case DOCUMENT_AUTHORIZE_CREATE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };
		case DOCUMENT_CHECK_DIGITAL_SIGNATURE_REQUESTING:
		    return {
		    	...state,
		    	requesting: true,
		    	success: false,
		    	error: ""
		    };       
		case DOCUMENT_CHECK_DIGITAL_SIGNATURE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	verifyData: action.verifyData,
		    	id: action.documentId
		    };
		case DOCUMENT_CHECK_DIGITAL_SIGNATURE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };  
		case DOCUMENT_SENT_MAIL_SIGNATURE_REQUESTING:
		    return {
		    	...state,
		    	requesting: true,
		    	success: false,
		    	error: ""
		    };
		case DOCUMENT_SENT_MAIL_SIGNATURE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: ""
		    }; 
		case DOCUMENT_SENT_MAIL_SIGNATURE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };
		default:
			return state;
	}
};

export default reducer;