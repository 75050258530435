import { call, put, all, takeEvery } from "redux-saga/effects";
import { CHECK_AUTH,SAVE_DEVICE,DELETE_DEVICE} from "./constans";
import { checkAuthError, checkAuthSuccess } from "./actions";
import { handleApiErrors } from "../../../commons/errors/apiErrors";
import { meGetRequesting } from "../user/actions";
import { statesGeneralGetRequesting } from "../../state/actions";

const checkJWT = `${process.env.REACT_APP_API_URL}/verifyAgent`;
const SAVE_DEVICE_URL = `${process.env.REACT_APP_API_URL}/user/devices`;
const DELETE_DEVICE_URL = `${process.env.REACT_APP_API_URL}/users/device/delete`;


const checkTokenJWT = () => {
	let token = localStorage.getItem("@danko:agent");
	return fetch(checkJWT, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: "Bearer" + token
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 200)
				return token;
			if (json.code === 400) {
				localStorage.removeItem("@danko:agent");
				throw json.data.data;
			}
		}).catch((error) => {
			throw error;
		});
};


function* checkAuthFlow() {
	try {
		const token = yield call(checkTokenJWT);
		yield put(checkAuthSuccess(token));
		yield put(meGetRequesting(token));
		yield put(statesGeneralGetRequesting(token));

	} catch (error) {
		yield put(checkAuthError(error));
	}
}

const saveDeviceAPI = (token, values) => {

	let data = {
		device: values,
	};
	return fetch(SAVE_DEVICE_URL, {

		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		},
		body: JSON.stringify(data)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.data.code === 400)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			else
				throw ''
		}).catch((error) => {
			throw error;
		})
};

function* saveDeviceFlow(action) {
	try {
		const {token, values} = action;
		yield call(saveDeviceAPI, token, values);
	} catch (error) {
	}
}

const deleteDeviceAPI = (token) => {

	return fetch(DELETE_DEVICE_URL, {

		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token
		},
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.data.code === 400)
				throw json.data;
			if (json.code === 200)
				throw json.data.data;
			else
				throw ''
		}).catch((error) => {
			throw error;
		})
};

function* deleteDeviceFlow(action) {
	try {
		const {token} = action;
		yield call(deleteDeviceAPI, token);
	} catch (error) {
	}
}

function* checkAuthWatcher() {
	yield all([
		takeEvery(CHECK_AUTH, checkAuthFlow),
		takeEvery(SAVE_DEVICE, saveDeviceFlow),
		takeEvery(DELETE_DEVICE, deleteDeviceFlow),
	]);
}

export default checkAuthWatcher;