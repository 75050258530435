// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
import {connect} from "react-redux";
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../../../components/inputs/typeText";
import GridItem from "../../../components/formQuote/client";
import {customInputSelect} from "../../../components/inputs/typeSelect";
import {clientUpdateRequesting} from "../../../redux/client/actions";
import {customInputDate} from "../../../components/inputs/inputDate";
import {identificationTypesGetRequesting} from "../../../redux/identificationType/actions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class InfoClient extends React.Component {

    handleSubmit = (values) => {
        const {clientUpdateRequesting, client: {token}} = this.props;
        clientUpdateRequesting(token, values);
    };


    componentDidMount() {
        const {identificationTypesGetRequesting} = this.props;
        identificationTypesGetRequesting();
    }

    render() {
        const {
            classes,
            open,
            controlModal,
            client,
            identificationType: {identificationTypes},
            city: {cities},
            handleSubmit,
            identificationTypesGetRequesting
        } = this.props;
        return <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => controlModal("clientModal")}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <MailOutline/>
                </CardIcon>
            </CardHeader>

            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => controlModal("clientModal")}
                >
                    <Close className={classes.modalClose}/>
                </Button>
                <h4 className={classes.modalTitle}>Información del cliente</h4>
            </DialogTitle>
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}>

                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}>
                        <div className="Conteiner-table">
                            <table className="table-date">
                                <p>Datos registrados</p>
                                <tbody>
                                <tr>
                                    <th>Nombres</th>
                                    <td>
                                        <Field
                                            id={"nombres"}
                                            name={"nombres"}
                                            type={"text"}
                                            autoComplete={"off"}
                                            component={customInputText}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Apellidos</th>
                                    <td>
                                        <Field
                                            id={"apellidos"}
                                            name={"apellidos"}
                                            type={"text"}
                                            autoComplete={"off"}
                                            component={customInputText}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Tipo de identificación</th>
                                    <td>
                                        <Field
                                            id={"tipo_identificacion"}
                                            name={"tipo_identificacion"}
                                            type={"text"}
                                            autoComplete={"off"}
                                            component={customInputSelect}
                                            classes={classes}
                                            data={identificationTypes}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Nit o cedula</th>
                                    <td>
                                        <Field
                                            id={"identificacion"}
                                            name={"identificacion"}
                                            type={"number"}
                                            autoComplete={"off"}
                                            component={customInputText}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Dirección</th>
                                    <td>
                                        <Field
                                            id={"direccion"}
                                            name={"direccion"}
                                            type={"text"}
                                            autoComplete={"off"}
                                            component={customInputText}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Ciudad</th>
                                    <td>
                                        <Field id={"ciudad"}
                                               name={"ciudad"}
                                               type={"select"}
                                               autoComplete={"on"}
                                               component={customInputText}
                                               classes={classes}
                                               data={cities}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Telefono / Celular</th>
                                    <td>
                                        <Field id={"telefono"}
                                               name={"telefono"}
                                               type={"number"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Correo electronico</th>
                                    <td>
                                        <Field
                                            id={"correo"}
                                            name={"correo"}
                                            type={"email"}
                                            autoComplete={"off"}
                                            component={customInputText}/>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Fecha de nacimiento</th>
                                    <td>
                                        <Field id={"fecha_nacimiento"}
                                               name={"fecha_nacimiento"}
                                               type={"date"}
                                               dateInitial={null}
                                               component={customInputDate}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <DialogActions className={classes.modalFooter}>
                            <Button
                                type={'submit'}
                                color="primary"
                            >
                                Guardar
                            </Button>
                            <Button
                                onClick={() => controlModal("clientModal")}
                                color="warning"
                            >
                                Cerrar
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </DialogContent>
            </Form>
        </Dialog>;

    }
}

InfoClient.propTypes = {
    classes: PropTypes.object
};

const formed = reduxForm({
    form: "infoClient",
    enableReinitialize: true,
    reset
})(withStyles(userProfileStyles)(InfoClient));

const mapStateToProps = state => ({
    initialValues: state.credit.client,
    client: state.client,
    identificationType: state.identificationType,
    city: state.city,
    stateForm: state.form,
});

const connected = connect(mapStateToProps, {
    clientUpdateRequesting,
    /*sureTypeFeaturesPolicyRequesting,
    policyUpdateRequesting,
    featuresSureTypeGetRequesting*/
    identificationTypesGetRequesting
})(withStyles(notificationsStyle)(formed));

export default connected;

