import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../../commons/errors/apiErrors";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import { verifyEmailError, verifyEmailSuccess } from "./actions";
import { VERIFY_EMAIL_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/verifyEmail`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const verifyEmailApi = (tokenEmail) => {
	return fetch(`${baseUrl}?token=${tokenEmail}`, {
		method: "PUT"
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 400)
				throw [json.data];
			if (json.code === 422)
				throw json.data;
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* verifyEmailFlow(action) {
	try {
		const { tokenEmail } = action;
		yield call(verifyEmailApi, tokenEmail);
		toastr.success("Correo verificado", "El correo fue verificado correctamente.");
		yield put(verifyEmailSuccess());
	} catch (error) {
		yield put(verifyEmailError(error));
		showError(error);
	}
}

function* verifyEmailWatcher() {
	yield all([
		takeEvery(VERIFY_EMAIL_REQUESTING, verifyEmailFlow),
	])
}

export default verifyEmailWatcher;