// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
// Integration
import { connect } from "react-redux";
import DocumentsInput from "../../../components/inputs/documents";
import { documentsCreateRequesting } from "../../../redux/document/actions";
import { Form, reduxForm, Field, reset} from "redux-form";
import { customInputText } from "../../../components/inputs/typeText";
import { customInputSelect } from "../../../components/inputs/typeSelect";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class OnerosoNotification extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            actionModal: 'oneroso'
        }
    }

    handleViewDocument = (url) => {
        window.open(url, "_blank");
    };

    handleSubmitOneroso(values){
        this.setState({ actionModal: 'documents' });
    }

    handleOpenDocument(url){
        let env = `${process.env.REACT_APP_API_URL}/${url}`;
        window.open(env,'_blank');
    }

    handleSubmit = async (values) => {
        const {
            client: { token },
            documentsCreateRequesting,
            credit: { credit },
            controlModal,
            handleUpdateOnerosoState
        } = this.props;
        await documentsCreateRequesting(token, "onerosoNotification", credit.id, values);
        controlModal();
        handleUpdateOnerosoState();
    };

    render() {
        const {
            classes,
            open,
            controlModal,
            handleSubmit,
            credit,
            documentType,
            oneroso: { oneroso } ,
            stateForm
        } = this.props;

        let documentTypes = documentType.documentTypes;
        let documents = credit.documents;
        documents = documents.filter((document) => document.tipo_documento.nombre == 'Póliza' || document.tipo_documento.nombre == 'Cédula de identificación' || document.tipo_documento.nombre == 'Contrato mandato');
        documentTypes = documentTypes.filter((documentType) => documentType.nombre == 'Contrato mandato' || documentType.nombre == 'Póliza' || documentType.nombre == 'Cédula de identificación');
        return <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => controlModal("onerosoNotification")}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
        >
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <MailOutline/>
                </CardIcon>
            </CardHeader>

            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => controlModal("onerosoNotification")}
                >
                    <Close className={classes.modalClose}/>
                </Button>
                <h4 className={classes.modalTitle}>{this.state == 'documents' ? 'Notificación Oneroso' : 'Documentos'}</h4>
            </DialogTitle>
            <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}>
                <DialogContent id="classic-modal-slide-description"
                               className={classes.modalBody}>
                    <div className="Conteiner-table">
                        {this.state.actionModal == 'documents' && (
                            <>
                                <table className="table-date">
                                    <tbody>
                                    {documents.map((document, index) => (
                                        <tr key={index}>
                                            <th>{document.tipo_documento.nombre}{document.tipo_documento.requerido ? "*" : ""}</th>
                                            <td>
                                                <Button color={"warning"}
                                                        onClick={() => this.handleViewDocument(document.url)}>
                                                    Ver
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <th>Cancelación póliza de seguro Pagaré</th>
                                        <td>
                                            <Button color={"warning"}
                                                    onClick={() => this.handleOpenDocument(`documents/cancellation/${credit.credit.id}`)}>
                                                Ver
                                            </Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> Notificación revocación Póliza </th>
                                        <td>
                                            <Button color={"warning"}
                                                    onClick={() => this.handleOpenDocument(`documents/oneroso/${credit.credit.id}/${stateForm.oneroso.values.id}`)}>
                                                Ver
                                            </Button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <Form onSubmit={handleSubmit(this.handleSubmit)}>
                                    <div className="Conteiner-table" style={{ marginTop: "0px" }}>
                                        <table className="table-date">
                                            <tbody>
                                            {documentTypes.map((documentType, index) => {
                                                if (!documents.find((document) => document.tipo_documento.id === documentType.id)) {
                                                    return (
                                                        <tr key={index}>
                                                            <th>{documentType.nombre}{documentType.requerido ? "*" : ""}</th>
                                                            <td>
                                                                <Field name={`document_${documentType.nombre}`} maxFiles={1}
                                                                       className={"filepond"}
                                                                       component={DocumentsInput}
                                                                       label={"<span class=\"filepond--label-action\">Subir</span>"}/>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <DialogActions className={classes.modalFooter}>
                                        <Button onClick={() => this.setState({ actionModal: 'oneroso' })} color={"primary"} className={classes.registerButton} type={"button"}>
                                            Atras
                                        </Button>
                                        <Button onClick={() => controlModal("onerosoNotification")} color="warning">
                                            Cerrar
                                        </Button>
                                        {stateForm.hasOwnProperty("oneroso") && stateForm.oneroso.hasOwnProperty("values") && stateForm.oneroso.values["document_Contrato mandato"] && (
                                            <Button color="primary" className={classes.registerButton} type={"submit"}>
                                                Agregar
                                            </Button>
                                        )}
                                    </DialogActions>
                                </Form></>)}

                        {this.state.actionModal === "oneroso" && (
                            <>
                                <Form onSubmit={handleSubmit(this.handleSubmitOneroso.bind(this))}>
                                    <div className={"container-table"}>
                                        <table className="table-date">
                                            <tr>
                                                <th>Nombre</th>
                                                <td>
                                                    <Field
                                                        id={"nombre_oneroso"}
                                                        name={"nombre_oneroso"}
                                                        type={"select"}
                                                        autoComplete={"off"}
                                                        onChange={(e) => {
                                                            const findOneroso = oneroso.find((type) => type.id === e.target.value);
                                                            stateForm.oneroso.values = {
                                                                codigo: findOneroso.correo,
                                                                direccion: findOneroso.direccion,
                                                                id: e.target.value
                                                            }
                                                        }}
                                                        data={oneroso}
                                                        component={customInputSelect}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Código</th>
                                                <td>
                                                    <Field
                                                        id={"codigo"}
                                                        name={"codigo"}
                                                        type={"text"}
                                                        autoComplete={"off"}
                                                        component={customInputText}
                                                        disabled={true}
                                                        placeholder={"Correo"}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Dirección</th>
                                                <td>
                                                    <Field
                                                        id={"direccion"}
                                                        name={"direccion"}
                                                        disabled={true}
                                                        type={"text"}
                                                        autoComplete={"off"}
                                                        component={customInputText}
                                                        placeholder={"Dirección"}/>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    {stateForm.hasOwnProperty("oneroso") && stateForm.oneroso.hasOwnProperty("values") && stateForm.oneroso.values.hasOwnProperty("codigo") && stateForm.oneroso.values.hasOwnProperty("direccion") && (
                                        <Button color="primary" className={classes.registerButton} type={"submit"}>
                                            Siguiente
                                        </Button>
                                    )}

                                </Form></>)}
                    </div>
                </DialogContent>
            </DialogContent>
        </Dialog>;
    }
}

OnerosoNotification.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
    client: state.client,
    documentType: state.documentType,
    oneroso: state.oneroso,
    credit: state.credit,
    stateForm: state.form
});

const connected = connect(mapStateToProps, {
    documentsCreateRequesting,
})(withStyles(notificationsStyle)(OnerosoNotification));

const formed = reduxForm({
    form: "oneroso"
})(connected);

export default formed;