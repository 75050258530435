import React from "react";
// MaterialIU
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
// Components Core
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Button from "../CustomButtons/Button.jsx";
import {Redirect} from "react-router-dom";
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// Integration
import {
    clientFindIdentificationRequesting,
    clientResetStates,
    clientResetFocus,
    clientUpdateRequesting,
    clientCreateRequesting,
    clientCleanValues
} from "../../redux/client/actions";
import {identificationTypesGetRequesting} from "../../redux/identificationType/actions";
import {citiesGetRequesting} from "../../redux/city/actions";
import {creditUpdateRequesting} from "../../redux/credit/actions";
import {policyUpdateRequesting} from "../../redux/policy/actions";
import {featuresSureTypeGetRequesting} from "../../redux/feature/actions";
import {connect} from "react-redux";
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../inputs/typeText";
import {customInputSelect} from "../inputs/typeSelect";
import {customInputDate} from "../inputs/inputDate";

class FormClient extends React.Component {

    constructor(props) {
        super(props);
        const {
            client: {token},
            identificationTypesGetRequesting,
            clientFindIdentificationRequesting,
            citiesGetRequesting,
            identificationType: {identificationTypes},
            credit,
        } = this.props;
        identificationTypesGetRequesting();
        citiesGetRequesting();
        this.state = {
            verify: credit.client ? true : false,
            redirect: false
        };

        if (credit.exists)
            clientFindIdentificationRequesting(token, credit.client, credit.client.tipo_identificacion.id, credit.credit.id);
    }

    componentWillUnmount() {
        this.props.clientCleanValues();
    }

    handleSubmit = (values) => {
        const {
            client: {token},
            clientFindIdentificationRequesting,
            clientUpdateRequesting,
            clientCreateRequesting,
            identificationType: {identificationTypes},
            clients: {client},
            credit: {credit}
        } = this.props;
        let cc = identificationTypes.find((identificationType) => identificationType.nombre === "Cédula de Ciudadanía").id;
        let typeIdentification = values.hasOwnProperty("tipo_identificacion") ? values.tipo_identificacion : cc;
        if (values.hasOwnProperty("nombres")) {
            if (values.hasOwnProperty("id")) {
                clientUpdateRequesting(token, values);
            } else {
                clientCreateRequesting(token, values, typeIdentification, credit.id);
            }
        } else {
            clientFindIdentificationRequesting(token, values, typeIdentification, credit.id);
            this.setState({
                verify: true
            });
        }
    };

    handleCreditUpdate = () => {
        const {
            client: {token},
            credit: {credit},
            clients: {client},
            creditUpdateRequesting,
        } = this.props;
        let values = {
            clientId: client.id,
        };
        creditUpdateRequesting(token, credit.id, values);
    };

    handlePolicyUpdate = () => {
        const {
            policyUpdateRequesting,
            creditUpdateRequesting,
            client: {token},
            policy: {policy},
            clients: {client},
            credit: {credit},
            stateForm
        } = this.props;
        this.setState({redirect: true});
        //let values = stateForm.hasOwnProperty("editClient") && stateForm.editClient.hasOwnProperty("values") ? stateForm.editClient.values : {};
        //policyUpdateRequesting(token, policy.id, values);
        let valuesData = {
            clientId: client.id
        };
        creditUpdateRequesting(token, credit.id, valuesData);
    };

    handleNewValidate = () => {
        const {
            reset,
            clientResetStates,
            clientResetFocus
        } = this.props;
        clientResetFocus();
        reset("editClient");
        clientResetStates();
    };

    /* handleGetFeatures = (e) => {
       const {
         client: { token },
         featuresSureTypeGetRequesting
       } = this.props;
       featuresSureTypeGetRequesting(token, e.target.value);
     };*/

    render() {
        const {
            classes,
            handleSubmit,
            identificationType: {identificationTypes},
            initialValues,
            stateForm,
            clients,
            city: {cities},
            credit: {credit},
            insurance: {insurances},
            sureType: {sureTypes},
            feature: {features},
            policy: {policy}
        } = this.props;
        const {redirect} = this.state;
        const formClient = stateForm ? stateForm.hasOwnProperty("editClient") && stateForm.editClient.hasOwnProperty("values") ? stateForm.editClient.values : {} : {};
        const validateOther = (initialValues.hasOwnProperty("nombres") || clients.error !== "");
        /*if (policy.hasOwnProperty("nombre_beneficiario") && policy.nombre_beneficiario !== null) {
          this.handleCreditUpdate();
        }*/

        /*if (redirect){
          return (
              <Redirect to={"/admin/misSolicitudes"}/>
          );
        }*/
        return (
            <Card profile>
                <CardBody>
                    <h1 className={classes.TitleH1Left}>Datos del cliente</h1>
                    <Form onSubmit={handleSubmit(this.handleSubmit)}>
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                {identificationTypes.length > 0 && (
                                    <Field
                                        disabled={!credit.hasOwnProperty("id")}
                                        id={"tipo_identificacion"} name={"tipo_identificacion"} type={"select"}
                                        label={"Tipo de identificación *"}
                                        autoComplete={"on"} component={customInputSelect} classes={classes}
                                        data={identificationTypes}/>
                                )}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <Field
                                    disabled={!credit.hasOwnProperty("id")}
                                    id={"identificacion"} name={"identificacion"} type={"number"}
                                    label={"Identificación *"}
                                    autoComplete={"off"}
                                    component={customInputText}/>
                            </GridItem>
                            {/*<GridItem xs={12} sm={4} md={2}>
                {!initialValues.hasOwnProperty("nombres") && clients.error === "" && (
                  <Button color={"primary"} type={"submit"}>
                    Verificar
                  </Button>
                )}
                {validateOther && (
                  <Button color={"warning"} onClick={() => {
                    this.handleNewValidate();
                    this.setState({
                      verify: false
                    });
                  }}>
                    Verificar otro
                  </Button>
                )}
              </GridItem>*/}
                            {this.state.verify && (
                                <>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Field disabled={!credit.hasOwnProperty("id")} id={"nombres"} name={"nombres"}
                                               type={"text"}
                                               label={"Nombres *"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Field disabled={!credit.hasOwnProperty("id")} id={"apellidos"}
                                               name={"apellidos"} type={"text"}
                                               label={"Apellidos *"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Field disabled={!credit.hasOwnProperty("id")} id={"correo"} name={"correo"}
                                               type={"email"}
                                               label={"Correo *"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Field disabled={!credit.hasOwnProperty("id")} id={"telefono"} name={"telefono"}
                                               type={"number"}
                                               label={"Telefono *"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Field disabled={!credit.hasOwnProperty("id")} id={"direccion"}
                                               name={"direccion"} type={"text"}
                                               label={"Dirección *"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        {cities.length > 0 && (
                                            <Field disabled={!credit.hasOwnProperty("id")} id={"ciudad"} name={"ciudad"}
                                                   type={"text"}
                                                   label={"Ciudad *"}
                                                   autoComplete={"on"} component={customInputText} classes={classes}
                                                   data={cities}/>
                                        )}
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Field disabled={!credit.hasOwnProperty("id")} id={"fecha_nacimiento"}
                                               name={"fecha_nacimiento"}
                                               type={"date"}
                                               dateInitial={null}
                                               label={"Fecha de nacimiento *"} component={customInputDate}/>
                                        <br/>
                                    </GridItem>
                                    {/*  {clients.client.hasOwnProperty("id") && (
                  <GridItem xs={12} sm={6} md={6}>
                  <Field disabled={!clients.client.hasOwnProperty("id")} id={"pagare"} name={"pagare"} type={"text"}
                  label={"Pagaré *"}
                  autoComplete={"off"}
                  component={customInputText} />
                  </GridItem>
                  )}*/}
                                </>
                            )}
                            {/*{clients.client.hasOwnProperty("id") && (
                <>
                  <GridItem xs={12} sm={6} md={6}>
                    {insurances.length > 0 && (
                      <Field id={"aseguradora"}
                             name={"aseguradora"} type={"select"}
                             label={"Aseguradora *"}
                             autoComplete={"on"} component={customInputSelect} classes={classes}
                             data={insurances}/>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Field id={"numero"} name={"numero"}
                           type={"number"} label={"Numero de poliza *"}
                           autoComplete={"off"}
                           component={customInputText}/>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6}>
                    <Field id={"nombre_beneficiario"}
                           name={"nombre_beneficiario"} type={"text"} label={"Nombre del beneficiario *"}
                           autoComplete={"off"}
                           component={customInputText}/>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    {sureTypes.length > 0 && (
                      <Field id={"tipo_seguro"}
                             name={"tipo_seguro"} type={"select"}
                             label={"Tipo de seguro *"}
                             onChange={(e) => this.handleGetFeatures(e)}
                             autoComplete={"on"} component={customInputSelect} classes={classes}
                             data={sureTypes}/>
                    )}
                  </GridItem>
                  {features.length > 0 && features.map((feature, index) => (
                    <GridItem key={index} xs={12} sm={6} md={6}>
                      <Field id={feature.nombre}
                             name={`feature_${feature.nombre}`} type={"text"}
                             label={`${feature.nombre} *`}
                             autoComplete={"off"}
                             component={customInputText}/>
                    </GridItem>
                  ))}
                </>
              )}*/}
                        </GridContainer>
                        {this.state.verify && (
                            <>
                                <Button disabled={!formClient.hasOwnProperty("apellidos")}
                                        color={initialValues.hasOwnProperty("id") ? "warning" : "primary"}
                                        className={classes.updateProfileButton} type={"submit"}>
                                    {initialValues.hasOwnProperty("id") ? "Actualizar" : "Crear"}
                                </Button>
                            </>
                        )}
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

FormClient.propTypes = {
    classes: PropTypes.object
};

const formed = reduxForm({
    form: "editClient",
    enableReinitialize: true,
    reset
})(withStyles(userProfileStyles)(FormClient));

const mapStateToProps = state => ({
    client: state.client,
    clients: state.clients,
    credit: state.credit,
    identificationType: state.identificationType,
    initialValues: state.clients.client,
    stateForm: state.form,
    city: state.city,
    insurance: state.insurance,
    sureType: state.sureType,
    feature: state.feature,
    policy: state.policy
});

const connected = connect(mapStateToProps, {
    clientFindIdentificationRequesting,
    identificationTypesGetRequesting,
    clientResetStates,
    clientResetFocus,
    citiesGetRequesting,
    clientUpdateRequesting,
    clientCreateRequesting,
    clientCleanValues,
    creditUpdateRequesting,
    policyUpdateRequesting,
    featuresSureTypeGetRequesting
})(formed);

export default connected;
