import React from "react";
// MaterialIU
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// Components Core
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button.jsx";
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// Integration
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../inputs/typeText";
import DocumentsInput from "../inputs/documents";
import {documentTypesGetRequesting} from "../../redux/documentType/actions";
import {creditCreateRequesting, planPaymentsCredit, creditGetRequesting , creditCleanValues} from "../../redux/credit/actions";
import {configurationsGetRequesting} from "../../redux/configuration/actions";
import {customInputSelect} from "../inputs/typeSelect";
import {Redirect} from "react-router-dom";
import {policyResetFocus, policyDeleteRequesting} from "../../redux/policy/actions";
import FirebaseMessage from '../../pushNotification/push';
import ModalClientData from './Modal/modalClientData';
import {messaging} from "../../init-fcm";

import {FormattedNumber} from "react-intl";

const formattedNumber = (value) => {
    return (
        <FormattedNumber value={value} style={"currency"} currency={"COP"}
                         minimumFractionDigits={0} maximumFractionDigits={0}/>
    );
};

class FormCredit extends React.Component {

    constructor(props) {
        super(props);
        const {
            documentTypesGetRequesting,
            configurationsGetRequesting
        } = this.props;
        documentTypesGetRequesting();
        configurationsGetRequesting();

        this.initialFeed = '';
        this.formCredit = '';
        this.totalCredit = '';
        this.fees = '';
        this.quoteValue = '';

        this.state = {
            message: "",
            divMessageContent: "",
            next: false,
            configuration: false,
            modalClient: {
                client: {name: '', id: '', type: ''}
            },
            errors: {name: '', id: ''},
            authorize: false,
            oldCustomer: false
        };
        this.effectMesssage = this.effectMessage.bind(this);
        this.confirmedResponse = this.confirmedResponse.bind(this);
        this.handleUpdateModalClient = this.handleUpdateModalClient.bind(this);
        this.modalRef = React.createRef();
        this.formRef = React.createRef();
        this.divMessage = React.createRef();
        this.timer = null;
    }


    handleGetCredit = () => {
        const {creditGetRequesting, client: {token}, credit: {credit, requesting}} = this.props;
        if (credit.id && !requesting)
            creditGetRequesting(token, credit.id);
    };

    componentDidMount() {
        window.addEventListener('focus', () => this.handleGetCredit());
        this.timer = setInterval(
            () => this.effectMessage(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        window.removeEventListener('focus', () => console.log('unMount'));
        this.handleCancelQuote();
        //this.props.creditCleanValues();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        const {credit: {status}} = this.props;
        if (this.divMessage.current){
            if (nextProps.credit.status !== '' && status !== nextProps.credit.status) {
                this.divMessage.current.style.color = "#fff";
                if (nextProps.credit.status === 'APPROVED')
                    this.handleApproved();
                if (nextProps.credit.status === 'REJECTED')
                    this.handleDenied();
            }
        }

        if (nextProps.credit.exists)
            nextProps.responseCentralRisk(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.configuration) {
            this.confirmedResponse({configuration: 'configuration'});
            this.setState((state, props) => ({configuration: false}));
        }
    }

    effectMessage() {
        if (this.divMessage.current) {
            let padding = this.divMessage.current.style.padding;
            let backgroundColor = this.divMessage.current.style.backgroundColor;

            if (backgroundColor === "rgb(255, 200, 70)")
                this.divMessage.current.style.backgroundColor = "rgb(255, 175, 70)";
            else if (backgroundColor === "rgb(255, 175, 70)")
                this.divMessage.current.style.backgroundColor = "rgb(255, 200, 70)";
            else if (backgroundColor === "rgb(120, 178, 240)")
                this.divMessage.current.style.backgroundColor = "rgb(78, 157, 227)";
            else if (backgroundColor === "rgb(78, 157, 227)")
                this.divMessage.current.style.backgroundColor = "rgb(120, 178, 240)";
            else if (backgroundColor === "rgb(245, 130, 123)")
                this.divMessage.current.style.backgroundColor = "rgb(237, 101, 93)";
            else if (backgroundColor === "rgb(237, 101, 93)")
                this.divMessage.current.style.backgroundColor = "rgb(245, 130, 123)";
        }
    }

    handleApproved = () => {
        this.divMessage.current.style.backgroundColor = "rgb(120, 178, 240)";
        this.props.responseCentralRisk(true);
        this.setState({
            divMessageContent: "Tu solicitud ha sido aprobada. por favor continua con los datos del cliente para la solicitud.",
            next: true
        });
    };

    handleDenied = () => {
        this.divMessage.current.style.backgroundColor = "rgb(245, 130, 123)";
        this.props.responseCentralRisk(false);
        this.setState({
            divMessageContent: "Lo sentimos tu solicitud ha sido rechazada. verifica bien el valor y la cantidad de cuotas que ingresaste e intenta de nuevo.",
            next: false
        })
    };

    confirmedResponse(data) {
        if (this.divMessage.current) {
            if (data.stateCredit) {
                let message = "", next = false;
                if (this.props.credit.credit.id == data.credit) {
                    this.divMessage.current.style.color = "#fff";
                    if (data.stateCredit === "approved") {
                        /* message = "Tu solicitud ha sido aprobada. por favor continua con los datos del cliente para la solicitud.";
                         this.divMessage.current.style.backgroundColor = "rgb(120, 178, 240)";
                         this.props.responseCentralRisk(true);
                         next = true;*/
                        this.handleApproved();
                    } else if (data.stateCredit !== "approved") {
                        this.handleDenied();
                        /* message = "Lo sentimos tu solicitud ha sido rechazada. verifica bien el valor y la cantidad de cuotas que ingresaste e intenta de nuevo.";
                         this.divMessage.current.style.backgroundColor = "rgb(245, 130, 123)";
                         this.props.responseCentralRisk(false);
                         next = false;*/
                    }
                    /*   this.setState({
                           divMessageContent: message,
                           next: next
                       });*/
                }
            }
        } else if (data.configuration) {
            const {configurationsGetRequesting} = this.props;
            configurationsGetRequesting();
            this.handleGetPlanPayments(this.fees, this.totalCredit);
            this.setState((state, props) => ({configuration: true}));
        }
    }

    handleUpdateModalClient(client) {
        this.setState({modalClient: {client: client}});
    }

    handleSubmitModal() {
        const {modalClient: {client: {name, id}}} = this.state;
        let err = {name: '', id: ''};
        let contador = 0;

        if (!name) {
            err.name = "debes ingresar el nombre";
            contador++;
        } else
            err.name = null;

        if (!id) {
            err.id = "debes ingresar el id";
            contador++;
        } else
            err.id = null;

        this.setState({errors: err});
        if (contador === 0) {
            this.formRef.current.props.onSubmit();
            this.handleCloseModal();
        }
    }

    handleCloseModal() {
        this.modalRef.current.classList.remove("modal-container-active");
    }

    handleOpenModal() {
        if (this.state.authorize)
            this.modalRef.current.classList.add("modal-container-active");
        else
            toastr.error("Error" , "Debe autorizar el siguimiento crediticio");
    }

    handleSubmit = (values) => {
        const {
            client: {token},
            policy: {policy},
            creditCreateRequesting,
            credit: {success, planPayments},
        } = this.props;
        const {modalClient: {client} , oldCustomer} = this.state;
        //const initialFeed = values.porcentaje * parseInt(policy.valor);
        const totalCredit = parseInt(policy.valor) - values.coutaInicial;
        creditCreateRequesting(token, values, policy, values.coutaInicial, totalCredit, client, planPayments , oldCustomer);
        this.setState({
            divMessageContent: "En espera de confirmación. por favor no cierres la pagina te informaremos si tu solicitud fue aprobada o rechazada en breve.",
            next: success ? true : false
        });
    };

    handleChangeAuthorize(){
        this.setState((state) => ({
            authorize: !state.authorize
        }));
    }

    handleChangeOldCustomer(){
        this.setState((state) => ({
            oldCustomer: !state.oldCustomer
        }));
    }

    handleGetPlanPayments = (fees, totalCredit) => {
        const {
            configuration: {configurations},
            policy: {policy},
            planPaymentsCredit,
        } = this.props;

        let pastDueRate = configurations.find((configuration) => configuration.nombre === "Tasa mes vencido");
        let adminFee = configurations.find((configuration) => configuration.nombre === "Tasa administración");
        planPaymentsCredit(fees, totalCredit, pastDueRate.valor, adminFee.valor, policy);
    };

    handleCancelQuote = () => {
        const {policyResetFocus, policy: {policy}, policyDeleteRequesting, client: {token}} = this.props;
        policyDeleteRequesting(token, policy.id);
        policyResetFocus();
        this.props.reset('policy');
        this.props.reset('credit');
        this.props.credit.success = false;
        this.props.responseCentralRisk(false);
        //this.props.creditCleanValues();
    };

    handleFormattedNumber(num) {
        try{
            const count = num.toString().split('');
            if (count.length > 3) {
                var dots = [], result = '';
                for (let i = count.length, j = 1; i > 0; i-- , j++) {
                    if (j % 3 === 0)
                        dots.push('.' + count[i - 1]);
                    else
                        dots.push(count[i - 1]);
                }

                for (let i = dots.length; i > 0; i--) {
                    result += dots[i - 1];
                }

                return '$' + result;
            } else
            return '$' + num;
        }catch (error){
        }
    }

    render() {
        const {
            classes,
            handleSubmit,
            policy: {policy},
            credit: {requesting, percentages, planPayments, success, credit , exists},
            documentType: {documentTypes},
            configuration: {configurations},
            identificationType: {identificationTypes},
            stateForm,
            responseCentralRisk
        } = this.props;
        const pastDueRate = configurations.find((configuration) => configuration.nombre === "Tasa mes vencido");
        const adminFee = configurations.find((configuration) => configuration.nombre === "Tasa administración");
        this.formCredit = stateForm ? stateForm.hasOwnProperty("credit") && stateForm.credit.hasOwnProperty("values") ? stateForm.credit.values : {} : {};
        const initialFeed = this.formCredit.hasOwnProperty("coutaInicial") && policy.hasOwnProperty("valor") ? parseInt((this.formCredit.coutaInicial / policy.valor) * 100) : 0;
        this.totalCredit = this.formCredit.hasOwnProperty("coutaInicial") && policy.hasOwnProperty("valor") ? (parseInt(policy.valor) - this.formCredit.coutaInicial) : 0;
        this.fees = this.formCredit.hasOwnProperty("cuotas") ? parseInt(this.formCredit.cuotas) : 0;
        // const fees = 6;
        // this.totalCredit = 10000000000;

        if (pastDueRate && adminFee && this.formCredit.hasOwnProperty("cuotas")) {
            let tasaMesVencido = (pastDueRate.valor / 100);
            this.quoteValue = /* (adminFee.valor * this.formCredit.cuotas) / (this.formCredit.cuotas - 1) + */(tasaMesVencido * Math.pow((1 + tasaMesVencido), (this.formCredit.cuotas - 1))) / (Math.pow((1 + tasaMesVencido), (this.formCredit.cuotas - 1)) - 1) * (policy.valor - this.formCredit.coutaInicial);     
            
            let intereses = tasaMesVencido;
            let interesesPrimeraCuota = policy?.valor * intereses;
            this.interestFirstValue = interesesPrimeraCuota;
            let abonoCapitalPrimeraCuota = this.formCredit.coutaInicial - interesesPrimeraCuota;
            let saldoDeudaPrimeraCuota = policy?.valor - abonoCapitalPrimeraCuota;
            this.capitalFirstBalance = saldoDeudaPrimeraCuota;
            this.capitalFirstPayment = abonoCapitalPrimeraCuota;
            let numeroDeCuotas = this.formCredit.cuotas;
            let tasaAdministracion = adminFee.valor;
            this.tasaAdministracion = tasaAdministracion;

            this.initialFeed = Math.ceil(((saldoDeudaPrimeraCuota * (intereses/(1-( Math.pow((1+intereses), (numeroDeCuotas-1) * -1) )))  ) + tasaAdministracion) / 1000) * 1000;
        }

        if (this.fees >= 3 && this.fees <= 11 && this.fees !== planPayments.length && configurations.length > 0) {
            this.handleGetPlanPayments(this.fees, this.totalCredit);
        }

        /*const planPaymentsList = planPayments.map((planPayment, index) => (
            <tr key={index}>
                <td>{planPayment.fee}</td>
                {index === 0 &&
                    <>
                        <td> {this.formCredit.coutaInicial} </td>
                        <td>0</td>
                        <td>{(this.formCredit.coutaInicial - adminFee.valor)}</td>
                        <td>{policy.valor - (this.formCredit.coutaInicial - adminFee.valor)}</td>
                    </>
                }
                {index !== 0 &&
                <>
                                        <td>{this.initialFeed}</td>
                    <td>{formattedNumber(planPayment.interestValue)}</td>
                    <td>{formattedNumber(planPayment.capitalValue)}</td>

                    {index === 1 &&
                        <td> {formattedNumber((policy.valor - (this.formCredit.coutaInicial - adminFee.valor)) - planPayment.capitalValue)} </td>
                    }
                    {index !== 1 &&
                        <td>{formattedNumber(planPayments[index - 1].capitalBalance - planPayment.capitalValue)}</td>
                    }
                </>
                }
                <td>{planPayment.payDayLimit}</td>
            </tr>
        ))*/

        /*if (success) {
            return (
                <Redirect to={"/admin/misSolicitudes"}/>
            );
        }*/
        if (policy.hasOwnProperty("id") && policy.fecha_inicio.split("-")[2] <= 15 && this.state.message === "") {
            this.setState({
                message: policy.beneficiario_honeroso ? "La cantidad de cuotas debe ser un numero entre 3 y 10" : "La cantidad de cuotas debe ser un numero entre 3 y 11"
            });
        }
        if (policy.hasOwnProperty("id") && policy.fecha_inicio.split("-")[2] > 15 && this.state.message === "") {
            this.setState({
                message: policy.beneficiario_honeroso ? "La cantidad de cuotas debe ser un numero entre 3 y 10" : "La cantidad de cuotas debe ser un numero entre 3 y 9"
            });
        }
        return (
            <>
                <Card profile>
                    {messaging && <FirebaseMessage onMessageReceived={this.confirmedResponse}/>}
                    <CardBody>
                        <ModalClientData
                            modalClient={this.state.modalClient}
                            handleUpdateModalClient={(key, value) => this.handleUpdateModalClient(key, value)}
                            handleSubmitModal={() => this.handleSubmitModal.bind(this)}
                            handleCloseModal={() => this.handleCloseModal.bind(this)}
                            handleChangeOldCustomer={() => this.handleChangeOldCustomer.bind(this)}
                            oldCustomer={this.state.oldCustomer}
                            errors={this.state.errors}
                            modalRef={this.modalRef}
                        />
                        <h1 className={classes.TitleH1Left}>Información crédito</h1>
                        <Form ref={this.formRef} onSubmit={handleSubmit(this.handleSubmit)}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    {/*<Field disabled={!policy.hasOwnProperty("id")} id={"porcentaje"}*/}
                                    {/*       name={"porcentaje"} type={"select"}*/}
                                    {/*       label={"Porcentaje cuota inicial *"}*/}
                                    {/*       autoComplete={"on"} component={customInputSelect} classes={classes}*/}
                                    {/*       data={percentages}/>*/}
                                    <Field disabled={!policy.hasOwnProperty("id")}
                                           id={"coutaInicial"}
                                           name={"coutaInicial"}
                                           type={"number"}
                                           label={"Valor cuota inicial *"}
                                           autoComplete={"on"} component={customInputText} classes={classes}
                                           onChange={() => {
                                               if (this.fees >= 3 && this.fees <= 11) {
                                                   this.handleGetPlanPayments(this.fees, this.totalCredit);
                                               }
                                           }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field disabled={!policy.hasOwnProperty("id")} id={"cuotas"} name={"cuotas"}
                                           type={"number"} label={"Numero de cuotas *"}
                                           autoComplete={"off"}
                                           component={customInputText}
                                    />
                                    <p style={{marginTop: -15}}>{this.state.message}</p>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <p>Porcentaje cuota inicial</p>
                                    {(initialFeed) + '%'}
                                    {/*<p>Valor cuota inicial</p>*/}
                                    {/*{formattedNumber(this.initialFeed)}*/}
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    {/*<p>Valor del credito</p>
								{formattedNumber(this.totalCredit)}*/}
                                </GridItem>
                                {planPayments.length > 0 && policy.hasOwnProperty("id") && (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <br/>
                                        <h1 className={classes.TitleH1Left}>Tabla amortización</h1>
                                        <div className="Conteiner-table">
                                            <table className="table-pyments-page-price4">
                                                <thead>
                                                <tr>
                                                    <th>Cuota</th>
                                                    <th>Valor cuota</th>
                                                    <th>Valor interes *</th>
                                                    <th>Abono capital</th>
                                                    <th>Saldo capital</th>
                                                    <th>Fecha de pago</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {planPayments.map((planPayment, index) => {
                                                    let tasaMesVencido = (pastDueRate.valor / 100);
                                                    if (index === 0) {
                                                        planPayment.valueFee = parseInt(this.formCredit.coutaInicial) /*+ adminFee.valor*/;
                                                        planPayment.interestValue = this.interestFirstValue;
                                                        planPayment.capitalValue = this.capitalFirstPayment;
                                                        planPayment.capitalBalance = this.capitalFirstBalance;
                                                    }

                                                    if (index !== 0) {
                                                        planPayment.valueFee = this.initialFeed;
                                                        planPayment.interestValue = parseInt(planPayments[index - 1].capitalBalance * tasaMesVencido); //+ adminFee.valor; //parseInt(planPayment.interestValue);
                                                        planPayment.capitalValue = parseInt(planPayment.valueFee - planPayment.interestValue) - this.tasaAdministracion; //parseInt(planPayment.capitalValue);
                                                        planPayment.capitalBalance = planPayments[index - 1].capitalBalance - planPayment.capitalValue;
                                                    }

                                                    if (index === planPayments.length - 1)
                                                        planPayment.capitalBalance = 0;

                                                    return (
                                                        <tr key={index}>
                                                            <td>{planPayment.fee}</td>
                                                            <td>{this.handleFormattedNumber(parseInt(planPayment.valueFee))}</td>
                                                            <td>{this.handleFormattedNumber(parseInt(planPayment.interestValue + this.tasaAdministracion))}</td>
                                                            <td>{this.handleFormattedNumber(parseInt(planPayment.capitalValue))}</td>
                                                            <td>{this.handleFormattedNumber(parseInt(planPayment.capitalBalance))}</td>
                                                            <td>{planPayment.payDayLimit}</td>
                                                        </tr>
                                                    )
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                )}
                                {planPayments.length > 0 && policy.hasOwnProperty("id") && (
                                    <div style={{textAlign: "left", margin: 20}}>
                                        <p>- El numero de cuotas depende de la vigencia corrida de la poliza.</p>
                                        <p>- El monto minimo de financiación son $500.000.</p>
                                        <p>- Se pueden ingresar financiaciones maximo 25 dias de vigencia. Se debe
                                            radicar la financiacion 5
                                            dias antes del vencimiento toda la documentacion completa y debidamente
                                            diligenciada.</p>
                                        <p>- * En el valor de la cuota esta incluido cobro mensual de comisón por
                                            disponibilidad de cupo de
                                            crédito, incluido IVA.</p>
                                    </div>
                                )}
                                {planPayments.length > 0 && policy.hasOwnProperty("id") && documentTypes.length > 0 && (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h1 className={classes.TitleH1Left}>Documentos</h1>
                                        <div className="Conteiner-table">
                                            <table className="table-pyments-page-price2">
                                                <tbody>
                                                {documentTypes.map((documentType, index) => (
                                                    documentType.nombre === 'Formato Autorización Centrales de Riesgo' && (
                                                        <tr key={index}>
                                                            <td>{documentType.nombre}{documentType.requerido ? " *" : ""}</td>
                                                            <td>
                                                                <Field disabled={!policy.hasOwnProperty("id")}
                                                                       name={`document_${documentType.nombre}`}
                                                                       maxFiles={1}
                                                                       className={"filepond"}
                                                                       component={DocumentsInput}
                                                                       label={"<span class=\"filepond--label-action\">Subir</span>"}/>
                                                            </td>
                                                            <td>
                                                                <input type={'checkbox'} checked={this.state.authorize} onChange={this.handleChangeAuthorize.bind(this)}/> El cliente autorizo consultar en las centrales de riesgo su comportamiento crediticio.
                                                            </td>
                                                        </tr>
                                                    )
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </GridItem>
                                )}
                            </GridContainer>
                            {/*	<h1 className={classes.TitleH1Left}>Datos del cliente</h1>
						<GridContainer>
							<GridItem xs={12} sm={4} md={5}>
								{identificationTypes.length > 0 && (
									<Field
										// disabled={!credit.hasOwnProperty("id") || initialValues.hasOwnProperty("tipo_identificacion") || clients.error !== ""}
										id={"tipo_identificacion"} name={"tipo_identificacion"} type={"select"}
										label={"Tipo de identificación *"}
										autoComplete={"on"} component={customInputSelect} classes={classes}
										data={identificationTypes}/>
								)}
							</GridItem>
							<GridItem xs={12} sm={4} md={5}>
								<Field
									//disabled={!credit.hasOwnProperty("id") || initialValues.hasOwnProperty("identificacion") || clients.error !== ""}
									id={"identificacion"} name={"identificacion"} type={"number"}
									label={"Identificación *"}
									autoComplete={"off"}
									component={customInputText}/>
							</GridItem>
							</GridContainer>*/}
                            <Button disabled={!policy.hasOwnProperty("id") || credit.hasOwnProperty("id")}
                                    type={"button"}
                                    color="primary"
                                    className={classes.updateProfileButton}
                                    onClick={this.handleOpenModal.bind(this)}
                            >
                                Siguiente
                            </Button>
                        </Form>
                        <Button disabled={!policy.hasOwnProperty("id") || requesting}
                                onClick={() => this.handleCancelQuote()}
                                color="danger"
                                className={classes.updateProfileButton}>
                            Cancelar
                        </Button>
                    </CardBody>
                </Card>

                {success && !credit.crentales_riesgo && <div>
                    <div ref={this.divMessage} style={{
                        backgroundColor: 'rgb(255, 200, 70)',
                        fontWeight: 'bold',
                        padding: '16px',
                        borderRadius: '4px',
                        transition: 'all ease 1.5s',
                        textAlign: 'center'
                    }}>
                        <p style={{
                            position: 'relative',
                            top: '4px'
                        }}>{this.state.divMessageContent}</p>
                    </div>
                </div>}
            </>
        );
    }
}

FormCredit.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
    client: state.client,
    credit: state.credit,
    documentType: state.documentType,
    policy: state.policy,
    clients: state.clients,
    configuration: state.configuration,
    stateForm: state.form,
    identificationType: state.identificationType,
});

const connected = connect(mapStateToProps, {
    documentTypesGetRequesting,
    creditCreateRequesting,
    creditCleanValues,
    planPaymentsCredit,
    configurationsGetRequesting,
    policyResetFocus,
    policyDeleteRequesting,
    reset,
    creditGetRequesting
})(withStyles(userProfileStyles)(FormCredit));

const formed = reduxForm({
    form: "credit",
    reset
})(connected);

export default formed;


