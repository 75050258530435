import { VERIFY_EMAIL_ERROR, VERIFY_EMAIL_REQUESTING, VERIFY_EMAIL_SUCCESS } from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: ""
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case VERIFY_EMAIL_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case VERIFY_EMAIL_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
			};
		case VERIFY_EMAIL_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default reducer;