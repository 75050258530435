import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../../commons/errors/apiErrors";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import { registerError, registerSuccess } from "./actions";
import { REGISTER_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/new_register`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const registerApi = (values, typeIdentification) => {
	let body = new FormData();
	body.append("name", values.nombre || null);
	body.append("email", values.correo || null);
	body.append("password", values.contraseña || null);
	body.append("identificationType", typeIdentification);
	body.append("identification", values.identificacion || null);
	body.append("phone", values.telefono || null);
	body.append("address", values.direccion || null);
	body.append("city", values.ciudad || null);
	body.append("photos_length", values.hasOwnProperty("photos") ? values.photos.length : 0);
	if (values.hasOwnProperty("photos") && values.photos.length > 0) {
		values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
	}
	Object.keys(values).map(key => {
		if (key.indexOf("document_") >= 0) {
			body.append(key.split("document_")[1].replace(/\s/g, ""), values[key][0].file);
		}
	});
	return fetch(`${baseUrl}`, {
		method: "POST",
		body: body
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* userCreateFlow(action) {
	try {
		const { values, typeIdentification } = action;
		yield call(registerApi, values, typeIdentification);
		yield put(registerSuccess());
		toastr.success("Registro exitoso", "Se ha registrado con exito, hemos enviado un correo electronico para activar su cuenta.");
	} catch (error) {
		yield put(registerError(error));
		showError(error);
	}
}

function* registerWatcher() {
	yield all([
		takeEvery(REGISTER_REQUESTING, userCreateFlow),
	]);
}

export default registerWatcher;