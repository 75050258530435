export const CLIENTS_GET_REQUESTING = 'CLIENTS_GET_REQUESTING';
export const CLIENTS_GET_SUCCESS = 'CLIENTS_GET_SUCCESS';
export const CLIENTS_GET_ERROR = 'CLIENTS_GET_ERROR';

export const CLIENT_CREATE_REQUESTING = 'CLIENT_CREATE_REQUESTING';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_ERROR = 'CLIENT_CREATE_ERROR';

export const CLIENT_UPDATE_REQUESTING = 'CLIENT_UPDATE_REQUESTING';
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS';
export const CLIENT_UPDATE_ERROR = 'CLIENT_UPDATE_ERROR';

export const CLIENT_CHANGE_STATE_REQUESTING = 'CLIENT_CHANGE_STATE_REQUESTING';
export const CLIENT_CHANGE_STATE_SUCCESS = 'CLIENT_CHANGE_STATE_SUCCESS';
export const CLIENT_CHANGE_STATE_ERROR = 'CLIENT_CHANGE_STATE_ERROR';

export const CLIENTS_SEARCH_REQUESTING = 'CLIENTS_SEARCH_REQUESTING';
export const CLIENTS_SEARCH_SUCCESS = 'CLIENTS_SEARCH_SUCCESS';
export const CLIENTS_SEARCH_ERROR = 'CLIENTS_SEARCH_ERROR';

export const CLIENT_FIND_IDENTIFICATION_REQUESTING = 'CLIENT_FIND_IDENTIFICATION_REQUESTING';
export const CLIENT_FIND_IDENTIFICATION_SUCCESS = 'CLIENT_FIND_IDENTIFICATION_SUCCESS';
export const CLIENT_FIND_IDENTIFICATION_ERROR = 'CLIENT_FIND_IDENTIFICATION_ERROR';

export const CLIENT_CONTROL_MODAL = 'CLIENT_CONTROL_MODAL';
export const CLIENT_FOCUS = 'CLIENT_FOCUS';
export const CLIENT_RESET_FOCUS = 'CLIENT_RESET_FOCUS';
export const CLIENT_RESET_STATES = 'CLIENT_RESET_STATES';
export const CLIENT_FILL_DATA = "CLIENT_FILL_DATA";
export const CLIENT_FILL_DATA_SUCCESS = "CLIENT_FILL_DATA_SUCCESS";
export const CLIENT_FILL_DATA_ERROR = "CLIENT_FILL_DATA_ERROR";
export const CLIENT_CLEAN_VALUES = "CLIENT_CLEAN_VALUES";