/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

//
import { creditsSearchRequesting } from "../../redux/credit/actions";
import { logoutGetRequesting } from "../../redux/auth/logout/actions";
import { reduxForm, Form, Field } from "redux-form/lib/immutable";
import { connect } from "react-redux";
import { inputSearch } from "../inputs/search";

class HeaderLinks extends React.Component {
	state = {
		openNotification: false,
		openProfile: false
	};
	handleClickNotification = () => {
		this.setState({ openNotification: !this.state.openNotification });
	};
	handleCloseNotification = () => {
		this.setState({ openNotification: false });
	};
	handleClickProfile = () => {
		this.setState({ openProfile: !this.state.openProfile });
	};
	handleCloseProfile = () => {
		this.setState({ openProfile: false });
	};
	
	handleSearch = values => {
		const {
			client: { token },
			creditsSearchRequesting
		} = this.props;
		creditsSearchRequesting(token, values);
	};
	
	handleLogout = () => {
		const {
			client: { token },
			logoutGetRequesting
		} = this.props;
		logoutGetRequesting(token);
	};
	
	handleGetInstructions = (type) => {
		const routeApi = "https://api.danko.co/storage/instructions";
		switch (type) {
			case "pse":
				window.open(`${routeApi}/PSE.pdf`, "_blank");
				break;
			case "bank":
				window.open(`${routeApi}/bank.jpeg`, "_blank");
				break;
		}
	};
	
	render() {
		const { classes, rtlActive, handleSubmit } = this.props;
		const { openNotification, openProfile } = this.state;
		const searchButton =
			classes.top +
			" " +
			classes.searchButton +
			" " +
			classNames({
				[classes.searchRTL]: rtlActive
			});
		const dropdownItem = classNames(
			classes.dropdownItem,
			classes.primaryHover,
			{ [classes.dropdownItemRTL]: rtlActive }
		);
		const wrapper = classNames({
			[classes.wrapperRTL]: rtlActive
		});
		const managerClasses = classNames({
			[classes.managerClasses]: true
		});
		return (
			<div className={wrapper}>
				<Form onSubmit={handleSubmit(this.handleSearch)}>
					
					<Field id={"search"} name={"search"} type={"text"} placeholder={"Buscar"} autoComplete={"off"}
					       component={inputSearch} classes={classes} rtlActive={rtlActive}/>
					<Button
						color="white"
						aria-label="edit"
						justIcon
						round
						className={searchButton}
						type={"submit"}
					>
						<Search
							className={classes.headerLinksSvg + " " + classes.searchIcon}
						/>
					</Button>
					
					
					<div className={managerClasses}>
						<Button
							color="transparent"
							aria-label="Person"
							justIcon
							aria-owns={openNotification ? "profile-menu-list" : null}
							aria-haspopup="true"
							onClick={this.handleClickProfile}
							className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
							muiClasses={{
								label: rtlActive ? classes.labelRTL : ""
							}}
							buttonRef={node => {
								this.anchorProfile = node;
							}}
						>
							<Person
								className={
									classes.headerLinksSvg +
									" " +
									(rtlActive
										? classes.links + " " + classes.linksRTL
										: classes.links)
								}
							/>
							<Hidden mdUp implementation="css">
              <span
	              onClick={this.handleClickProfile}
	              className={classes.linkText}
              >
                {rtlActive ? "الملف الشخصي" : "Profile"}
              </span>
							</Hidden>
						</Button>
						<Popper
							open={openProfile}
							anchorEl={this.anchorProfile}
							transition
							disablePortal
							placement="bottom"
							className={classNames({
								[classes.popperClose]: !openProfile,
								[classes.popperResponsive]: true,
								[classes.popperNav]: true
							})}
						>
							{({ TransitionProps }) => (
								<Grow
									{...TransitionProps}
									id="profile-menu-list"
									style={{ transformOrigin: "0 0 0" }}
								>
									<Paper className={classes.dropdown}>
										<ClickAwayListener onClickAway={this.handleCloseProfile}>
											
											
											<MenuList role="menu">
												<MenuItem
													onClick={() => this.handleGetInstructions('pse')}
													className={dropdownItem}
												>
													{rtlActive ? "الإعدادات" : "Instructivo PSE"}
												</MenuItem>
												<Divider light/>
												<MenuItem
													onClick={() => this.handleGetInstructions('bank')}
													className={dropdownItem}
												>
													{rtlActive ? "الإعدادات" : "Instructivo cuenta bancaria"}
												</MenuItem>
												<Divider light/>
												<MenuItem
													onClick={() => this.handleLogout()}
													className={dropdownItem}
												>
													{rtlActive ? "الخروج" : "Cerrar sesión"}
												</MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</div>
				</Form>
			</div>
		);
	}
}

HeaderLinks.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool
};

const mapStateToProps = state => ({
	client: state.client
});

const connected = connect(mapStateToProps, {
	creditsSearchRequesting,
	logoutGetRequesting
})(withStyles(adminNavbarLinksStyle)(HeaderLinks));

const formed = reduxForm({
	form: "search"
})(connected);

export default formed;
