import {
	LEGAL_PERSON_CREATE_REQUESTING,
	LEGAL_PERSON_CREATE_SUCCESS,
	LEGAL_PERSON_CREATE_ERROR,
	LEGAL_PERSON_UPDATE_REQUESTING,
	LEGAL_PERSON_UPDATE_SUCCESS,
	LEGAL_PERSON_UPDATE_ERROR,
	LEGAL_PERSON_RESET_STATES,
	LEGAL_PERSON_FILL_DATA,
	LEGAL_PERSON_FILL_DATA_SUCCESS,
	LEGAL_PERSON_FILL_DATA_ERROR,
	LEGAL_PERSON_CLEAN_VALUES
} from './constants';

const initialState = {
	requesting: false,
	success: false,
	error: "",
	values: {},
	person: {}
};

const reducer = (state = initialState, action) => {
	switch (action.type){
		case LEGAL_PERSON_CREATE_REQUESTING:
		    return {
		   	    ...state,
		   	    requesting: false,
		   	    success: false,
		   	    error: ""
		    };
		case LEGAL_PERSON_CREATE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	person: action.person
		    };  
		case LEGAL_PERSON_CREATE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };
		case LEGAL_PERSON_UPDATE_REQUESTING:
		    return {
		   	    ...state,
		   	    requesting: false,
		   	    success: false,
		   	    error: ""
		    };
		case LEGAL_PERSON_UPDATE_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	person: action.person
		    };  
		case LEGAL_PERSON_UPDATE_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error
		    };    
		case LEGAL_PERSON_RESET_STATES:
		    return {
		    	...state,
                requesting: false,
                success: false,
                error: ""
		    };
		case LEGAL_PERSON_FILL_DATA:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: ""
		    }; 
		case LEGAL_PERSON_FILL_DATA_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	person: action.person
		    }; 
		case LEGAL_PERSON_CLEAN_VALUES:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: "",
		    	person: {},
		    	values: {}
		    };           
		default: 
		    return state;      
	}
}

export default reducer;