import {
    CHECK_AUTH,
    CHECK_AUTH_ERROR,
    CHECK_AUTH_SUCCESS,
    CLIENT_UNSET,
    CLIENT_SET,
    SAVE_DEVICE,
    DELETE_DEVICE,
} from "./constans";

export const clientSet = (token) => ({
    type: CLIENT_SET,
    token
});

export const clientUnSet = () => ({
    type: CLIENT_UNSET
});

export const checkAuth = () => ({
    type: CHECK_AUTH
});

export const checkAuthError = () => ({
    type: CHECK_AUTH_ERROR
});

export const checkAuthSuccess = (token) => ({
    type: CHECK_AUTH_SUCCESS,
    token
});
export const saveDevice = (token,values) => ({
    type: SAVE_DEVICE,
    token,
    values,
});

export const deleteDevice = (token) => ({
    type: DELETE_DEVICE,
    token,
});