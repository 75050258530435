import React from 'react';
// MaterialUI
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// Components Core
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button.jsx';
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../inputs/typeText";
import {customInputSelect} from "../inputs/typeSelect";
import {Redirect} from "react-router-dom";
// Acciones
import {
    familyMemberCreateRequesting,
    familyMemberUpdateRequesting,
    familyMemberCleanValues,
    familyMemberDeleteRequesting
} from './../../redux/familyMember/actions';

class FormFamily extends React.Component {

    constructor(props) {
        super(props);
        this.handleCheckClientData = this.handleCheckClientData.bind(this);
        this.handleCleanValues = this.handleCleanValues.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {familyMember: {familyMember}} = prevProps;
        if (!familyMember.hasOwnProperty("id") && this.props.familyMember.familyMember.hasOwnProperty("id"))
            this.handleCheckClientData();
    }

    componentWillUnmount() {
        this.props.familyMemberCleanValues();
    }

    handleCheckClientData() {
        const {
            stateForm,
            clients: {client},
            familyMember: {familyMember}
        } = this.props;
        if (familyMember.hasOwnProperty("id")) {
            this.props.stateForm.familyMember = {
                values: familyMember
            };
        }
    }

    handleCleanValues() {
        const {stateForm, familyMemberDeleteRequesting} = this.props;
        if (stateForm.hasOwnProperty("familyMember")) {
            if (stateForm.familyMember.hasOwnProperty("values")) {
                familyMemberDeleteRequesting();
                this.props.stateForm.familyMember.values = {};
            }
        }
    }

    handleSubmit = (values) => {
        const {
            client: {token},
            clients: {client},
            familyMember: {familyMember, success},
            familyMemberCreateRequesting,
            familyMemberUpdateRequesting
        } = this.props;

        console.log(familyMember, client);
        if (familyMember.hasOwnProperty("id"))
            familyMemberUpdateRequesting(token, values, client.id, familyMember.id);
        else
            familyMemberCreateRequesting(token, values, client.id);
    };

    render() {
        const {
            classes,
            handleSubmit,
            familyMember: {familyMember, success},
            stateForm
        } = this.props;
        const nextButtonCondition = !success || !familyMember.hasOwnProperty("id")
        return (
            <>
                <Card profile>
                    <CardBody>
                        <h1 className={classes.TitleH1Left}>REFERENCIA PERSONAL Y FAMILIAR(Que no viva con el
                            solicitante)</h1>
                        <Form ref={this.formRef} onSubmit={handleSubmit(this.handleSubmit)}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"nombresYApellidos"}
                                        name={"nombresYApellidos"}
                                        type={"text"}
                                        label={"Nombres y apellidos *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"parentesco"}
                                        name={"parentesco"}
                                        type={"text"}
                                        label={"Parentesco *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"direccion"}
                                        name={"direccion"}
                                        type={"text"}
                                        label={"Dirección *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"telefono"}
                                        name={"telefono"}
                                        type={"number"}
                                        label={"Telefono fijo *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"celular"}
                                        name={"celular"}
                                        type={"number"}
                                        label={"Celular *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <Field
                                        id={"ciudad"}
                                        name={"ciudad"}
                                        type={"text"}
                                        label={"Ciudad *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                            </GridContainer>
                            {nextButtonCondition &&
                            <Button
                                type={"submit"}
                                color="primary"
                                className={classes.updateProfileButton}
                            >
                                Siguiente
                            </Button>
                            }


                            {familyMember.hasOwnProperty("id") && success &&
                            <>
                                <Button
                                    type={"button"}
                                    color="primary"
                                    className={classes.updateProfileButton}
                                    onClick={this.handleCleanValues}
                                >
                                    Nuevo
                                </Button>
                                <Button
                                    type={"submit"}
                                    color="success"
                                    className={classes.updateProfileButton}
                                >
                                    Actualizar
                                </Button>
                            </>
                            }
                        </Form>
                    </CardBody>
                </Card>
            </>
        );
    }
}

FormFamily.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
    client: state.client,
    familyMember: state.familyMember,
    clients: state.clients,
    stateForm: state.form
});

const connected = connect(mapStateToProps, {
    familyMemberCreateRequesting,
    familyMemberUpdateRequesting,
    familyMemberCleanValues,
    familyMemberDeleteRequesting
})(withStyles(userProfileStyles)(FormFamily));

const formed = reduxForm({
    form: "familyMember",
    reset
})(connected);

export default formed;

