import {
	CONFIGURATIONS_GET_ERROR,
	CONFIGURATIONS_GET_REQUESTING,
	CONFIGURATIONS_GET_SUCCESS
} from "./constants";

export const configurationsGetRequesting = () => ({
	type: CONFIGURATIONS_GET_REQUESTING
});

export const configurationsGetSuccess = (configurations) => ({
	type: CONFIGURATIONS_GET_SUCCESS,
	configurations
});

export const configurationsGetError = (error) => ({
	type: CONFIGURATIONS_GET_ERROR,
	error
});