import {
	CLIENT_CHANGE_STATE_ERROR,
	CLIENT_CHANGE_STATE_REQUESTING,
	CLIENT_CHANGE_STATE_SUCCESS,
	CLIENT_CONTROL_MODAL,
	CLIENT_CREATE_ERROR,
	CLIENT_CREATE_REQUESTING,
	CLIENT_CREATE_SUCCESS, CLIENT_FIND_IDENTIFICATION_ERROR,
	CLIENT_FIND_IDENTIFICATION_REQUESTING, CLIENT_FIND_IDENTIFICATION_SUCCESS,
	CLIENT_FOCUS, CLIENT_RESET_FOCUS,
	CLIENT_RESET_STATES,
	CLIENT_UPDATE_ERROR,
	CLIENT_UPDATE_REQUESTING,
	CLIENT_UPDATE_SUCCESS,
	CLIENTS_GET_ERROR,
	CLIENTS_GET_REQUESTING,
	CLIENTS_GET_SUCCESS,
	CLIENTS_SEARCH_ERROR,
	CLIENTS_SEARCH_REQUESTING,
	CLIENTS_SEARCH_SUCCESS,
	CLIENT_CLEAN_VALUES,
	CLIENT_FILL_DATA,
	CLIENT_FILL_DATA_SUCCESS,
	CLIENT_FILL_DATA_ERROR
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	filter: "",
	search: "",
	paginate: 0,
	hasMore: false,
	hasLess: false,
	clients: [],
	client: {},
	clientsSearch: [],
	addModal: false,
	editModal: false,
	creditsModal: false,
	documentsModal: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CLIENTS_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
				filter: action.filter,
				paginate: action.paginate
			};
		case CLIENTS_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				clients: action.clients,
				hasMore: action.clients.length > 0,
				hasLess: state.paginate > 1
			};
		case CLIENTS_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case CLIENT_CREATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case CLIENT_CREATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				clients: [action.client, ...state.clients],
				clientsSearch: state.clientsSearch.length > 0 ? [action.client, ...state.clientsSearch] : state.clientsSearch,
				client: action.client
			};
		case CLIENT_CREATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case CLIENT_UPDATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case CLIENT_UPDATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				clients: state.clients.map((client) => client.id === action.client.id ? action.client : client),
				clientsSearch: state.clientsSearch.length > 0 ? state.clientsSearch.map((client) => client.id === action.client.id ? action.client : client) : state.clientsSearch,
				client: action.client
			};
		case CLIENT_UPDATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case CLIENT_CHANGE_STATE_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case CLIENT_CHANGE_STATE_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				clients: state.clients.map((client) => client.id === action.client.id ? action.client : client),
				clientsSearch: state.clientsSearch.length > 0 ? state.clientsSearch.map((client) => client.id === action.client.id ? action.client : client) : state.clientsSearch
			};
		case CLIENT_CHANGE_STATE_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case CLIENTS_SEARCH_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				search: action.values.search,
				error: ""
			};
		case CLIENTS_SEARCH_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				clientsSearch: action.clients
			};
		case CLIENTS_SEARCH_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case CLIENT_FIND_IDENTIFICATION_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: "",
				client: {}
			};
		case CLIENT_FIND_IDENTIFICATION_SUCCESS:
			return {
				...state,
				requesting: false,
				success: true,
				client: action.client
			};
		case CLIENT_FIND_IDENTIFICATION_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
		case CLIENT_CONTROL_MODAL:
			return {
				...state,
				addModal: action.modal === "addModal" ? !state.addModal : state.addModal,
				editModal: action.modal === "editModal" ? !state.editModal : state.editModal,
				creditsModal: action.modal === "creditsModal" ? !state.creditsModal : state.creditsModal,
				documentsModal: action.modal === "documentsModal" ? !state.documentsModal : state.documentsModal
			};
		case CLIENT_FOCUS:
			return {
				...state,
				client: action.client
			};
		case CLIENT_RESET_FOCUS:
			return {
				...state,
				client: {}
			};
		case CLIENT_RESET_STATES:
			return {
				...state,
				requesting: false,
				success: false,
				error: ""
			};
		case CLIENT_CLEAN_VALUES:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: "",
		    	client: {}
		    };	
		case CLIENT_FILL_DATA:
		    return {
		    	...state,
		    	requesting: true,
		    	success: false,
		    	error: "",
		    	client: {}
		    };    
		case CLIENT_FILL_DATA_SUCCESS:
		    return {
		    	...state,
		    	requesting: false,
		    	success: true,
		    	error: "",
		    	client: action.client 
		    };
		case CLIENT_FILL_DATA_ERROR:
		    return {
		    	...state,
		    	requesting: false,
		    	success: false,
		    	error: action.error,
		    	client: {}
		    };
		default:
			return state;
	}
};

export default reducer;