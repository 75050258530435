import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
import { connect } from "react-redux";
import { Form, reduxForm, Field, reset} from "redux-form";
import { customInputText } from "../../../components/inputs/typeText";
import GridItem from "../../../components/formQuote/client";
import { customInputSelect } from "../../../components/inputs/typeSelect";
import {clientUpdateRequesting} from "../../../redux/client/actions";
import {customInputDate} from "../../../components/inputs/inputDate";
import {
	familyMemberCreateRequesting,
	familyMemberUpdateRequesting,
	familyMemberFillData,
	familyMemberCleanValues
} from '../../../redux/familyMember/actions';
import {creditFamilyRequesting} from '../../../redux/credit/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});

class InfoFamily extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			currentFamily: null
		};
		this.handleSetFamilyMember = this.handleSetFamilyMember.bind(this);
	}

	handleSubmit = (values) => {
		const {
			clients: {client},
			credit: {credit},
			client: {token},
			familyMember: { familyMember },
			familyMemberCreateRequesting,
			familyMemberUpdateRequesting,
			creditFamilyRequesting
		} = this.props;
		if (familyMember.hasOwnProperty("id"))
		    familyMemberUpdateRequesting(token,values,client.id,familyMember.id);
	    else
	    	familyMemberCreateRequesting(token,values,client.id);
	    creditFamilyRequesting(token,credit.id);
	}

	componentDidMount(){
		const {
			credit: { family },
			familyMemberFillData
		} = this.props;
		if (family.length > 0)
			familyMemberFillData(family[0]);
	}

	componentDidUpdate(prevProps,prevState){
		const {
			familyMemberFillData,
			credit: {family}
		} = this.props;
		const {currentFamily} = this.state;
		if (prevState.currentFamily != currentFamily){
			const index = family.find(f => f.id == currentFamily).index;
			familyMemberFillData({...family[index] , selectFamilyMember: currentFamily});
		}
	}

	handleSetFamilyMember(e){
		this.setState({currentFamily: e.target.value});
	}

	render(){
		const {
			classes,
			open,
			controlModal,
			handleSubmit,
			credit: { family },
			familyMemberCleanValues
		} = this.props;
		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("familyModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>

			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("familyModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Información familiar</h4>
			</DialogTitle>
			<Form onSubmit={handleSubmit(this.handleSubmit)}>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>
					{family.length > 0 && <Field
					   id={"selectFamilyMember"}
					   name={"selectFamilyMember"}
					   type={"select"}
					   autoComplete={"off"}
					   data={family}
					   onChange={this.handleSetFamilyMember}
					   component={customInputSelect}/>}
					<div className="Conteiner-table">
						<table className="table-date">
							<p>Datos registrados</p>
							<tbody>
							<tr>
								<th>Nombre y apellidos</th>
							<td>
								<Field
									id={"nombresYApellidos"}
									name={"nombresYApellidos"}
									type={"text"}
									autoComplete={"off"}
									component={customInputText}/>
							</td>
							</tr>
							<tr>
								<th>Parentesco</th>
								<td>
									<Field
										id={"parentesco"}
										name={"parentesco"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Dirección</th>
								<td>
										<Field
											id={"direccion"}
											name={"direccion"}
											type={"text"}
											autoComplete={"on"} 
											component={customInputText} 
											classes={classes}/>
											
								</td>
							</tr>
							<tr>
								<th>Telefono</th>
								<td>
									<Field
										id={"telefono"}
										name={"telefono"}
										type={"number"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Celular</th>
								<td>
									<Field
										id={"celular"}
										name={"celular"}
										type={"text"}
										autoComplete={"off"}
										component={customInputText}/>
								</td>
							</tr>
							<tr>
								<th>Ciudad</th>
								<td>
									<Field id={"ciudad"}
										name={"ciudad"}
										type={"text"}
										autoComplete={"on"}
										component={customInputText}
										classes={classes}/>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button
							type={'submit'}
							color="primary"
						>
							Guardar
						</Button>
						<Button
						    type={'button'}
						    color={'primary'}
						    onClick={() => familyMemberCleanValues()}
						>
						   Nuevo
						</Button>
						<Button
							onClick={() => controlModal("familyModal")}
							color="warning"
						>
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
			</Form>
		</Dialog>	
	}
};

InfoFamily.propTypes = {
	classes: PropTypes.object
}

const formed = reduxForm({
	form: "InfoFamily",
	enableReinitialize: true,
	reset
})(withStyles(userProfileStyles)(InfoFamily));

const mapStateToProps = state => ({
	initialValues: state.familyMember.familyMember,
	client: state.client,
	credit: state.credit,
	stateForm: state.form,
	clients: state.clients,
	familyMember: state.familyMember
});

const connected = connect(mapStateToProps, {
	creditFamilyRequesting,
	familyMemberCreateRequesting,
	familyMemberUpdateRequesting,
	familyMemberFillData,
	familyMemberCleanValues
})(withStyles(notificationsStyle)(formed))

export default connected;
