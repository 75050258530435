import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../../commons/errors/apiErrors";
import { meGetError, meGetSuccess, meUpdateError, meUpdateSuccess } from "./actions";
import { ME_GET_REQUESTING, ME_UPDATE_REQUESTING } from "./constants";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import React from "react";

const meUrl = `${process.env.REACT_APP_API_URL}/me`;
const userUrl = `${process.env.REACT_APP_API_URL}/users`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const meGetApi = (token) => {
	return fetch(meUrl, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		})
		.catch((error) => {
			throw error;
		});
};

function* meGetFlow(action) {
	try {
		const { token } = action;
		const user = yield call(meGetApi, token);
		yield put(meGetSuccess(user));
	} catch (error) {
		yield put(meGetError(error));
		showError(error);
	}
}

const meUpdateApi = (token, values) => {
	let body = new FormData();
	body.append("_method", "PUT");
	body.append("name", values.nombre || "");
	body.append("email", values.correo || "");
	body.append("identificationType", values.tipo_identificacion.hasOwnProperty("id") ? values.tipo_identificacion.id : values.tipo_identificacion);
	body.append("identification", values.identificacion || "");
	body.append("phone", values.telefono || "");
	body.append("address", values.direccion || "");
	body.append("city", values.ciudad.hasOwnProperty("id") ? values.ciudad.id : values.ciudad);
	body.append("photos_length", values.hasOwnProperty("photos") ? values.photos.length : 0);
	if (values.hasOwnProperty("photos") && values.photos.length > 0) {
		values.photos.map((fileItem, index) => body.append(`photo_${index}`, fileItem.file));
	}
	// Object.keys(values).map(key => {
	// 	if (key.indexOf('document_') >= 0) {
	// 		body.append(key.split('document_')[1].replace(/\s/g,''), values[key][0].file);
	// 	}
	// });
	if (values.hasOwnProperty("contraseña")) {
		body.append("password", values.contraseña);
	}
	return fetch(`${userUrl}/${values.id}`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`
		},
		body: body
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* userUpdateFlow(action) {
	try {
		const { token, values } = action;
		const user = yield call(meUpdateApi, token, values);
		yield put(meUpdateSuccess(user));
		toastr.success("Usuario actualizado", "El usuario fue actualizado con exito.");
	} catch (error) {
		yield put(meUpdateError(error));
		showError(error);
	}
}

function* meUserWatcher() {
	yield all([
		takeEvery(ME_GET_REQUESTING, meGetFlow),
		takeEvery(ME_UPDATE_REQUESTING, userUpdateFlow)
	]);
}

export default meUserWatcher;