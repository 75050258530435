import {
	NATURAL_PERSON_CREATE_REQUESTING,
	NATURAL_PERSON_CREATE_SUCCESS,
	NATURAL_PERSON_CREATE_ERROR,
	NATURAL_PERSON_UPDATE_REQUESTING,
	NATURAL_PERSON_UPDATE_SUCCESS,
	NATURAL_PERSON_UPDATE_ERROR,
	NATURAL_PERSON_RESET_STATES,
	NATURAL_PERSON_FILL_DATA,
	NATURAL_PERSON_FILL_DATA_SUCCESS,
	NATURAL_PERSON_FILL_DATA_ERROR,
	NATURAL_PERSON_CLEAN_VALUES	
} from './constants';

export const naturalPersonCreateRequesting = (token, values, client) => ({
	type: NATURAL_PERSON_CREATE_REQUESTING,
	token,
	values,
	client
});

export const naturalPersonCreateSuccess = (person) => ({
	type: NATURAL_PERSON_CREATE_SUCCESS,
	person
});

export const naturalPersonCreateError = (error) => ({
	type: NATURAL_PERSON_CREATE_ERROR,
	error
});

export const naturalPersonUpdateRequesting = (token, values, client, id) => ({
	type: NATURAL_PERSON_UPDATE_REQUESTING,
	token,
	values,
	id,
	client
});

export const naturalPersonUpdateSuccess = (person) => ({
	type: NATURAL_PERSON_UPDATE_SUCCESS,
	person
});

export const naturalPersonUpdateError = (error) => ({
	type: NATURAL_PERSON_UPDATE_ERROR,
	error
});

export const naturalPersonResetStates = () => ({
	type: NATURAL_PERSON_RESET_STATES
});

export const naturalPersonFillData = (person, company) => ({
	type: NATURAL_PERSON_FILL_DATA,
	person,
	company
});

export const naturalPersonFillDataSuccess = (person) => ({
	type: NATURAL_PERSON_FILL_DATA_SUCCESS,
	person
});

export const naturalPersonFillDataError = (error) => ({
	type: NATURAL_PERSON_FILL_DATA_ERROR,
	error
});

export const naturalPersonCleanValues = () => ({
	type: NATURAL_PERSON_CLEAN_VALUES
});