import {
  FORGOT_RESET_STATES,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_REQUESTING, RESET_PASSWORD_SUCCESS,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_REQUESTING,
  SEND_EMAIL_SUCCESS, SEND_TOKEN_ERROR,
  SEND_TOKEN_REQUESTING,
  SEND_TOKEN_SUCCESS
} from "./constants";

const initialState = {
  requesting: false,
  success: false,
  error: "",
  formEmail: true,
  formToken: false,
  formPassword: false,
  resetPassword: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_EMAIL_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        formEmail: false,
        formToken: true,
      };
    case SEND_EMAIL_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error,
        formEmail: true,
        formToken: false,
      };
    case SEND_TOKEN_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
      };
    case SEND_TOKEN_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        formToken: false,
        formPassword: true,
      };
    case SEND_TOKEN_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error,
        formToken: true,
        formPassword: false,
      };
    case RESET_PASSWORD_REQUESTING:
      return {
        ...state,
        requesting: true,
        success: false,
        error: "",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        resetPassword: true,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        requesting: false,
        error: action.error,
      };
    case FORGOT_RESET_STATES:
      return {
        ...state,
        requesting: false,
        success: false,
        error: "",
        formEmail: true,
        formToken: false,
        formPassword: false,
        resetPassword: false,
      };
    default:
      return state;
  }
};
export default reducer;