import {
	SUPPLEMENTARY_DATA_CREATE_REQUESTING,
	SUPPLEMENTARY_DATA_CREATE_SUCCESS,
	SUPPLEMENTARY_DATA_CREATE_ERROR,
	SUPPLEMENTARY_DATA_UPDATE_REQUESTING,
	SUPPLEMENTARY_DATA_UPDATE_SUCCESS,
	SUPPLEMENTARY_DATA_UPDATE_ERROR,
	SUPPLEMENTARY_DATA_RESET_STATES,
	SUPPLEMENTARY_DATA_FILL_DATA,
	SUPPLEMENTARY_DATA_FILL_DATA_SUCCESS,
	SUPPLEMENTARY_DATA_FILL_DATA_ERROR,
	SUPPLEMENTARY_DATA_CLEAN_VALUES,
} from './constants';

export const supplementaryDataCreateRequesting = (token , values, client) => ({
	type: SUPPLEMENTARY_DATA_CREATE_REQUESTING,
	token,
	values,
	client
});

export const supplementaryDataCreateSuccess = (supplementaryData) => ({
	type: SUPPLEMENTARY_DATA_CREATE_SUCCESS,
	supplementaryData
});

export const supplementaryDataCreateError = (error) => ({
	type: SUPPLEMENTARY_DATA_CREATE_ERROR,
	error
});

export const supplementaryDataUpdateRequesting = (token, values, client, id) => ({
	type: SUPPLEMENTARY_DATA_UPDATE_REQUESTING,
	token,
	values,
	client,
	id
});

export const supplementaryDataUpdateSuccess = (supplementaryData) => ({
	type: SUPPLEMENTARY_DATA_UPDATE_SUCCESS,
	supplementaryData
});

export const supplementaryDataUpdateError = (error) => ({
	type: SUPPLEMENTARY_DATA_UPDATE_ERROR,
	error
});

export const supplementaryDataResetStates = () => ({
	type: SUPPLEMENTARY_DATA_RESET_STATES
});

export const supplementaryDataFillData = (supplementaryData) => ({
	type: SUPPLEMENTARY_DATA_FILL_DATA,
	supplementaryData
});

export const supplementaryDataFillDataSuccess = (supplementaryData) => ({
	type: SUPPLEMENTARY_DATA_FILL_DATA_SUCCESS,
	supplementaryData
});

export const supplementaryDataFillDataError = (error) => ({
	type: SUPPLEMENTARY_DATA_FILL_DATA_ERROR,
	error
});

export const supplementaryDataCleanValues = () => ({
	type: SUPPLEMENTARY_DATA_CLEAN_VALUES
}); 