/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/


import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles.jsx";
import avatar from "assets/img/faces/marc.jpg";
import avatarProfile from "assets/img/faces/profile2.jpg";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Datetime from "react-datetime";
import Radio from "@material-ui/core/Radio";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

// Integration
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { reduxForm, Form, Field } from "redux-form";
import { meUpdateRequesting } from "../../redux/auth/user/actions";
import ImagesInput from "../../components/inputs/images";
import DocumentsInput from "../../components/inputs/documents";
import { customInputText } from "../../components/inputs/typeText";
import { customInputSelect } from "../../components/inputs/typeSelect";
import { citiesGetRequesting } from "../../redux/city/actions";
import { identificationTypesGetRequesting } from "../../redux/identificationType/actions";
import { documentTypesGetRequesting } from "../../redux/documentType/actions";
import LogoDanko from "../../assets/img/LogoNotLetter.png";


const customInputPassword = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        type: "password",
        autoComplete: "off"
      }}
    />
  );
};

class Cuote extends React.Component {

  constructor(props) {
    super(props);
    const {
      client: { token },
      auth: { user },
      citiesGetRequesting,
      identificationTypesGetRequesting,
      documentTypesGetRequesting
    } = this.props;
    citiesGetRequesting();
    identificationTypesGetRequesting();
    documentTypesGetRequesting();
  }

  handleSubmit = (values) => {
    const {
      client: { token },
      meUpdateRequesting
    } = this.props;
    meUpdateRequesting(token, values);
  };

  render() {
    const {
      classes,
      client: { token },
      auth: { user },
      handleSubmit,
      city: { cities },
      identificationType: { identificationTypes },
      documentType: { documentTypes }
    } = this.props;
    if (token === "") {
      return (
        <Redirect to={"/"}/>
      );
    }
    return (
      <div>
        <GridItem xs={12} sm={12} md={12}>

          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PermIdentity/>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Editar perfil
            </h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
              <GridItem xs={12} sm={12} md={12}>
                <Card profile>
                  <CardAvatar> {/* se elimino el atributo 'profile' del Card avatar */}
                    <img src={LogoDanko} style={{ width: '300px', height: 'auto', margin: 'auto' }} alt={user.nombre}/>
                  </CardAvatar>
                  <br/>
                  <GridItem>
                    <h1 className={classes.TitleH1}>{user.nombre}</h1>
                    <h2 className={classes.TitleH2}>Agente</h2>
                    <h3 className={classes.TitleH3}>Telefono : {user.telefono}</h3>
                    {/*<GridItem xs={12} sm={12} md={12}>*/}
                      {/*<Field name={"photos"} maxFiles={1} className={"filepond"}*/}
                             {/*component={ImagesInput} label={"Actualizar foto"}/>*/}
                    {/*</GridItem>*/}
                  </GridItem>
                  <CardBody>
                    <h1 className={classes.TitleH1Left}>Datos personales</h1>
                    <GridContainer>

                      <GridItem xs={12} sm={6} md={6}>
                        <Field id={"nombre"} name={"nombre"} type={"text"} label={"Nombre *"} autoComplete={"off"}
                               component={customInputText}/>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <Field id={"correo"} name={"correo"} type={"email"} label={"Correo *"} autoComplete={"off"}
                               component={customInputText}/>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        {identificationTypes.length > 0 && (
                          <Field id={"tipo_identificacion"} name={"tipo_identificacion"} type={"select"}
                                 label={"Tipo de identificación *"}
                                 autoComplete={"on"} component={customInputSelect} classes={classes}
                                 data={identificationTypes}/>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <Field id={"identificacion"} name={"identificacion"} type={"number"} label={"Identificación *"}
                               autoComplete={"off"}
                               component={customInputText}/>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <Field id={"telefono"} name={"telefono"} type={"number"} label={"Telefono *"}
                               autoComplete={"off"}
                               component={customInputText}/>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <Field id={"direccion"} name={"direccion"} type={"number"} label={"Dirección"}
                               autoComplete={"off"}
                               component={customInputText}/>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        {cities.length > 0 && (
                          <Field id={"ciudad"} name={"ciudad"} type={"select"} label={"Ciudad *"}
                                 autoComplete={"on"} component={customInputSelect} classes={classes} data={cities}/>
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <Field id={"contraseña"} name={"contraseña"} label={"Contraseña *"} autoComplete={"off"}
                               component={customInputPassword}/>
                      </GridItem>
                    </GridContainer>
                    <Button color="primary" className={classes.updateProfileButton} type={"submit"}>
                      Actualizar
                    </Button>
                  </CardBody>


                </Card>
              </GridItem>
            </Form>
            <Clearfix/>;
            ;
          </CardBody>
        </GridItem>


      </div>
    )
      ;
  }
}

Cuote.propTypes = {
  classes: PropTypes.object
};
const formed = reduxForm({
  form: "editUser",
  enableReinitialize: true
})(withStyles(userProfileStyles)(Cuote));

const mapStateToProps = state => ({
  client: state.client,
  auth: state.auth,
  initialValues: state.auth.user,
  city: state.city,
  identificationType: state.identificationType,
  documentType: state.documentType
});

const connected = connect(mapStateToProps, {
  meUpdateRequesting,
  citiesGetRequesting,
  identificationTypesGetRequesting,
  documentTypesGetRequesting
})(formed);

export default connected;
