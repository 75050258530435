/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles.jsx";
import avatar from "assets/img/faces/marc.jpg";
import LogoDanko from "../../assets/img/LogoNotLetter.png";
//Integration
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {Form, reduxForm, Field, reset} from "redux-form";
import FormClient from "../../components/formQuote/client";
import FormPolicy from "../../components/formQuote/policy";
import FormPolicyData from "../../components/formQuote/policyData";
import FormCredit from "../../components/formQuote/credit";
import FormPerson from '../../components/formQuote/Person/index';
import FormFamily from '../../components/formQuote/family';
import FormOneroso from '../../components/formQuote/oneroso';
import FormSupplementaryData from '../../components/formQuote/supplementaryData';

class Cuote extends React.Component {

	constructor(props){
		super(props);
		this.responseCentralRisk = this.responseCentralRisk.bind(this);
		this.state = {formClientConfirmed : false};
	}

	responseCentralRisk(value){
		this.setState({ formClientConfirmed : value });
	}

	render() {
		const {
			classes,
			client: { token },
			clients: { client },
			oneroso: { success },
			credit: { credit },
			policy,
			policyForm,
			legalPerson,
			naturalPerson,
			familyMember: { familyMember }
		} = this.props;
		let personRegister = legalPerson.person.hasOwnProperty("id") || naturalPerson.person.hasOwnProperty("id") ? true : false;
		if (token === "") {
			return <Redirect to={"/"}/>;
		}
		return (
			<div>
				<GridItem xs={12} sm={12} md={12}>
					<CardHeader color="primary" icon>
						<CardIcon color="primary">
							<PermIdentity/>
						</CardIcon>
						<h4 className={classes.cardIconTitle}>
							Cotizar
						</h4>
					</CardHeader>
					<CardBody>
						<GridItem xs={12} sm={12} md={12}>
							<Card profile>
								<CardAvatar> {/* se elimino el atributo 'profile' del Card avatar */}
									<img src={LogoDanko} style={{ width: '300px', height: 'auto', margin: 'auto' }} alt="logoDanko"/>
								</CardAvatar>
								<CardBody profile>
									<GridItem xs={12} sm={6} md={12}>
										{/*<div className={classes.socialLine}>*/}
											{/*{[*/}
												{/*"fab fa-facebook-square",*/}
												{/*"fab fa-twitter",*/}
												{/*"fab fa-google-plus"*/}
											{/*].map((prop, key) => {*/}
												{/*return (*/}
													{/*<Button*/}
														{/*color="transparent"*/}
														{/*justIcon*/}
														{/*key={key}*/}
														{/*className={classes.customButtonClass}*/}
													{/*>*/}
														{/*<i className={prop}/>*/}
													{/*</Button>*/}
												{/*);*/}
											{/*})}*/}
										{/*</div>*/}
									</GridItem>
									{/*<h1 className={classes.TitleH1}>Danko</h1>*/}
									{/*<h2 className={classes.TitleH2}>Unicos en el mercado</h2>*/}
									{/*<h3 className={classes.TitleH3}>Telefono : 312457878</h3>*/}
									<p className={classes.Textparagraph}>Ofrecemos créditos a tasas muy competitivas, de
										fácil acceso, con respuesta y desembolso rápido.</p>
								</CardBody>
							</Card>
						</GridItem>
						<FormPolicy/>
						<FormCredit responseCentralRisk={this.responseCentralRisk}/>
						{this.state.formClientConfirmed && <FormClient/>}
						{client.hasOwnProperty("id") &&
						   <FormPerson/>
					    }
						{personRegister &&
						   <FormFamily/>
					    }
						{credit.hasOwnProperty("id") && personRegister && familyMember.hasOwnProperty("id") &&  <>
                           <FormPolicyData/>
                        </>}
                        {personRegister && familyMember.hasOwnProperty("id") && policyForm.values.beneficiario_honeroso && policy.update &&
                           <FormOneroso/>
                        }
                        {personRegister && familyMember.hasOwnProperty("id") && (success || !policyForm.values.beneficiario_honeroso) && policy.update &&
                           <FormSupplementaryData/>
                        }
						<Clearfix/>
					</CardBody>
				</GridItem>
			</div>
		);
	}
}

Cuote.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => ({
	credit: state.credit,
	client: state.client,
	oneroso: state.oneroso,
	clients: state.clients,
	policy: state.policy,
	auth: state.auth,
	legalPerson: state.legalPerson,
	naturalPerson: state.naturalPerson,
	familyMember: state.familyMember,
	policyForm: state.form.policy
});

const connected = connect(mapStateToProps, {
})(withStyles(userProfileStyles)(Cuote));

const formed = reduxForm({
  form: "policyForm",
  reset
})(connected);

export default formed;
