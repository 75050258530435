import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import { paymentPlanConfirmedPaymentError, paymentPlanConfirmedPaymentSuccess } from "./actions";
import { creditControlModal, creditPaymentPlansSuccess } from "../credit/actions";
import { PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING } from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/paymentPlans`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const paymentPlanConfirmedPaymentApi = (token, paymentPlan) => {
	return fetch(`${baseUrl}/${paymentPlan.id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`
		},
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* paymentPlanConfirmedPaymentFlow(action) {
	try {
		const { token, paymentPlan } = action;
		const paymentPlans = yield call(paymentPlanConfirmedPaymentApi, token, paymentPlan);
		yield put(creditPaymentPlansSuccess(paymentPlans));
		yield put(paymentPlanConfirmedPaymentSuccess());
		toastr.success("Cuota actualizada", `El pago de la cuota #${paymentPlan.numero_cuota} del credito ${paymentPlan.credito.id} ha sido confirmada con exito.`);
		// yield put(creditControlModal("paymentPlansModal"));
	} catch (error) {
		yield put(paymentPlanConfirmedPaymentError(error));
		showError(error);
	}
}

function* paymentPlanWatcher() {
	yield all([
		takeEvery(PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING, paymentPlanConfirmedPaymentFlow),
	])
}

export default paymentPlanWatcher;