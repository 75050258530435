// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridItem from "../../../components/Grid/GridItem";

import { FormattedNumber } from "react-intl";

const formattedNumber = (value) => {
	return (
		<FormattedNumber value={value} style={"currency"} currency={"COP"}
		                 minimumFractionDigits={0} maximumFractionDigits={0}/>
	);
};
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});
class DateCredit extends React.Component {
	render() {
		const {
			classes,
			open,
			controlModal,
			paymentPlans,
		} = this.props;
		return <Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modalLarge
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("paymentPlansModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description"
		>
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>
			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("paymentPlansModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Datos de Credito</h4>
			</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody}>
					{paymentPlans.length > 0 && (
							<div className="Conteiner-table">
								<table className="table-pyments-page-price">
									<thead>
									<tr>
										<th>Cuota</th>
										<th>Valor cuota</th>
										<th>Intereses moratorios</th>
										<th>Total a pagar</th>
										<th>Valor interes</th>
										<th>Valor capital</th>
										<th>Saldo capital</th>
										<th>Fecha de pago</th>
										<th>Estado</th>
									</tr>
									</thead>
									<tbody>
									{paymentPlans.map((planPayment, index) => (
										<tr key={index}>
											<td>{planPayment.numero_cuota}</td>
											<td>{formattedNumber(planPayment.valor_cuota)}</td>
											<td>{formattedNumber(planPayment.intereses)}</td>
											<td>{formattedNumber(planPayment.total)}</td>
											<td>{formattedNumber(planPayment.valor_interes)}</td>
											<td>{formattedNumber(planPayment.valor_capital)}</td>
											<td>{formattedNumber(planPayment.saldo_capital)}</td>
											<td>{planPayment.fecha_limite_pago}</td>
											<td>{planPayment.estado.nombre}</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
					)}
					<br/>
					<DialogActions className={classes.modalFooter}>
						<Button onClick={() => controlModal("paymentPlansModal")} color="warning">
							Cerrar
						</Button>
					</DialogActions>
			</DialogContent>
		</Dialog>;
	}
}
DateCredit.propTypes = {
	classes: PropTypes.object
};

export default withStyles(notificationsStyle)(DateCredit);
