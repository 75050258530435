import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import login from "./auth/login/reducer";
import logout from "./auth/logout/reducer";
import register from "./auth/register/reducer";
import client from "./auth/client/reducer";
import auth from "./auth/user/reducer";
import state from "./state/reducer";
import city from "./city/reducer";
import identificationType from "./identificationType/reducer";
import oneroso from './oneroso/reducer';
import documentType from "./documentType/reducer";
import document from "./document/reducer";
import credit from "./credit/reducer";
import clients from "./client/reducer";
import configuration from "./configuration/reducer";
import sureTypeFeature from "./sureTypeFeature/reducer";
import insurance from "./insurance/reducer";
import sureType from "./sureType/reducer";
import feature from "./feature/reducer";
import policy from "./policy/reducer";
import paymentPlan from "./paymentPlan/reducer";
import verifyEmail from "./auth/verifyEmail/reducer";
import forgot from "./auth/forgot/reducer";
import naturalPerson from './naturalPerson/reducer';
import legalPerson from './legalPerson/reducer';
import familyMember from './familyMember/reducer';
import supplementaryData from './supplementaryData/reducer';

const IndexReducer = combineReducers({
	form,
	toastr: toastrReducer,
	login,
	logout,
	register,
	client,
	auth,
	state,
	city,
	identificationType,
	documentType,
	document,
	oneroso,
	naturalPerson,
	legalPerson,
	familyMember,
	supplementaryData,
	credit,
	clients,
	configuration,
	sureTypeFeature,
	insurance,
	sureType,
	feature,
	policy,
	paymentPlan,
	verifyEmail,
	forgot
});

export default IndexReducer;