import {
	CLIENT_CHANGE_STATE_ERROR,
	CLIENT_CHANGE_STATE_REQUESTING,
	CLIENT_CHANGE_STATE_SUCCESS,
	CLIENT_CONTROL_MODAL,
	CLIENT_CREATE_ERROR,
	CLIENT_CREATE_REQUESTING,
	CLIENT_CREATE_SUCCESS, CLIENT_FIND_IDENTIFICATION_ERROR,
	CLIENT_FIND_IDENTIFICATION_REQUESTING, CLIENT_FIND_IDENTIFICATION_SUCCESS,
	CLIENT_FOCUS, CLIENT_RESET_FOCUS,
	CLIENT_RESET_STATES,
	CLIENT_UPDATE_ERROR,
	CLIENT_UPDATE_REQUESTING,
	CLIENT_UPDATE_SUCCESS,
	CLIENTS_GET_ERROR,
	CLIENTS_GET_REQUESTING,
	CLIENTS_GET_SUCCESS,
	CLIENTS_SEARCH_ERROR,
	CLIENTS_SEARCH_REQUESTING,
	CLIENTS_SEARCH_SUCCESS,
	CLIENT_CLEAN_VALUES,
	CLIENT_FILL_DATA,
	CLIENT_FILL_DATA_SUCCESS,
	CLIENT_FILL_DATA_ERROR
} from "./constants";

export const clientsGetRequesting = (token, filter, paginate) => ({
	type: CLIENTS_GET_REQUESTING,
	token, filter, paginate
});

export const clientsGetSuccess = (clients) => ({
	type: CLIENTS_GET_SUCCESS,
	clients
});

export const clientsGetError = (error) => ({
	type: CLIENTS_GET_ERROR,
	error
});

export const clientCreateRequesting = (token, values, typeIdentification, creditId) => ({
	type: CLIENT_CREATE_REQUESTING,
	token, values, typeIdentification, creditId
});

export const clientCreateSuccess = (client) => ({
	type: CLIENT_CREATE_SUCCESS,
	client
});

export const clientCreateError = (error) => ({
	type: CLIENT_CREATE_ERROR,
	error
});

export const clientUpdateRequesting = (token, values) => ({
	type: CLIENT_UPDATE_REQUESTING,
	token, values
});

export const clientUpdateSuccess = (client) => ({
	type: CLIENT_UPDATE_SUCCESS,
	client
});

export const clientUpdateError = (error) => ({
	type: CLIENT_UPDATE_ERROR,
	error
});

export const clientChangeStateRequesting = (token, clientId) => ({
	type: CLIENT_CHANGE_STATE_REQUESTING,
	token, clientId
});

export const clientChangeStateSuccess = (client) => ({
	type: CLIENT_CHANGE_STATE_SUCCESS,
	client
});

export const clientChangeStateError = (error) => ({
	type: CLIENT_CHANGE_STATE_ERROR,
	error
});

export const clientsSearchRequesting = (token, values) => ({
	type: CLIENTS_SEARCH_REQUESTING,
	token, values
});

export const clientsSearchSuccess = (clients) => ({
	type: CLIENTS_SEARCH_SUCCESS,
	clients
});

export const clientsSearchError = (error) => ({
	type: CLIENTS_SEARCH_ERROR,
	error
});

export const clientFindIdentificationRequesting = (token, values, typeIdentification, creditId) => ({
	type: CLIENT_FIND_IDENTIFICATION_REQUESTING,
	token, values, typeIdentification , creditId
});

export const clientFindIdentificationSuccess = (client) => ({
	type: CLIENT_FIND_IDENTIFICATION_SUCCESS,
	client,
});

export const clientFindIdentificationError = (error) => ({
	type: CLIENT_FIND_IDENTIFICATION_ERROR,
	error,
});

export const clientControlModal = (modal) => ({
	type: CLIENT_CONTROL_MODAL,
	modal
});

export const clientFocus = (client) => ({
	type: CLIENT_FOCUS,
	client
});

export const clientResetFocus = () => ({
	type: CLIENT_RESET_FOCUS,
});

export const clientResetStates = () => ({
	type: CLIENT_RESET_STATES
});

export const clientCleanValues = () => ({
	type: CLIENT_CLEAN_VALUES
});

export const clientFillData = (client) => ({
	type: CLIENT_FILL_DATA,
	client
});

export const clientFillDataSuccess = (client) => ({
	type: CLIENT_FILL_DATA_SUCCESS,
	client 
});

export const clientFillDataError = (error) => ({
	type: CLIENT_FILL_DATA_ERROR,
	error
});
