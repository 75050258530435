import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import {
	configurationsGetError,
	configurationsGetSuccess,
} from "./actions";
import {
	CONFIGURATIONS_GET_REQUESTING,
} from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/configurations`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const configurationsGetApi = () => {
	return fetch(`${baseUrl}`, {
		method: "GET"
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* configurationsGetFlow() {
	try {
		const configurations = yield call(configurationsGetApi);
		yield put(configurationsGetSuccess(configurations));
	} catch (error) {
		yield put(configurationsGetError(error));
	}
}

function* configurationWatcher() {
	yield all([
		takeEvery(CONFIGURATIONS_GET_REQUESTING, configurationsGetFlow),
	]);
}

export default configurationWatcher;