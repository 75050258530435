import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dialog, CardHeader, DialogTitle, DialogContent, DialogActions, Slide } from "@material-ui/core";
import { MailOutline, Close } from "@material-ui/icons";
import CardIcon from "../../../components/Card/CardIcon";
import Button from "../../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { FormattedNumber } from "react-intl";

const DetailCreditModal = props => {

	const { classes, controlModal, open } = props;

	const creditState = useSelector(state => state.credit);

	const { credit } = creditState;

	const Transition = React.forwardRef(function Transition(props, ref) {
		return <Slide direction="down" ref={ref} {...props} />;
	});

	return (
		<Dialog
			classes={{
				root: classes.center + " " + classes.modalRoot,
				paper: classes.modal
			}}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => controlModal("detailCreditModal")}
			aria-labelledby="classic-modal-slide-title"
			aria-describedby="classic-modal-slide-description">
			<CardHeader color="primary" icon>
				<CardIcon color="primary">
					<MailOutline/>
				</CardIcon>
			</CardHeader>
			<DialogTitle
				id="classic-modal-slide-title"
				disableTypography
				className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key="close"
					aria-label="Close"
					color="transparent"
					onClick={() => controlModal("detailCreditModal")}
				>
					<Close className={classes.modalClose}/>
				</Button>
				<h4 className={classes.modalTitle}>Detalle del credito</h4>
			</DialogTitle>
			<DialogContent
				id="classic-modal-slide-description"
				className={classes.modalBody}>
				<DialogContent id="classic-modal-slide-description"
				               className={classes.modalBody}>
					<div className="Conteiner-table">
						<table className="table-date4">
							<tbody>
							<tr>
								<th>Porcentaje cuota inicial</th>
								<td>{credit.porcentaje_credito} %</td>
							</tr>
							<tr>
								<th>Valor cuota inicial</th>
								<td>
									<FormattedNumber
										value={credit.valor_cuota_inicial}
										style={"currency"}
										currency={"COP"}
										minimumFractionDigits={0}
										maximumFractionDigits={0}
									/>
								</td>
							</tr>
							<tr>
								<th>Tasa efectivo anual</th>
								<td>{credit.tasa_efectivo_anual} %</td>
							</tr>
							<tr>
								<th>Tasa mes vencido</th>
								<td>{credit.tasa_mes_vencido} %</td>
							</tr>
							<tr>
								<th>Cuota de administración</th>
								<td>
									<FormattedNumber
										value={credit.plan_pagos.cuota_administracion}
										style={"currency"}
										currency={"COP"}
										minimumFractionDigits={0}
										maximumFractionDigits={0}
									/>
								</td>
							</tr>
							<tr>
								<th>Numero de cuotas</th>
								<td>{credit.cuotas}</td>
							</tr>
							<tr>
								<th>No. Pagaré</th>
								<td>{credit.id}</td>
							</tr>
							</tbody>
						</table>
					</div>
					<DialogActions className={classes.modalFooter}>
						<Button
							onClick={() => controlModal("detailCreditModal")}
							color="warning"
						>
							Cerrar
						</Button>
					</DialogActions>
				</DialogContent>
			</DialogContent>
		</Dialog>
	);
};

DetailCreditModal.propTypes = {
	classes: PropTypes.object
};

export default withStyles(notificationsStyle)(DetailCreditModal);
