import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../../commons/errors/apiErrors";
import { loginPostError, loginPostSuccess } from "./actions";
import { LOGIN_POST_REQUESTING } from "./constants";
import { clientSet } from "../client/actions";
import { toastr } from "react-redux-toastr";
import { meGetRequesting } from "../user/actions";
import { ErrorComponent } from "../../../commons/errors/errors";
import React from "react";

const loginUrl = `${process.env.REACT_APP_API_URL}/loginAgent`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};


const loginPostApi = (values) => {
	let body = {
		email: values.email,
		password: values.password,
		device: "desktop"
	};
	return fetch(loginUrl, {
		method: "POST",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.hasOwnProperty("access_token")) {
				localStorage.setItem("@danko:agent", json.access_token);
				return json.access_token;
			}
			throw json.data;
		})
		.catch((error) => {
			throw error;
		});
};

function* loginPostFlow(action) {
	try {
		const { values } = action;
		const token = yield call(loginPostApi, values);
		yield put(clientSet(token));
		yield put(loginPostSuccess());
		yield put(meGetRequesting(token));
	} catch (error) {
		yield put(loginPostError(error));
		showError(error);
	}
}

function* loginWatcher() {
	yield all([
		takeEvery(LOGIN_POST_REQUESTING, loginPostFlow)
	]);
}

export default loginWatcher;