import {
	ONEROSO_GET_ERROR,
	ONEROSO_GET_REQUESTING,
	ONEROSO_GET_SUCCESS,
	ONEROSO_FIND_BY_ID,
	ONEROSO_SET_CREDIT_ID_REQUESTING,
	ONEROSO_SET_CREDIT_ID_SUCCESS,
	ONEROSO_SET_CREDIT_ID_ERROR, ONEROSO_CURRENT_CLEAR
} from "./constants";

const initialState = {
	requesting: false,
	success: false,
	error: "",
	oneroso: [],
	currentOneroso: {}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ONEROSO_GET_REQUESTING:
			return {
				...state,
				requesting: true,
				success: false,
				error: ""
			};
		case ONEROSO_GET_SUCCESS:
			return {
				...state,
				requesting: false,
				success: false,
				oneroso: action.oneroso
			};
		case ONEROSO_GET_ERROR:
			return {
				...state,
				requesting: false,
				error: action.error
			};
	    case ONEROSO_FIND_BY_ID:
	        const findOneroso = state.oneroso.find((o) => o.id == action.onerosoId);
	        findOneroso.selectOneroso = action.onerosoId;
	        return {
	        	...state,
	        	currentOneroso: findOneroso,
	        };
	    case ONEROSO_SET_CREDIT_ID_REQUESTING:
	        return {
	        	...state,
	        	requesting: true,
	        	success: false,
	        	error: ""
	        };
	    case ONEROSO_SET_CREDIT_ID_SUCCESS:
	        return {
	        	...state,
	        	requesting: false,
	        	success: true,
	        	error: ""
	        };
	    case ONEROSO_SET_CREDIT_ID_ERROR:
	        return {
	        	...state,
	        	requesting: false,
	        	success: false,
	        	error: action.error
	        };
		case ONEROSO_CURRENT_CLEAR :
			return {
				...state,
				currentOneroso: {}
			};
		default:
			return state;
	}
};

export default reducer;
