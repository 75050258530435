/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import Background from "./../../assets/img/19154-[Convertido].jpg";
import Logo from "./../../assets/img/LogoNotLetter.png";

//Integration
import { Field, Form, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  sendEmailRequesting,
  sendEmailSuccess,
  sendEmailError,
  sendTokenRequesting,
  resetPasswordRequesting
} from "../../redux/auth/forgot/actions";
import { Redirect, Link } from "react-router-dom";
import { customInputText } from "../../components/inputs/typeText";

const customInputPassword = ({ input, label, id, name, classes, meta: { touched, error }, ...rest }) => {
  return (
    <CustomInput
      {...input}
      {...rest}
      labelText={label}
      id={id}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: input.onChange,
        endAdornment: (
          <InputAdornment position="end">
            <Icon className={classes.inputAdornmentIcon}>
              lock_outline
            </Icon>
          </InputAdornment>
        ),
        type: "password",
        autoComplete: "off"
      }}
    />
  );
};

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
      viewLogin: false
    };
  }

  componentDidMount() {
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleSubmit = (values) => {
    const {
      forgot: { formEmail, formToken, formPassword },
      sendEmailRequesting,
      sendTokenRequesting,
      resetPasswordRequesting
    } = this.props;
    if (formEmail) {
      sendEmailRequesting(values);
    }
    if (formToken) {
      sendTokenRequesting(values);
    }
    if (formPassword) {
      resetPasswordRequesting(values);
    }
  };

  render() {
    const {
      classes,
      handleSubmit,
      forgot: { formEmail, formToken, formPassword, resetPassword }
    } = this.props;
    if (this.state.viewLogin || resetPassword) {
      return (
        <Redirect to={"/"}/>
      );
    }
    return (
      <div className="login-back" style={{
        backgroundImage: `url(${Background})`, backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <GridContainer>
                    <GridItem className="style_img_login" xs={12} sm={6} md={12}>
                      <div style={{backgroundColor: "#fff", overflow: "hidden", width: "50%", paddingTop: 5, paddingBottom: 5, borderRadius: 7, margin: "auto"}}>
                         <img src={Logo} alt="Logo"/>
                      </div>
                    </GridItem>

                    <GridItem className="style_text" xs={12} sm={6} md={12}>
                      <h2 className={classes.cardTitle} style={{color: "#fff"}}>Danko</h2>
                      <h4 className={classes.cardTitle} style={{color: "#fff"}}>Recuperación de contraseña</h4>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                {formEmail && (
                  <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    <CardBody>
                      <GridItem className="style_text" xs={12} sm={12} md={12}>
                        <p className={classes.cardTitle}>Ingresa tu correo electronico para encontrar tu cuenta como
                          agente</p>
                      </GridItem>
                      <Field classes={classes} id={"email"} name={"email"} label={"Email..."}
                             type={"text"} component={customInputText}/>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button color={"primary"} block onClick={() => this.props.sendEmailSuccess()}>
                        Ya cuento con un token
                      </Button>
                      <Button color={"primary"} block type={"submit"}>
                        Enviar
                      </Button>
                    </CardFooter>
                    <Button color={"primary"} block onClick={() => this.setState({ viewLogin: true })}>
                      Volver atras
                    </Button>
                  </Form>
                )}
                {formToken && (
                  <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    <CardBody>
                      <GridItem className="style_text" xs={12} sm={12} md={12}>
                        <p className={classes.cardTitle}>Ingresa el token que hemos enviado a tu correo</p>
                      </GridItem>
                      <Field classes={classes} id={"token"} name={"token"} label={"Ingresar Token *"}
                             type={"text"} component={customInputText}/>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button color={"primary"} block onClick={() => this.props.sendEmailError("")}>
                        Volver
                      </Button>
                      <Button color={"primary"} block type={"submit"}>
                        Validar Token
                      </Button>
                    </CardFooter>
                  </Form>
                )}
                {formPassword && (
                  <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    <CardBody>
                      <GridItem className="style_text" xs={12} sm={12} md={12}>
                        <h2>Ingresa la nueva contraseña</h2>
                        <p className={classes.cardTitle}>La nueva contraseña debe ser minimo de 6 caracteres</p>
                      </GridItem>
                      <Field classes={classes} id={"password"} name={"password"} label={"Nueva contraseña"}
                             type={"text"} component={customInputPassword}/>
                      <Field classes={classes} id={"re_password"} name={"re_password"}
                             label={"Confirmar contraseña"}
                             type={"text"} component={customInputPassword}/>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                      <Button color={"primary"} block type={"submit"}>
                        Cambiar contraseña
                      </Button>
                    </CardFooter>
                  </Form>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  forgot: state.forgot
});

const connected = connect(mapStateToProps, {
  sendEmailRequesting,
  sendEmailSuccess,
  sendEmailError,
  sendTokenRequesting,
  resetPasswordRequesting
})(withStyles(loginPageStyle)(LoginPage));

const formed = reduxForm({
  form: "forgot"
})(connected);
export default formed;
