import {
	PAYMENT_PLAN_CONFIRMED_PAYMENT_ERROR,
	PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING,
	PAYMENT_PLAN_CONFIRMED_PAYMENT_SUCCESS
} from "./constants";

export const paymentPlanConfirmedPaymentRequesting = (token, paymentPlan) => ({
	type: PAYMENT_PLAN_CONFIRMED_PAYMENT_REQUESTING,
	token, paymentPlan
});

export const paymentPlanConfirmedPaymentSuccess = () => ({
	type: PAYMENT_PLAN_CONFIRMED_PAYMENT_SUCCESS,
});

export const paymentPlanConfirmedPaymentError = (error) => ({
	type: PAYMENT_PLAN_CONFIRMED_PAYMENT_ERROR,
	error
});