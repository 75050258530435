import * as firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDbLCe5kQ1KgCKgCZazlc2_3IYEImPm5sY",
    authDomain: "danko-2219b.firebaseapp.com",
    projectId: "danko-2219b",
    storageBucket: "danko-2219b.appspot.com",
    messagingSenderId: "446268569386",
    appId: "1:446268569386:web:ce8dcb233f8518bad0d053",
    measurementId: "G-M7KR3ZVE4K"
};

var messaging = null

if (firebase.messaging.isSupported()){
	const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
    messaging = initializedFirebaseApp.messaging();
    messaging.usePublicVapidKey(
        // Project Settings => Cloud Messaging => Web Push certificates
        "BGJWkPM_AFzmknEcVEScuXAhr5VSdqNlZySWC64SKXGtYTkcrFPHqlHl2H-yHT-DvPswd3POfrdIWbVrAxoWLqE"
    );
}

export {messaging}

