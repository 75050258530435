import {call, put, all, takeEvery} from 'redux-saga/effects';
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import React from "react";
import {Redirect} from "react-router-dom";
import {reset} from "redux-form";
import {
    familyMemberCreateSuccess,
    familyMemberCreateError,
    familyMemberResetStates,
    familyMemberUpdateSuccess,
    familyMemberUpdateError,
    familyMemberFillDataSuccess,
    familyMemberFillDataError
} from './actions';

import {
    FAMILY_MEMBER_CREATE_REQUESTING,
    FAMILY_MEMBER_UPDATE_REQUESTING,
    FAMILY_MEMBER_FILL_DATA
} from './constants';

const baseUrl = `${process.env.REACT_APP_API_URL}/familyMember`;

const showError = (error) => {
    let toastOptions = {
        component: (
            <ErrorComponent message={error}/>
        )
    };
    toastr.error("Error", toastOptions);
};

const familyMemberCreateApi = (token, values, client) => {
    console.log(client);
    let body = {
        'name': values.nombresYApellidos,
        'relationship': values.parentesco,
        'address': values.direccion,
        'landline': values.telefono,
        'phone': values.celular,
        'city': values.ciudad
    };

    return fetch(`${baseUrl}/${client}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            if (!json.code)
                return json.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });

};


const familyMemberUpdateApi = (token, values, client, id) => {
    let body = {
        'name': values.nombresYApellidos,
        'relationship': values.parentesco,
        'address': values.direccion,
        'landline': values.telefono,
        'phone': values.celular,
        'city': values.ciudad
    };
    return fetch(`${baseUrl}/${id}/${client}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            if (!json.code)
                return json.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });

};

function* familyMemberCreateFlow(action) {
    try {
        const {token, values, client} = action;
        const familyMember = yield call(familyMemberCreateApi, token, values, client);
        familyMember.nombresYApellidos = familyMember.nombre;
        yield put(familyMemberResetStates());
        yield put(familyMemberCreateSuccess(familyMember));
        toastr.success("Registrado", "Información personal registrada exitosamente.");
    } catch (error) {
        yield put(familyMemberCreateError(error));
        showError(error);
    }
}

function* familyMemberUpdateFlow(action) {
    try {
        const {token, values, client, id} = action;
        const familyMember = yield call(familyMemberUpdateApi, token, values, client, id);
        yield put(familyMemberResetStates());
        yield put(familyMemberUpdateSuccess());
        toastr.success("Actualizado", "Información personal actualizada exitosamente.");
    } catch (error) {
        yield put(familyMemberUpdateError(error));
        showError(error);
    }
}

function* familyMemberFillDataFlow(action) {
    try {
        const {family} = action;
        const familyMember = {
            id: family.id,
            nombresYApellidos: family.nombre,
            parentesco: family.parentesco,
            direccion: family.direccion,
            telefono: family.telefono,
            celular: family.celular,
            ciudad: family.ciudad
        };
        if (family.hasOwnProperty("selectFamilyMember"))
            familyMember.selectFamilyMember = family.selectFamilyMember;
        yield put(familyMemberFillDataSuccess(familyMember));
    } catch (error) {
        yield put(familyMemberFillDataError(error));
    }
}

function* familyMemberWatcher() {
    yield all([
        takeEvery(FAMILY_MEMBER_CREATE_REQUESTING, familyMemberCreateFlow),
        takeEvery(FAMILY_MEMBER_UPDATE_REQUESTING, familyMemberUpdateFlow),
        takeEvery(FAMILY_MEMBER_FILL_DATA, familyMemberFillDataFlow)
    ]);
}

export default familyMemberWatcher;
