/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import AdminLayout from "layouts/Admin.jsx";
import LoginPagefrom from "./views/Pages/LoginPage";
import ForgetPageFrom from "./views/Pages/ForgetPassword";
import CheckInPageFrom from "./views/Pages/checkIn";
import VerifyPage from "./views/Pages/verify";
import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
//Integration
import {applyMiddleware, createStore, compose} from "redux";
import createSagaMiddleware from "redux-saga";
import {IndexSagas} from "./redux/index-sagas";
import IndexReducer from "./redux/index-reducer";
import ReduxToastr from "react-redux-toastr";
import Routes from "./commons/routes/routes";
import {Provider} from "react-redux";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import {IntlProvider} from "react-intl";
import FirebaseMessage from "./pushNotification/push";
import * as serviceWorker from './serviceWorker';


const composeSetup =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    IndexReducer,
    composeSetup(applyMiddleware(sagaMiddleware))
);


sagaMiddleware.run(IndexSagas);

const hist = createBrowserHistory();


if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
            console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
            console.log("Service worker registration failed, error:", err);
        });
}

ReactDOM.render(
    <IntlProvider locale={"es-CO"}>
        <Provider store={store}>
            <Router history={hist}>
                <div className="App">
                    <ReduxToastr
                        timeOut={10000}
                        newestOnTop={false}
                        preventDuplicates
                        position="top-right"
                        transitionIn="bounceInDown"
                        transitionOut="bounceOutUp"
                        progressBar
                        closeOnToastrClick
                    />
                    <Routes/>
                    <Switch>
                        <Route exact path="/" component={LoginPagefrom}/>
                        <Route path="/admin" component={AdminLayout}/>
                        <Route path="/checkIn" component={CheckInPageFrom}/>
                        <Route path="/forget" component={ForgetPageFrom}/>
                        <Route path={"/verify/:token"} component={VerifyPage}/>
                    </Switch>
                </div>
            </Router>
        </Provider>
    </IntlProvider>,
    document.getElementById("root")
);

serviceWorker.register();
// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register("./firebase-messaging-sw.js")
//         .then(function (registration) {
//             console.log("Registration successful, scope is:", registration.scope);
//         })
//         .catch(function (err) {
//             console.log("Service worker registration failed, error:", err);
//         })
// }



