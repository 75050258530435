import * as firebase from "firebase/app";
import '@firebase/messaging';
import {toastr} from "react-redux-toastr";
import {connect} from 'react-redux';
import React from 'react';
import {configurationsGetRequesting} from "../redux/configuration/actions";
import {messaging} from "../init-fcm";

export const askForPermissioToReceiveNotifications = async () => {
    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        return await messaging.getToken();
    } catch (error) {
        console.error(error);
    }

};

class FirebaseMessage extends React.Component {

    state = {
        windowState: 'focus'
    };

    onMessageFirebase = (payload) => {
        const {configurationsGetRequesting} = this.props;
        try {
            configurationsGetRequesting();
            if (this.props.onMessageReceived) {
                if (payload.data['firebase-messaging-msg-data']) {
                    this.props.onMessageReceived(payload.data['firebase-messaging-msg-data'].data);
                }

                if (payload.data.data) {
                    this.props.onMessageReceived(payload.data.data);
                }
            }
            //toastr.success("Atención", "Las tasas han sido actualizadas por los administradores");
        } catch (error) {
            console.error(error);
        }
    };

    componentDidMount() {
        messaging.requestPermission()
            .then(async function () {
                const token = await messaging.getToken();
            })
            .catch(function (err) {
                console.log("Unable to get permission to notify.", err);
            });
        navigator.serviceWorker.addEventListener("message", (payload) => this.onMessageFirebase(payload));
    }

    render() {
        return (
            null
        )
    }
}

const mapStateToProps = state => ({});

const connected = connect(mapStateToProps, {
    configurationsGetRequesting
})(FirebaseMessage);

export default connected;

// const onMessageFirebase = () => {
//     console.log(props.client);
//     try {
//         const messaging = firebase.messaging();
//         messaging.onMessage((payload) => {
//             console.log(payload.notification.data.type.split('_')[0]);
//             let type = payload.notification.data.type.split('_')[0];
//             switch (type) {
//                 case 'AcceptService':
//                     // userServicesGetRequesting(client.token);
//                     break;
//             }
//             toastr.success(payload.notification.title, payload.notification.body);
//         });
//     } catch (error) {
//         console.error(error);
//     }
// };
//
// const mapStateToProps = state => ({
//     client: state.client,
// });
//
// const connected = connect(mapStateToProps, {
//     userServicesGetRequesting,
// })(onMessageFirebase);
//
// export default connected;
