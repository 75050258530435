import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export const customInputSelect = ({input, label, data, id, name, type, meta: {touched, error}, ...rest}, classes) => {
    var value = input.name === "tipo_identificacion" && input.value === "" ? data.find((type) => type.nombre === "Cédula de Ciudadanía").id : input.value.hasOwnProperty("id") ? input.value.id : input.value;
    if (input.name === "nombre_oneroso" && input.value === "") {
        value = data.find((type) => type.nombre === "BANCO AV VILLAS").id;
    }

    return (
        <FormControl fullWidth>
            <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
            >
                {label}
            </InputLabel>
            <Select
                {...input} {...rest}
                MenuProps={{
                    className: classes.selectMenu
                }}
                classes={{
                    select: classes.select
                }}
                value={value || 'seleccione'}
                onChange={input.onChange}
                inputProps={{
                    name: {name},
                    id: `simple-select`
                }}
            >
                <MenuItem
                          classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                          value={'seleccione'}>
                    Elija una opción
                </MenuItem>
                {data.map((item, index) => {
                    return (
                        <MenuItem key={index}
                                  classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected}}
                                  value={item.id}>
                            {item.nombre}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
