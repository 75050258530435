import React from "react";
import connect from "react-redux/es/connect/connect";
import { checkAuth } from "../../redux/auth/client/actions";
import {saveDevice} from "../../redux/auth/client/actions";
import {askForPermissioToReceiveNotifications} from "../../pushNotification/push";

class RoutesComponent extends React.Component {

	componentWillMount() {
		const { checkAuth,saveDevice } = this.props;
		checkAuth();
		let localToken = localStorage.getItem("@danko:agent");
		if (localToken){
			askForPermissioToReceiveNotifications().then(tokenFirebase =>
			{
				saveDevice(localToken,tokenFirebase);
			});
		}
	}

	render() {
		return null;
	}
}

const mapStateToProps = state => ({
	client: state.client
});

const connected = connect(mapStateToProps, {
	checkAuth,
	saveDevice
})(RoutesComponent);

export default connected;
