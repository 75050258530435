import React from 'react';
// MaterialUI
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// Components Core
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button.jsx';
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Form, reduxForm, Field, Label, reset} from "redux-form";
import {customInputText} from "../inputs/typeText";
import {customInputSelect} from "../inputs/typeSelect";
import {customInputRadio} from "../inputs/radio";
import {customInputCheckBox} from "../inputs/checkbox";
import {Redirect} from "react-router-dom";
// Acciones
import {
    supplementaryDataCreateRequesting,
    supplementaryDataUpdateRequesting,
    supplementaryDataCleanValues
} from './../../redux/supplementaryData/actions';


class FormSupplementaryData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "text",
            finish: false
        };
        this.handleCheckClientData = this.handleCheckClientData.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const {supplementaryData: supplementaryData} = prevProps;
        const {stateForm} = this.props;
        if (!supplementaryData.hasOwnProperty("id") && this.props.supplementaryData.supplementaryData.hasOwnProperty("id") && !stateForm.supplementaryData.hasOwnProperty("values"))
            this.handleCheckClientData();
    }

    componentWillUnmount() {
        this.props.supplementaryDataCleanValues();
    }

    handleCheckClientData() {
        const {
            stateForm,
            clients: {client},
            supplementaryData: {supplementaryData}
        } = this.props;
        if (supplementaryData.hasOwnProperty("id")) {
            this.props.stateForm.supplementaryData = {
                values: supplementaryData
            };
        }
    }

    handleFinish() {
        this.setState({finish: true});
    }

    handleSubmit = (values) => {
        const {
            client: {token},
            clients: {client},
            supplementaryDataCreateRequesting,
            supplementaryDataUpdateRequesting,
            supplementaryData: {supplementaryData}
        } = this.props;

        if (supplementaryData.hasOwnProperty("id"))
            supplementaryDataUpdateRequesting(token, values, client.id, supplementaryData.id);
        else
            supplementaryDataCreateRequesting(token, values, client.id);
    };

    render() {
        const {
            classes,
            handleSubmit,
            supplementaryData: {supplementaryData}
        } = this.props;
        const {finish} = this.state;

        if (finish) {
            return window.location.href = '/admin/misSolicitudes';
        }

        return (
            <>
                <Card profile>
                    <CardBody>
                        <h1 className={classes.TitleH1Left}>Datos complementarios</h1>
                        <Form ref={this.formRef} onSubmit={handleSubmit(this.handleSubmit)}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={8}>
                                    <label>
                                        Declaro que mis recursos no provienen de ninguna actividad ilícita de la
                                        contempladas en el código penal colombiano o en cualquier otra norma
                                        concordante, o que la adicione y que proviene de:
                                    </label>
                                    <Field
                                        id={"recursosProvienenDe"}
                                        name={"recursosProvienenDe"}
                                        type={"text"}
                                        label={"Indicar origen de recursos *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <Field
                                        id={"ciudad"}
                                        name={"ciudad"}
                                        type={"text"}
                                        label={"Ciudad donde se diligencia la poliza *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Field
                                        id={"personaDeConocimientoPublico"}
                                        name={"personaDeConocimientoPublico"}
                                        type={"text"}
                                        label={"Se considera usted una persona de conocimiento publico (PEPS) *"}
                                        autoComplete={"on"}
                                        component={customInputCheckBox} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                    <Field
                                        id={"cargoConElEstado"}
                                        name={"cargoConElEstado"}
                                        type={"text"}
                                        label={"Ejerce o ha ejercido un cargo de primer o segundo nuvel en una entidad del estado? *"}
                                        autoComplete={"on"}
                                        component={customInputCheckBox} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Field
                                        id={"contrataConElEstado"}
                                        name={"contrataConElEstado"}
                                        type={"text"}
                                        label={"Contrata con el estado *"}
                                        autoComplete={"on"}
                                        component={customInputCheckBox} classes={classes}
                                    />
                                </GridItem>
                            </GridContainer>
                            {!supplementaryData.hasOwnProperty("id") &&
                            <Button
                                type={"submit"}
                                color="primary"
                                className={classes.updateProfileButton}
                            >
                                Siguiente
                            </Button>
                            }


                            {supplementaryData.hasOwnProperty("id") &&
                            <>
                                <Button
                                    type={"submit"}
                                    color="success"
                                    className={classes.updateProfileButton}
                                >
                                    Actualizar
                                </Button>
                                <Button
                                    type={"button"}
                                    color="primary"
                                    className={classes.updateProfileButton}
                                    onClick={this.handleFinish}
                                >
                                    Finalizar
                                </Button>
                            </>
                            }
                        </Form>
                    </CardBody>
                </Card>
            </>
        );
    }
}

FormSupplementaryData.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
    client: state.client,
    clients: state.clients,
    supplementaryData: state.supplementaryData,
    stateForm: state.form
});

const connected = connect(mapStateToProps, {
    supplementaryDataCreateRequesting,
    supplementaryDataUpdateRequesting,
    supplementaryDataCleanValues
})(withStyles(userProfileStyles)(FormSupplementaryData));

const formed = reduxForm({
    form: "supplementaryData",
    reset
})(connected);

export default formed;

