import React from 'react';
// MaterialUI
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// Styles
// Components Core
import Card from '../../Card/Card.jsx';
import CardBody from '../../Card/CardBody';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
import {Form, reduxForm, Field, reset} from "redux-form";
// Formularios
import FormNaturalPerson from './naturalPerson';
import FormLegalPerson from './legalPerson';
// Componentes
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class FormPerson extends React.Component{

	constructor(props){
		super(props);
    const { legalPerson } = this.props;
    this.state = {
        personType: legalPerson.person.hasOwnProperty("tipo_persona") ? 2 : 1
    };
	}

	handleChange = (e) => {
		this.setState({personType: e.target.value});
	}

	render(){
		const {
			classes,
			handleSubmit,
      clients: { client },
      legalPerson,
      naturalPerson
		} = this.props;
    const hasPerson = legalPerson.person.hasOwnProperty("id") || naturalPerson.person.hasOwnProperty("id");

		const { personType } = this.state;
		return (
			<>
			  <Card profile>
			     <CardBody>
			        <h1 className={classes.TitleH1Left}>Información de la persona</h1>
			        	{!client.hasOwnProperty("persona") && !hasPerson &&
                <GridContainer>
			        	    <GridItem xs={12} sm={6} md={6}>
                                <FormControlLabel
                                   control={
                                   	  <Radio 
                                        checked={personType == 2}
                                        onChange={this.handleChange}
                                        value={2}
                                        color={"primary"}
                                        name={"person_type"}/>
                                    }
                                    label={"Persona juridica"}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <FormControlLabel
                                   control={
                                   	  <Radio 
                                        checked={personType == 1}
                                        onChange={this.handleChange}
                                        value={1}
                                        color={"primary"}
                                        name={"person_type"}/>    
                                   }
                                   label={"Persona natural"}
                                />
                            </GridItem>
			            </GridContainer>}
                    {personType == 1 &&
                       <FormNaturalPerson {...this.props} personType={personType}/>
                    }

                    {personType == 2 &&
                       <FormLegalPerson {...this.props} personType={personType}/>
                    }
			     </CardBody>
			  </Card>
			</>
		);
	}
}

FormPerson.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = (state) => ({
   legalPerson: state.legalPerson,
   naturalPerson: state.naturalPerson,
   clients: state.clients
});

export default connect(mapStateToProps, {

})(withStyles(userProfileStyles)(FormPerson));

