import {
    CREDIT_AGENT_ERROR,
    CREDIT_AGENT_REQUESTING,
    CREDIT_AGENT_SUCCESS,
    CREDIT_CHANGE_STATE_ERROR,
    CREDIT_CHANGE_STATE_REQUESTING,
    CREDIT_CHANGE_STATE_SUCCESS,
    CREDIT_CLIENT_ERROR,
    CREDIT_CLIENT_REQUESTING,
    CREDIT_CLIENT_SUCCESS,
    CREDIT_CONTROL_MODAL, CREDIT_CREATE_ERROR,
    CREDIT_CREATE_REQUESTING, CREDIT_CREATE_SUCCESS,
    CREDIT_DOCUMENTS_ERROR,
    CREDIT_DOCUMENTS_REQUESTING,
    CREDIT_DOCUMENTS_SUCCESS,
    CREDIT_CLIENT_REGISTER,
    CREDIT_FOCUS, CREDIT_GET_ERROR, CREDIT_GET_REQUESTING, CREDIT_GET_SUCCESS, CREDIT_PAYMENT_PLAN_UPDATE,
    CREDIT_PAYMENT_PLANS_ERROR,
    CREDIT_PAYMENT_PLANS_REQUESTING,
    CREDIT_PAYMENT_PLANS_SUCCESS,
    CREDIT_POLICY_ERROR,
    CREDIT_POLICY_REQUESTING,
    CREDIT_POLICY_SUCCESS,
    CREDIT_RESET_STATES, CREDIT_UPDATE_ERROR, CREDIT_UPDATE_REQUESTING, CREDIT_UPDATE_SUCCESS,
    CREDITS_CLIENT_GET_ERROR,
    CREDITS_CLIENT_GET_REQUESTING,
    CREDITS_CLIENT_GET_SUCCESS,
    CREDITS_GET_ERROR,
    CREDITS_GET_REQUESTING,
    CREDITS_GET_SUCCESS,
    CREDITS_SEARCH_ERROR,
    CREDITS_SEARCH_REQUESTING,
    CREDITS_SEARCH_SUCCESS, CREDITS_TABLE_FILTER,
    CREDITS_USER_GET_ERROR,
    CREDITS_USER_GET_REQUESTING,
    CREDITS_USER_GET_SUCCESS, PLAN_PAYMENTS_CREDIT, PLAN_PAYMENTS_CREDIT_RESET,
    CREDIT_CLEAN_VALUES,
    CREDIT_PERSON_REQUESTING,
    CREDIT_PERSON_SUCCESS,
    CREDIT_PERSON_ERROR,
    CREDIT_FAMILY_REQUESTING,
    CREDIT_FAMILY_SUCCESS,
    CREDIT_FAMILY_ERROR,
    CREDIT_SUPPLEMENTARY_DATA_REQUESTING,
    CREDIT_SUPPLEMENTARY_DATA_SUCCESS,
    CREDIT_SUPPLEMENTARY_DATA_ERROR
} from "./constants";

export const creditsUserGetRequesting = (token, userId) => ({
    type: CREDITS_USER_GET_REQUESTING,
    token, userId
});

export const creditsUserGetSuccess = (credits) => ({
    type: CREDITS_USER_GET_SUCCESS,
    credits
});

export const creditsUserGetError = (error) => ({
    type: CREDITS_USER_GET_ERROR,
    error
});

export const creditsClientGetRequesting = (token, clientId) => ({
    type: CREDITS_CLIENT_GET_REQUESTING,
    token, clientId
});

export const creditsClientGetSuccess = (credits) => ({
    type: CREDITS_CLIENT_GET_SUCCESS,
    credits
});

export const creditsClientGetError = (error) => ({
    type: CREDITS_CLIENT_GET_ERROR,
    error
});

export const creditsGetRequesting = (token, filter, paginate) => ({
    type: CREDITS_GET_REQUESTING,
    token, filter, paginate
});

export const creditsGetSuccess = (credits) => ({
    type: CREDITS_GET_SUCCESS,
    credits
});

export const creditsGetError = (error) => ({
    type: CREDITS_GET_ERROR,
    error
});

export const creditsSearchRequesting = (token, values) => ({
    type: CREDITS_SEARCH_REQUESTING,
    token, values
});

export const creditsSearchSuccess = (credits) => ({
    type: CREDITS_SEARCH_SUCCESS,
    credits
});

export const creditsSearchError = (error) => ({
    type: CREDITS_SEARCH_ERROR,
    error
});

export const creditChangeStateRequesting = (token, creditId, name) => ({
    type: CREDIT_CHANGE_STATE_REQUESTING,
    token, creditId, name
});

export const creditChangeStateSuccess = (credit) => ({
    type: CREDIT_CHANGE_STATE_SUCCESS,
    credit
});

export const creditChangeStateError = (error) => ({
    type: CREDIT_CHANGE_STATE_ERROR,
    error
});

export const creditClientRequesting = (token, creditId) => ({
    type: CREDIT_CLIENT_REQUESTING,
    token, creditId
});

export const creditClientSuccess = (client) => ({
    type: CREDIT_CLIENT_SUCCESS,
    client
});

export const creditClientError = (error) => ({
    type: CREDIT_CLIENT_ERROR,
    error
});

export const creditPolicyRequesting = (token, creditId) => ({
    type: CREDIT_POLICY_REQUESTING,
    token, creditId
});

export const creditPolicySuccess = (policy) => ({
    type: CREDIT_POLICY_SUCCESS,
    policy
});

export const creditPolicyError = (error) => ({
    type: CREDIT_POLICY_ERROR,
    error
});

export const creditPaymentPlansRequesting = (token, creditId) => ({
    type: CREDIT_PAYMENT_PLANS_REQUESTING,
    token, creditId
});

export const creditPaymentPlansSuccess = (paymentPlans) => ({
    type: CREDIT_PAYMENT_PLANS_SUCCESS,
    paymentPlans
});

export const creditPaymentPlansError = (error) => ({
    type: CREDIT_PAYMENT_PLANS_ERROR,
    error
});

export const creditAgentRequesting = (token, creditId) => ({
    type: CREDIT_AGENT_REQUESTING,
    token, creditId
});

export const creditAgentSuccess = (agent) => ({
    type: CREDIT_AGENT_SUCCESS,
    agent
});

export const creditAgentError = (error) => ({
    type: CREDIT_AGENT_ERROR,
    error
});

export const creditDocumentsRequesting = (token, creditId) => ({
    type: CREDIT_DOCUMENTS_REQUESTING,
    token, creditId
});

export const creditDocumentsSuccess = (documents) => ({
    type: CREDIT_DOCUMENTS_SUCCESS,
    documents
});

export const creditDocumentsError = (error) => ({
    type: CREDIT_DOCUMENTS_ERROR,
    error
});

export const creditCreateRequesting = (token, values, policy, initialFeed, totalCredit, client, planPayments , oldCustomer) => ({
    type: CREDIT_CREATE_REQUESTING,
    token, values, policy, initialFeed, totalCredit, client, planPayments , oldCustomer
});

export const creditCreateSuccess = (credit) => ({
    type: CREDIT_CREATE_SUCCESS,
    credit
});

export const creditCreateError = (error) => ({
    type: CREDIT_CREATE_ERROR,
    error
});

export const creditUpdateRequesting = (token, creditId, values) => ({
    type: CREDIT_UPDATE_REQUESTING,
    token, creditId, values,
});

export const creditUpdateSuccess = (credit) => ({
    type: CREDIT_UPDATE_SUCCESS,
    credit,
});

export const creditUpdateError = (error) => ({
    type: CREDIT_UPDATE_ERROR,
    error,
});

export const creditPaymentPlanUpdate = (paymentPlan) => ({
    type: CREDIT_PAYMENT_PLAN_UPDATE,
    paymentPlan,
});

export const planPaymentsCredit = (fees, totalCredit, pastDueRate, adminFee, policy) => ({
    type: PLAN_PAYMENTS_CREDIT,
    fees, totalCredit, pastDueRate, adminFee, policy
});

export const planPaymentsCreditReset = () => ({
    type: PLAN_PAYMENTS_CREDIT_RESET
});

export const creditsTableFilter = (values) => ({
    type: CREDITS_TABLE_FILTER,
    values,
});

export const creditControlModal = (modal) => ({
    type: CREDIT_CONTROL_MODAL,
    modal
});

export const creditFocus = (credit) => ({
    type: CREDIT_FOCUS,
    credit
});

export const creditResetStates = () => ({
    type: CREDIT_RESET_STATES
});

export const creditGetRequesting = (token,id) => ({
    type: CREDIT_GET_REQUESTING,
    token,
    id
});

export const creditGetSuccess = (credit) => ({
    type: CREDIT_GET_SUCCESS,
    credit
});

export const creditGetError = () => ({
    type: CREDIT_GET_ERROR
});

export const creditClientRegister = (credit) => ({
    type: CREDIT_CLIENT_REGISTER,
    credit
});

export const creditCleanValues = () => ({
    type: CREDIT_CLEAN_VALUES
});

export const creditPersonRequesting = (token, creditId) => ({
    type: CREDIT_PERSON_REQUESTING,
    token,
    creditId
});


export const creditPersonSuccess = () => ({
    type: CREDIT_PERSON_SUCCESS
});

export const creditPersonError = (error) => ({
    type: CREDIT_PERSON_ERROR,
    error 
});

export const creditFamilyRequesting = (token, creditId) => ({
    type: CREDIT_FAMILY_REQUESTING,
    token,
    creditId
});

export const creditFamilySuccess = (family,creditId) => ({
    type: CREDIT_FAMILY_SUCCESS,
    family,
    creditId
}); 

export const creditFamilyError = (error) => ({
    type: CREDIT_FAMILY_ERROR,
    error 
});

export const creditSupplementaryDataRequesting = (token , creditId) => ({
    type: CREDIT_SUPPLEMENTARY_DATA_REQUESTING,
    token,
    creditId 
});

export const creditSupplementaryDataSuccess = () => ({
    type: CREDIT_SUPPLEMENTARY_DATA_SUCCESS
}); 


export const creditSupplementaryDataError = (error) => ({
    type: CREDIT_SUPPLEMENTARY_DATA_ERROR,
    error
});
