import {call, put, all, takeEvery} from 'redux-saga/effects';
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import React from "react";
import {Redirect} from "react-router-dom";
import {reset} from "redux-form";
import {
	naturalPersonCreateSuccess,
	naturalPersonCreateError,
	naturalPersonResetStates,
	naturalPersonUpdateSuccess,
	naturalPersonUpdateError,
	naturalPersonFillDataSuccess,
	naturalPersonFillDataError
} from './actions';

import {
	NATURAL_PERSON_CREATE_REQUESTING,
	NATURAL_PERSON_UPDATE_REQUESTING,
	NATURAL_PERSON_FILL_DATA
} from './constants';

const baseUrl = `${process.env.REACT_APP_API_URL}/person/natural`;

const showError = (error) => {
    let toastOptions = {
        component: (
            <ErrorComponent message={error}/>
        )
    };
    toastr.error("Error", toastOptions);
};

const naturalPersonCreateApi = (token , values , client) => {
	let body = {
		'country': values.nacionalidad,
		'sex': values.genero,
		'marital_status': values.estadoCivil,
		'landline': values.telefono,
		'expedition_date': values.fechaDeExpedicion,
		'type_of_housing': values.tipoDeVivienda,
		'neighborhood': values.barrio,
		'job': values.ocupacion,
		'name': values.nombreDeLaEmpresa,
		'activity': values.actividadDeLaEmpresa,
		'charge': values.cargoActual,
		'education_level': values.nivelDeEstudios,
		'address_office': values.direccionOficina,
		'landline_office': values.telefonoOficina,
		'total_monthly_income': parseInt(values.totalIngresosMensuales.split('.').join('')),
		'total_monthly_expenses': parseInt(values.totalEgresosMensuales.split('.').join('')),
		'total_assets': parseInt(values.totalActivos.split('.').join('')),
		'total_liabilities': parseInt(values.totalPasivos.split('.').join('')),
		'person_type_id': values.personType
	};

	return fetch(`${baseUrl}/${client}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
	.then(response => response.json())
	.then(json => {
		if (json.code === 422)
			throw json.data;
		if (json.code === 400)
			throw [json.data];
		if (json.code === 200)
			return json.data.data;
		if (!json.code)
			return json.data;
		throw json.data;
	}).catch((error) => {
		throw error;
	});

};


const naturalPersonUpdateApi = (token , values , client , id) => {
	let body = {
		'country': values.nacionalidad,
		'sex': values.genero,
		'marital_status': values.estadoCivil,
		'landline': values.telefono,
		'expedition_date': values.fechaDeExpedicion,
		'type_of_housing': values.tipoDeVivienda,
		'neighborhood': values.barrio,
		'job': values.ocupacion,
		'name': values.nombreDeLaEmpresa,
		'activity': values.actividadDeLaEmpresa,
		'charge': values.cargoActual,
		'education_level': values.nivelDeEstudios,
		'address_office': values.direccionOficina,
		'landline_office': values.telefonoOficina,
		'total_monthly_income': parseInt(values.totalIngresosMensuales.split('.').join('')),
		'total_monthly_expenses': parseInt(values.totalEgresosMensuales.split('.').join('')),
		'total_assets': parseInt(values.totalActivos.split('.').join('')),
		'total_liabilities': parseInt(values.totalPasivos.split('.').join('')),
		'person_type_id': values.personType
	};

	return fetch(`${baseUrl}/${id}/${client}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
	.then(response => response.json())
	.then(json => {
		if (json.code === 422)
			throw json.data;
		if (json.code === 400)
			throw [json.data];
		if (json.code === 200)
			return json.data.data;
		if (!json.code)
			return json.data;
		throw json.data;
	}).catch((error) => {
		throw error;
	});

};

function* naturalPersonCreateFlow(action){
	try{
		const {token, values, client} = action;
		const person = yield call(naturalPersonCreateApi, token , values , client);
		yield put(naturalPersonResetStates());
		yield put(naturalPersonCreateSuccess(person));
		toastr.success("Registrado", "Información personal registrada exitosamente.");
	}catch (error) {
		yield put(naturalPersonCreateError(error));
		showError(error);
	}
}

function* naturalPersonUpdateFlow(action){
	try{
		const {token, values, client, id} = action;
		const person = yield call(naturalPersonUpdateApi, token, values, client, id);
		yield put(naturalPersonResetStates());
		yield put(naturalPersonUpdateSuccess(person));
		toastr.success("Actualizado", "Información personal actualizada exitosamente.");
	}catch (error) {
		yield put(naturalPersonUpdateError(error));
		showError(error);
	}
}

function* naturalPersonFillDataFlow(action){
	try{
		const { person, company } = action;
		const naturalPerson = {
			id: person.id,
			nacionalidad: person.pais,
			genero: person.genero,
			estadoCivil: person.estado_civil,
			telefono: person.telefono,
			fechaDeExpedicion: person.fecha_de_expedicion,
			ocupacion: company.trabajo,
			tipoDeVivienda: person.tipo_de_vivienda,
			barrio: person.barrio,
			nombreDeLaEmpresa: company.nombre,
			actividadDeLaEmpresa: company.actividad,
			cargoActual: company.cargo,
			nivelDeEstudios: company.nivel_de_estudios,
			direccionOficina: company.direccion,
			telefonoOficina: company.telefono,
			totalIngresosMensuales: company.total_ingresos_mensuales,
			totalEgresosMensuales: company.total_egresos_mensuales,
			totalActivos: company.total_activos,
			totalPasivos: company.total_pasivos
		};
		yield put(naturalPersonFillDataSuccess(naturalPerson));
	}catch (error){
		yield put(naturalPersonFillDataError(error));
	}
}

function* naturalPersonWatcher(){
	yield all([
		takeEvery(NATURAL_PERSON_CREATE_REQUESTING, naturalPersonCreateFlow),
		takeEvery(NATURAL_PERSON_UPDATE_REQUESTING, naturalPersonUpdateFlow),
		takeEvery(NATURAL_PERSON_FILL_DATA, naturalPersonFillDataFlow)
	]);
}

export default naturalPersonWatcher;