import {
	LEGAL_PERSON_CREATE_REQUESTING,
	LEGAL_PERSON_CREATE_SUCCESS,
	LEGAL_PERSON_CREATE_ERROR,
	LEGAL_PERSON_UPDATE_REQUESTING,
	LEGAL_PERSON_UPDATE_SUCCESS,
	LEGAL_PERSON_UPDATE_ERROR,
	LEGAL_PERSON_RESET_STATES,
	LEGAL_PERSON_FILL_DATA,
	LEGAL_PERSON_FILL_DATA_ERROR,
	LEGAL_PERSON_FILL_DATA_SUCCESS,
	LEGAL_PERSON_CLEAN_VALUES
} from './constants';

export const legalPersonCreateRequesting = (token, values, client) => ({
	type: LEGAL_PERSON_CREATE_REQUESTING,
	token,
	values,
	client
});

export const legalPersonCreateSuccess = (person) => ({
	type: LEGAL_PERSON_CREATE_SUCCESS,
	person
});

export const legalPersonCreateError = (error) => ({
	type: LEGAL_PERSON_CREATE_ERROR,
	error
});

export const legalPersonUpdateRequesting = (token, values, client, id) => ({
	type: LEGAL_PERSON_UPDATE_REQUESTING,
	token,
	values,
	client,
	id
});

export const legalPersonUpdateSuccess = (person) => ({
	type: LEGAL_PERSON_UPDATE_SUCCESS,
	person
});

export const legalPersonUpdateError = (error) => ({
	type: LEGAL_PERSON_UPDATE_ERROR,
	error
});

export const legalPersonResetStates = () => ({
	type: LEGAL_PERSON_RESET_STATES
});

export const legalPersonFillData = (person) => ({
	type: LEGAL_PERSON_FILL_DATA,
	person
});

export const legalPersonFillDataSuccess = (person) => ({
	type: LEGAL_PERSON_FILL_DATA_SUCCESS,
	person
});

export const legalPersonFillDataError = (error) => ({
	type: LEGAL_PERSON_FILL_DATA_ERROR,
	error
});

export const legalPersonCleanValues = () => ({
	type: LEGAL_PERSON_CLEAN_VALUES
});