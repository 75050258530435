import React from 'react';
import './../../../assets/css/modalClientData.css';
class ModalClientData extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: '',
            id: '',
            type: 2
        };
        this.handleChangeValues = this.handleChangeValues.bind(this);
    }

    handleChangeValues(key , value){
        this.setState({ [key] : value } , () => {
            this.props.handleUpdateModalClient(this.state);
        });
    }

    render(){
        const {
            handleSubmitModal,
            handleCloseModal,
            handleChangeOldCustomer,
            errors,
            modalRef,
            oldCustomer
        } = this.props;

        const { name , id , type } = this.state;

        return (<div className="modal-container" ref={modalRef}>
            <div className="modal-content">
                <div className="modal-heading">
                    <h3> Datos del cliente </h3>
                </div>
                <div className="modal-body">
                    <p> ingresa los datos del cliente </p>
                    <div>
                        <div>
                            <span className="error">{errors.name}</span>
                            <span className="error">{errors.id}</span>
                        </div>
                        <input type={"text"} className={errors.name ? 'error-input' : ''} placeholder={"Nombre del cliente"} value={name} onChange={(e) => this.handleChangeValues('name', e.target.value)}/>
                        <input type={"number"} className={errors.id ? 'error-input' : ''} placeholder={"Cedula del cliente"} value={id} onChange={(e) => this.handleChangeValues('id' , e.target.value)}/>
                        <div>
                            <select className={errors.type ? 'error-input' : ''} value={type} onChange={(e) => this.handleChangeValues('type' , e.target.value)}>
                                <option value={2} selected>Cédula de Ciudadanía</option>
                                <option value={1}>Cédula de Extranjería</option>
                                <option value={3}> NIT </option>
                            </select>
                            <input type={'checkbox'} checked={oldCustomer} onChange={handleChangeOldCustomer()}/> Cliente antiguo renovación
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn-acept" onClick={handleSubmitModal()}>Aceptar</button>
                    <button className="btn-cancel" onClick={handleCloseModal()}>Cancelar</button>
                </div>
            </div>
        </div>);
    }
}

export default ModalClientData;