import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import {
	policyCreateError,
	policyCreateSuccess,
	policyUpdateError,
	policyUpdateSuccess,
	policyDeleteSuccess,
	policyDeleteError,
	policyUpdatePriceError,
	policyUpdatePriceSuccess
} from "./actions";
import { POLICY_CREATE_REQUESTING, POLICY_UPDATE_REQUESTING, POLICY_DELETE_REQUESTING,POLICY_UPDATE_PRICE_REQUESTING} from "./constants";

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const baseUrl = `${process.env.REACT_APP_API_URL}/policies`;


const policyCreateApi = (token, values) => {
	// let body = {
	//   number: values.numero || "",
	//   valuePolicy: values.valor || "",
	//   beneficiaryName: values.nombre_beneficiario || "",
	//   expensiveBeneficiary: values.beneficiario_honeroso || false,
	//   startDate: values.hasOwnProperty("fecha_inicio") ? moment(values.fecha_inicio).format("YYYY-MM-DD") : "",
	//   endDate: values.hasOwnProperty("fecha_final") ? moment(values.fecha_final).format("YYYY-MM-DD") : moment(values.fecha_inicio).add(1, "years").format("YYYY-MM-DD"),
	//   insurance: values.aseguradora || "",
	//   sureType: values.tipo_seguro || ""
	// };
	let body = {
		valuePolicy: values.valor || "",
		expensiveBeneficiary: values.beneficiario_honeroso || false,
		startDate: values.hasOwnProperty("fecha_inicio") ? moment(values.fecha_inicio).format("YYYY-MM-DD") : "",
		endDate: values.hasOwnProperty("fecha_final") ? moment(values.fecha_final).format("YYYY-MM-DD") : moment(values.fecha_inicio).add(1, "years").format("YYYY-MM-DD")
	};
	// Object.keys(values).map(key => {
	//   if (key.indexOf("feature_") >= 0) {
	//     Object.assign(body, {
	//       [key.split("feature_")[1].replace(/\s/g)]: values[key]
	//     });
	//   }
	// });
	return fetch(`${baseUrl}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* policyCreateFlow(action) {
	try {
		const { token, values } = action;
		const policy = yield call(policyCreateApi, token, values);
		yield put(policyCreateSuccess(policy));
		//toastr.success(`Poliza creada`, `La poliza ${policy.numero} ha sido creada con exito.`);
		toastr.success(`Poliza creada`, `La poliza ha sido creada con exito.`);
	} catch (error) {
		yield put(policyCreateError(error));
		showError(error);
	}
}

const policyUpdateApi = (token, policyId, values) => {
	let body = {
		number: values.hasOwnProperty("numero") ? values.numero : "",
		valuePolicy: values.hasOwnProperty("valuePolicy") ? values.valor : "",
		beneficiaryName: values.hasOwnProperty("nombre_beneficiario") ? values.nombre_beneficiario : "",
		expensiveBeneficiary: !!values.beneficiario_honeroso,
		startDate: values.hasOwnProperty("fecha_inicio") ? moment(values.fecha_inicio).format("YYYY-MM-DD") : "",
		endDate: values.hasOwnProperty("fecha_final") ? moment(values.fecha_final).format("YYYY-MM-DD") : moment(values.fecha_inicio).add(1, "years").format("YYYY-MM-DD"),
		insurance: values.hasOwnProperty("aseguradora") && values.aseguradora.hasOwnProperty('id') ? values.aseguradora.id : values.aseguradora ,
		sureType: values.hasOwnProperty("tipo_seguro") && values.tipo_seguro.hasOwnProperty('id') ? values.tipo_seguro.id : values.tipo_seguro
	};
	Object.keys(values).map(key => {
		if (key.indexOf("feature_") >= 0) {
			Object.assign(body, {
				[key.split("feature_")[1].replace(/\s/g)]: values[key] || ""
			});
		}
	});
	return fetch(`${baseUrl}/${policyId}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* policyUpdateFlow(action) {
	try {
		const { token, policyId, values } = action;
		const policy = yield call(policyUpdateApi, token, policyId, values);
		yield put(policyUpdateSuccess(policy));
		toastr.success(`Poliza actualizada`, `La poliza  ha sido actualizada con exito.`);
	} catch (error) {
		yield put(policyUpdateError(error));
		showError(error);
	}
}

const policyUpdatePriceApi = (token, policyId, values) => {

	let body = {
		valuePolicy: values.valor || "",
		expensiveBeneficiary: values.beneficiario_honeroso || false,
		startDate: values.hasOwnProperty("fecha_inicio") ? moment(values.fecha_inicio).format("YYYY-MM-DD") : "",
		endDate: values.hasOwnProperty("fecha_final") ? moment(values.fecha_final).format("YYYY-MM-DD") : moment(values.fecha_inicio).add(1, "years").format("YYYY-MM-DD")
	};

	return fetch(`${baseUrl}/price/${policyId}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* policyUpdatePriceFlow(action) {
	try {
		const { token, policyId, values } = action;
		const policy = yield call(policyUpdatePriceApi, token, policyId, values);
		yield put(policyUpdatePriceSuccess(policy));
		toastr.success(`Poliza actualizada`, `La poliza  ha sido actualizada con exito.`);
	} catch (error) {
		yield put(policyUpdatePriceError(error));
		showError(error);
	}
}

const policyDeleteApi = (token, policyId) => {

	return fetch(`${baseUrl}/${policyId}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* policyDeleteFlow(action) {
	try {
		const { token, policyId} = action;
		yield call(policyDeleteApi, token, policyId);
		yield put(policyDeleteSuccess());
		// toastr.success(`Poliza actualizada`, `La poliza ${policy.numero} ha sido actualizada con exito.`);
	} catch (error) {
		yield put(policyDeleteError(error));
	}
}

function* policyWatcher() {
	yield all([
		takeEvery(POLICY_CREATE_REQUESTING, policyCreateFlow),
		takeEvery(POLICY_UPDATE_REQUESTING, policyUpdateFlow),
		takeEvery(POLICY_UPDATE_PRICE_REQUESTING, policyUpdatePriceFlow),
		takeEvery(POLICY_DELETE_REQUESTING, policyDeleteFlow)
	]);
}

export default policyWatcher;
