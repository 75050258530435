import { VERIFY_EMAIL_ERROR, VERIFY_EMAIL_REQUESTING, VERIFY_EMAIL_SUCCESS } from "./constants";

export const verifyEmailRequesting = (tokenEmail) => ({
	type: VERIFY_EMAIL_REQUESTING,
	tokenEmail,
});

export const verifyEmailSuccess = () => ({
	type: VERIFY_EMAIL_SUCCESS,
});

export const verifyEmailError = (error) => ({
	type: VERIFY_EMAIL_ERROR,
	error,
});