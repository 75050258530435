import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../../commons/errors/apiErrors";
import { ErrorComponent } from "../../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import {
  forgotResetStates, resetPasswordError,
  resetPasswordSuccess,
  sendEmailError,
  sendEmailSuccess,
  sendTokenError,
  sendTokenSuccess
} from "./actions";
import { RESET_PASSWORD_REQUESTING, SEND_EMAIL_REQUESTING, SEND_TOKEN_REQUESTING } from "./constants";

const showError = (error) => {
  let toastOptions = {
    component: (
      <ErrorComponent message={error}/>
    )
  };
  toastr.error("Error", toastOptions);
};

const baseUrl = `${process.env.REACT_APP_API_URL}/forgot`;

const sendEmailApi = (values) => {
  let body = {
    email: values.email
  };
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => {
      if (json.code === 422)
        throw json.data;
      if (json.code === 400)
        throw [json.data];
      if (json.code === 200)
        return json.data.data;
      throw json.data;
    })
    .catch((error) => {
      throw error;
    });
};

function* sendEmailFlow(action) {
  try {
    const { values } = action;
    yield call(sendEmailApi, values);
    yield put(sendEmailSuccess());
    toastr.success("Correo electronico enviado", `Se ha enviado un correo electronico a ${values.email} con las indicaciones para restablecer su contraseña.`);
  } catch (error) {
    yield put(sendEmailError(error));
    showError(error);
  }
}

const sendTokenApi = (values) => {
  let body = {
    token: values.token
  };
  return fetch(`${baseUrl}VerifyToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => {
      if (json.code === 422)
        throw json.data;
      if (json.code === 400)
        throw [json.data];
      if (json.code === 200)
        return json.data.data;
      throw json.data;
    })
    .catch((error) => {
      throw error;
    });
};

function* sendTokenFlow(action) {
  try {
    const { values } = action;
    yield call(sendTokenApi, values);
    yield put(sendTokenSuccess());
    toastr.success("Token verificado", "El token fue verificado con exito.");
  } catch (error) {
    yield put(sendTokenError(error));
    showError(error);
  }
}

const resetPasswordApi = (values) => {
  let body = {
    token: values.token,
    password: values.password,
    password_confirmation: values.re_password
  };
  return fetch(`${baseUrl}ResetPassword`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => {
      if (json.code === 422)
        throw json.data;
      if (json.code === 400)
        throw [json.data];
      if (json.code === 200)
        return json.data.data;
      throw json.data;
    })
    .catch((error) => {
      throw error;
    });
};

function* resetPasswordFlow(action) {
  try {
    const { values } = action;
    yield call(resetPasswordApi, values);
    toastr.success("Contraseña restablecida", "La contraseña fue restablecida con exito.");
    yield put(resetPasswordSuccess());
    yield put(forgotResetStates());
  } catch (error) {
    yield put(resetPasswordError(error));
    showError(error);
  }
}

function* forgotWatcher() {
  yield all([
    takeEvery(SEND_EMAIL_REQUESTING, sendEmailFlow),
    takeEvery(SEND_TOKEN_REQUESTING, sendTokenFlow),
    takeEvery(RESET_PASSWORD_REQUESTING, resetPasswordFlow)
  ]);
}

export default forgotWatcher;