import { REGISTER_ERROR, REGISTER_REQUESTING, REGISTER_SUCCESS } from "./constants";


export const registerRequesting = (values, typeIdentification) => ({
	type: REGISTER_REQUESTING,
	values, typeIdentification
});

export const registerSuccess = () => ({
	type: REGISTER_SUCCESS,
});

export const registerError = (error) => ({
	type: REGISTER_ERROR,
	error
});