import {
    ME_GET_ERROR,
    ME_GET_REQUESTING,
    ME_GET_SUCCESS,
    ME_UPDATE_ERROR,
    ME_UPDATE_REQUESTING,
    ME_UPDATE_SUCCESS
} from "./constants";

const initialState = {
    requesting: false,
    success: false,
    error: '',
    user: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ME_GET_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: '',
            };
        case ME_GET_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                user: action.user,
            };
        case ME_GET_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error,
            };
        case ME_UPDATE_REQUESTING:
            return {
                ...state,
                requesting: true,
                success: false,
                error: ""
            };
        case ME_UPDATE_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                user: action.user,
            };
        case ME_UPDATE_ERROR:
            return {
                ...state,
                requesting: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default reducer;