import React from "react";
//Integration
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { verifyEmailRequesting } from "../../redux/auth/verifyEmail/actions";

class Verify extends React.Component {
	
	constructor(props) {
		super(props);
		const {
			verifyEmailRequesting,
			match
		} = this.props;
		verifyEmailRequesting(match.params.token);
	};
	
	render() {
		const {
			verifyEmail: { requesting }
		} = this.props;
		if (requesting) {
			return (
				<Redirect to={"/"}/>
			);
		}
		return null;
	}
}

const mapStateToProps = state => ({
	verifyEmail: state.verifyEmail
});

const connected = connect(mapStateToProps, {
	verifyEmailRequesting
})(Verify);

export default connected;