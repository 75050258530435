import React from 'react';
// MaterialUI
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// Components Core
import Card from '../Card/Card.jsx';
import CardBody from '../Card/CardBody';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Button from '../CustomButtons/Button.jsx';
// Styles
import userProfileStyles from "../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
// integration
import {connect} from "react-redux";
import {toastr} from 'react-redux-toastr';
import {Form, reduxForm, Field, reset} from "redux-form";
import {customInputText} from "../inputs/typeText";
import {customInputSelect} from "../inputs/typeSelect";
import {Redirect} from "react-router-dom";
// Acciones
import { sureTypeFeaturesPolicyRequesting } from "../../redux/sureTypeFeature/actions";
import {policyUpdateRequesting} from "../../redux/policy/actions";
import {featuresSureTypeGetRequesting} from "../../redux/feature/actions";
import {creditPolicyRequesting} from '../../redux/credit/actions';

class FormPolicyData extends React.Component{

	constructor(props) {
		super(props);
	}

	componentDidMount(){
		const {
			credit: { credit },
			client: { token },
		    creditPolicyRequesting,
		    featuresSureTypeGetRequesting
		} = this.props;
		creditPolicyRequesting(token, credit.id);
	}

	handleSubmit = (values) => {
		const{
			policyUpdateRequesting,
			initialValues,
			client:{token},
			policyForm
		} = this.props;

		if (!values.nombre_beneficiario && policyForm.values.beneficiario_honeroso){
            toastr.error("Error" , "Debe ingresar el nombre del beneficiario oneroso");
            return;
        }

		policyUpdateRequesting(token,initialValues.id,values);
	};


	handleGetFeatures = (e) => {
		const {
			client: { token },
			featuresSureTypeGetRequesting,
		} = this.props;
		featuresSureTypeGetRequesting(token,e.target.value);
	};

	render(){
		const {
			classes,
			open,
			handleSubmit,
			controlModal,
			credit: { policy },
			insurance: { insurances },
			sureType: { sureTypes },
			feature: {features},
			sureTypeFeature: { sureTypeFeatures },
			stateForm
		} = this.props;
		return (
			  <Card profile>
			     <CardBody>
			        <h1 className={classes.TitleH1Left}>Actualización poliza</h1>
                        <Form ref={this.formRef} onSubmit={handleSubmit(this.handleSubmit)}>
                            <GridContainer>
                            	<GridItem xs={12} sm={6} md={6}>
                                    <Field
                                       id={"numero"}
                                        name={"numero"}
                                        type={"text"}
                                        label={"Numero de Póliza *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                   <Field
                                       id={"aseguradora"}
                                        name={"aseguradora"}
                                        type={"text"}
                                        label={"Aseguradora *"}
                                        autoComplete={"on"}
                                        component={customInputSelect} classes={classes}
                                        data={insurances}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                   <Field
                                       id={"nombre_beneficiario"}
                                        name={"nombre_beneficiario"}
                                        type={"text"}
                                        label={"Nombre del beneficiario *"}
                                        autoComplete={"on"}
                                        component={customInputText} classes={classes}
                                    />
                                </GridItem>
                                {sureTypes.length > 0 &&
                                <GridItem xs={12} sm={6} md={6}>
                                   <Field
                                       id={"tipo_seguro"}
                                        name={"tipo_seguro"}
                                        type={"number"}
                                        label={"Tipo seguro *"}
                                        autoComplete={"on"}
                                        onChange={(e) => this.handleGetFeatures(e)}
                                        component={customInputSelect} classes={classes}
                                        data={sureTypes}
                                    />
                                </GridItem>}
                                {features.map((feature, index) => (
                                 <GridItem xs={12} sm={6} md={4} key={index}>
                                   	<Field
                                         id={`feature_${feature.nombre}`}
                                         name={`feature_${feature.nombre}`}
                                         label={`${feature.nombre} *`}
                                         autoComplete={"off"}
                                         component={customInputText} classes={classes}
                                     />
                                 </GridItem>
                                 ))}
                             </GridContainer>

                             <Button
							    type={'submit'}
							    color="primary"
							    className={classes.updateProfileButton}
						    >
							    Guardar
						    </Button>
                        </Form>

			     </CardBody>
			  </Card>
		);
	}

}

FormPolicyData.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
	initialValues: state.credit.policy,
	client: state.client,
	sureTypeFeature: state.sureTypeFeature,
	credit: state.credit,
	insurance :state.insurance,
	sureType: state.sureType,
	feature: state.feature,
	stateForm: state.form,
	policyForm: state.form.policy
});

const connected = connect(mapStateToProps, {
	sureTypeFeaturesPolicyRequesting,
	policyUpdateRequesting,
	featuresSureTypeGetRequesting,
	creditPolicyRequesting
})(withStyles(userProfileStyles)(FormPolicyData));

const formed = reduxForm({
	form: "policyForm",
	reset
})(connected);

export default formed;
