// /*!// =========================================================
// * Developer DigitalParchapp V.1
// =========================================================// */
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import notificationsStyle from "./../../../assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import Slide from "@material-ui/core/Slide";
// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
// core components
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import {customInputText} from "../../../components/inputs/typeText";
import {customInputSelect} from "../../../components/inputs/typeSelect";
import {connect} from "react-redux";
import {sureTypeFeaturesPolicyRequesting} from "../../../redux/sureTypeFeature/actions";
import {FormattedNumber} from "react-intl";
import {Form, reduxForm, Field, reset} from "redux-form";
import CardBody from "../../../components/formQuote/client";
import userProfileStyles from "../../../assets/jss/material-dashboard-pro-react/views/userProfileCompanyStyles";
import {policyUpdateRequesting} from "../../../redux/policy/actions";
import {featuresSureTypeGetRequesting} from "../../../redux/feature/actions";
import GridItem from "../../../components/Grid/GridItem";
import {customInputCheckBox} from "../../../components/inputs/checkbox";
import GridContainer from "../../../components/Grid/GridContainer";

const formattedNumber = (value) => {
    return (
        <FormattedNumber value={value} style={"currency"} currency={"COP"}
                         minimumFractionDigits={0} maximumFractionDigits={0}/>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


class DatePolicy extends React.Component {

    constructor(props) {
        super(props);
        const {
            client: {token},
            credit: {policy},
            featuresSureTypeGetRequesting
        } = this.props;
        if (policy.aseguradora.nombre !== null)
            featuresSureTypeGetRequesting(token, policy.aseguradora.id);
    }

    handleSubmit = (values) => {
        const {policyUpdateRequesting, initialValues, client: {token}} = this.props;
        policyUpdateRequesting(token, initialValues.id, values);
    };

    handleGetFeatures = (e) => {
        const {
            client: {token},
            featuresSureTypeGetRequesting,
        } = this.props;
        featuresSureTypeGetRequesting(token, e.target.value);
    };

    render() {
        const {
            classes,
            open,
            handleSubmit,
            controlModal,
            credit: {policy},
            insurance: {insurances},
            sureType: {sureTypes},
            feature: {features},
            sureTypeFeature: {sureTypeFeatures},
            stateForm
        } = this.props;
        return <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => controlModal("policyModal")}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description">
            <CardHeader color="primary" icon>
                <CardIcon color="primary">
                    <MailOutline/>
                </CardIcon>
            </CardHeader>
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => controlModal("policyModal")}
                >
                    <Close className={classes.modalClose}/>
                </Button>
                <h4 className={classes.modalTitle}>Datos de la poliza</h4>
            </DialogTitle>
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}>
                    <DialogContent id="classic-modal-slide-description"
                                   className={classes.modalBody}>
                        <div className="Conteiner-table">
                            <table className="table-date">
                                <p>Datos registrados</p>
                                <tbody>
                                <tr>
                                    <th>Numero de Póliza</th>
                                    <td>{
                                        <Field id={"numero"}
                                               name={"numero"}
                                               type={"number"}
                                               autoComplete={"off"}
                                               component={customInputText}/>
                                    }</td>
                                </tr>
                                <tr>
                                    <th>Beneficiario Oneroso</th>
                                    <td>{
                                        <Field id={"beneficiario_honeroso"}
                                               name={"beneficiario_honeroso"}
                                               type={"checkbox"}
                                               label={"Beneficiario Oneroso *"}
                                               component={customInputCheckBox}
                                               classes={classes}
                                               color={"primary"}/>
                                    }</td>
                                </tr>
                                <tr>
                                    <th>Aseguradora</th>
                                    <td>
                                        {insurances.length > 0 && (
                                            <Field id={"aseguradora"}
                                                   name={"aseguradora"}
                                                   type={"select"}
                                                   autoComplete={"on"}
                                                   component={customInputSelect}
                                                   classes={classes}
                                                   data={insurances}/>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Nombre del beneficiario</th>
                                    <td>
                                        <Field id={"nombre_beneficiario"}
                                               name={"nombre_beneficiario"}
                                               autoComplete={"off"}
                                               component={customInputText}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Beneficiario Oneroso</th>
                                    <td>{policy.beneficiario_honeroso ? "Si" : "No"}</td>
                                </tr>
                                <tr>
                                    <th>Valor</th>
                                    <td>{formattedNumber(policy.valor)}</td>
                                </tr>
                                <tr>
                                    <th>Tipo de seguro</th>
                                    <td>{sureTypes.length > 0 && (
                                        <Field id={"tipo_seguro"}
                                               name={"tipo_seguro"}
                                               type={"select"}
                                               onChange={(e) => this.handleGetFeatures(e)}
                                               component={customInputSelect} classes={classes}
                                               data={sureTypes}/>
                                    )}</td>
                                </tr>
                                <tr>
                                    <th>Vigencia desde</th>
                                    <td>{policy.fecha_inicio}</td>
                                </tr>
                                <tr>
                                    <th>Vigencia Hasta</th>
                                    <td>{policy.fecha_final}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {features.length > 0
                        &&
                        (
                            <table className="table-date2">
                                <br/>
                                <p>Caracteristicas de {policy.tipo_seguro.nombre ? policy.tipo_seguro.nombre : ''}</p>
                                <tbody>
                                {features.map((feature, index) => (
                                    <tr key={index}>
                                        <th>{feature.nombre}</th>
                                        <td>
                                            <Field id={'feature_' + feature.nombre}
                                                   name={'feature_' + feature.nombre}
                                                //autoComplete={"off"}
                                                   component={customInputText}
                                            />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )
                        }
                        <DialogActions className={classes.modalFooter}>
                            <Button
                                type={'submit'}
                                color="primary"
                            >
                                Guardar
                            </Button>
                            <Button
                                onClick={() => controlModal("policyModal")}
                                color="warning"
                            >
                                Cerrar
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </DialogContent>
            </Form>
        </Dialog>;
    }
}

DatePolicy.propTypes = {
    classes: PropTypes.object
};


const formed = reduxForm({
    form: "editPolicy",
    enableReinitialize: true,
    reset
})(withStyles(userProfileStyles)(DatePolicy));

const mapStateToProps = state => ({
    initialValues: state.credit.policy,
    client: state.client,
    sureTypeFeature: state.sureTypeFeature,
    credit: state.credit,
    insurance: state.insurance,
    sureType: state.sureType,
    feature: state.feature,
    stateForm: state.form,
});

const connected = connect(mapStateToProps, {
    sureTypeFeaturesPolicyRequesting,
    policyUpdateRequesting,
    featuresSureTypeGetRequesting
})(withStyles(notificationsStyle)(formed));

export default connected;
