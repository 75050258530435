/*!
=========================================================
* Developer DigitalParchapp V.1
=========================================================
*/
import React from "react";
import PropTypes from "prop-types";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Create from "@material-ui/icons/Create";
import Find from "@material-ui/icons/FindInPage";
import Description from "@material-ui/icons/Description";


// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import {dataTable} from "variables/general.jsx";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Modals
import InfoClientModal from "./Modal/InfoClient";
import InfoPersonModal from "./Modal/InfoPerson";
import InfoFamilyModal from './Modal/InfoFamily';
import InfoSupplementaryDataModal from './Modal/InfoSupplementaryData';
import DatePolicyModal from "./Modal/datePolicy";
import DateCreditModal from "./Modal/dateCredit";
import DocumentsModal from "./Modal/documents";
import StatesModal from "./Modal/statesModal";
//
import {
    creditFocus,
    creditControlModal,
    creditClientRequesting,
    creditPolicyRequesting,
    creditPaymentPlansRequesting,
    creditDocumentsRequesting,
    creditsGetRequesting,
    creditChangeStateRequesting,
    creditPersonRequesting,
    creditFamilyRequesting,
    creditSupplementaryDataRequesting,
    creditsTableFilter,
    creditsSearchError
} from "../../redux/credit/actions";
import {clientCleanValues, clientFindIdentificationSuccess} from '../../redux/client/actions';
import {creditCleanValues} from '../../redux/credit/actions';
import {connect} from "react-redux";
import {FormattedNumber} from "react-intl";
import {Redirect} from "react-router-dom";
import {NotFound} from "../../commons/errors/notFound";
import {statesCreditGetRequesting} from "../../redux/state/actions";
import {documentTypesGetRequesting} from "../../redux/documentType/actions";
import {onerosoGetRequesting} from '../../redux/oneroso/actions';
import {getAgentToken} from '../../services/StorageService';
import DetailCreditModal from "./Modal/detailCredit";
import FormFamily from "../../components/formQuote/family";
import FormSupplementaryData from "../../components/formQuote/supplementaryData";
import FormPerson from "../../components/formQuote/Person";
import FormClient from "../../components/formQuote/client";
import FormOneroso from "../../components/formQuote/oneroso";
import {animateScroll as scroll, Element, Events} from 'react-scroll';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        fontWeight: 700
    },
    textTable: {
        fontWeight: 600
    }
};

const formattedNumber = (value) => {
    return (
        <FormattedNumber value={value} style={"currency"} currency={"COP"}
                         minimumFractionDigits={0} maximumFractionDigits={0}/>
    );
};

// Cambiar el nombre de la clase segun el que se vaya a usar
class MyRequests extends React.Component {

    handleChange = () => {
        this.setState({checkedA: !this.state.checkedA});
    };
    state = {};
    token = getAgentToken();

    handleInit = () => {
        const {
            creditsGetRequesting,
            statesCreditGetRequesting,
            documentTypesGetRequesting,
            creditsSearchError,
            onerosoGetRequesting,

        } = this.props;

        creditsSearchError("");
        creditsGetRequesting(this.token, "Todos", 1);
        statesCreditGetRequesting(this.token);
        documentTypesGetRequesting();
        onerosoGetRequesting();
    }

    constructor(props) {
        super(props);
        this.handleInit();
        this.container = React.createRef();
        this.state = {
            oneroso: false,
            detailOneroso: {},
            dataSearch: [],
            columns: [
                {
                    Header: "Fecha",
                    accessor: "Fecha",
                    width: 250,
                    className: "text-center",
                    id: "fecha_creacion",
                    sortable: false,
                    filterable: false,
                },{
                    Header: "Numero de pagaré",
                    accessor: "Numerodepagaré",
                    width: 250,
                    className: "text-center",
                    id: "numero_pagare",
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: "Numero de crédito",
                    accessor: "Numerodecrédito",
                    width: 250,
                    className: "text-center",
                    id: "id",
                    sortable: false,
                    filterable: false,
                },
                {
                    Header: "Valor Poliza",
                    accessor: "ValorPoliza",
                    sortable: false,
                    filterable: false,
                    width: 250,
                    className: "text-center"
                },
                {
                    Header: "Nombre cliente",
                    accessor: "nameClient",
                    sortable: false,
                    filterable: false,
                    width: 250,
                    className: "text-center"
                },
                {
                    Header: "Cuota fija",
                    accessor: "valueFee",
                    sortable: false,
                    filterable: false,
                    width: 250,
                    className: "text-center"
                },
                {
                    Header: "Estado",
                    accessor: "Estado",
                    sortable: false,
                    filterable: false,
                    width: 350,
                    className: "text-center"
                },
                {
                    width: 1800,
                    Header: "Acciones",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    className: "text-center"
                }
            ]
            // EditProduct: false,
            // AddProduct:false,
        };
    }

    componentWillUnmount() {
        this.props.clientCleanValues();
        this.props.creditCleanValues();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {credit: {credits, creditsSearch, error, supplementaryDataModal, familyModal, personModal, clientModal}, classes, clientFindIdentificationSuccess} = this.props;

        if ((familyModal && error) || (supplementaryDataModal && error) || (personModal && error) || (clientModal && error)) {
            this.container.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (prevProps.credit.credits !== credits) {
            this.setState({
                data: credits.map((credit, index) => {
                    return {
                        id: index,
                        Fecha: credit.fecha_creacion,
                        Numerodecrédito: credit.id,
                        Numerodepagaré: credit.numero_pagare ? credit.numero_pagare : 'No registra',
                        nameClient: `${credit.cliente.nombres} ${credit.cliente.apellidos}`,
                        valueFee: formattedNumber(credit.plan_pagos.valor_cuota),
                        ValorPoliza: formattedNumber(credit.poliza.valor),
                        Estado: credit.estado,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to add a like kind of action */}
                                <GridContainer>
                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleGetInfo(credit.id, "clientModal");
                                        this.handleControlModal("clientModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info Cliente
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={2} onClick={() => {
                                        this.handleGetInfo(credit.id, "personModal");
                                        clientFindIdentificationSuccess(credit.cliente);
                                        this.handleControlModal("personModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info Personal
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                                        this.handleGetInfo(credit.id, "familyModal");
                                        clientFindIdentificationSuccess(credit.cliente);
                                        this.handleControlModal("familyModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info Familiar
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                                        this.handleGetInfo(credit.id, "supplementaryDataModal");
                                        clientFindIdentificationSuccess(credit.cliente);
                                        this.handleControlModal("supplementaryDataModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info comp
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleGetInfo(credit.id, "policyModal");
                                        this.handleControlModal("policyModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Find/>
                                        </Button>
                                        Datos Poliza
                                    </GridItem>
                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleGetInfo(credit.id, "paymentPlansModal");
                                        this.handleControlModal("paymentPlansModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Create/>
                                        </Button>
                                        Plan pagos
                                    </GridItem>
                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleGetInfo(credit.id, "documentsModal");
                                        this.handleControlModal("documentsModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Description/>
                                        </Button>
                                        Documentos
                                    </GridItem>
                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleControlModal("detailCreditModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple

                                            color="info"
                                            className="like"
                                        >
                                            <Find/>
                                        </Button>
                                        Detal.Credito
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.setState({
                                            oneroso: !this.state.oneroso,
                                            detailOneroso: credit.detalle_oneroso
                                        }, () => {
                                            this.container.current.scrollIntoView({behavior: 'smooth'});
                                        })
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple

                                            color="info"
                                            className="like"
                                        >
                                            <Find/>
                                        </Button>
                                        Oneroso
                                    </GridItem>

                                    <GridItem xs={2} sm={2} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleControlModal("statesCreditModal");
                                    }} className={"cursor-pointer"}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Create/>
                                        </Button>
                                        Estados
                                    </GridItem>
                                </GridContainer>
                            </div>
                        )
                    };
                })
            });
        }
        if (prevProps.credit.creditsSearch !== creditsSearch) {
            this.setState({
                dataSearch: creditsSearch.map((credit, index) => {
                    return {
                        id: index,
                        Fecha: credit.fecha_creacion,
                        Numerodepagaré: credit.numero_pagare ? credit.numero_pagare : 'No registra',
                        Numerodecrédito: credit.id,
                        nameClient: `${credit.cliente.nombres} ${credit.cliente.apellidos}`,
                        valueFee: formattedNumber(credit.plan_pagos.valor_cuota),
                        ValorPoliza: formattedNumber(credit.poliza.valor),
                        Estado: credit.estado,
                        actions: (
                            // we've added some custom button actions
                            <div className="actions-right">
                                {/* use this button to add a like kind of action */}
                                <GridContainer>
                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                                        this.handleGetInfo(credit.id, "clientModal");
                                        this.handleControlModal("clientModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info Cliente
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={2} onClick={() => {
                                        /*this.handleGetInfo(credit.id, "clientModal");
                                        this.handleControlModal("clientModal");*/
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info Personal
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={1} sm={1} md={1} onClick={() => {
                                        /*this.handleGetInfo(credit.id, "clientModal");
                                        this.handleControlModal("clientModal");*/
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <AssignmentInd/>
                                        </Button>
                                        Info Familiar
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={2} onClick={() => {
                                        this.handleGetInfo(credit.id, "policyModal");
                                        this.handleControlModal("policyModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Find/>
                                        </Button>
                                        Datos Poliza
                                    </GridItem>
                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={2} onClick={() => {
                                        this.handleGetInfo(credit.id, "paymentPlansModal");
                                        this.handleControlModal("paymentPlansModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Create/>
                                        </Button>
                                        Plan pagos
                                    </GridItem>
                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={2} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleGetInfo(credit.id, "documentsModal");
                                        this.handleControlModal("documentsModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Description/>
                                        </Button>
                                        Documentos
                                    </GridItem>
                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleControlModal("detailCreditModal");
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Find/>
                                        </Button>
                                        Detal. Credito
                                    </GridItem>

                                    <GridItem className={"cursor-pointer"} xs={2} sm={2} md={1} onClick={() => {
                                        this.handleFocus(credit);
                                        this.setState({
                                            oneroso: !this.state.oneroso,
                                            detailOneroso: credit.detalle_oneroso
                                        }, () => {
                                            this.container.current.scrollIntoView({behavior: 'smooth'});
                                        })
                                    }}>
                                        <Button
                                            justIcon
                                            round
                                            simple

                                            color="info"
                                            className="like"
                                        >
                                            <Find/>
                                        </Button>
                                        Oneroso
                                    </GridItem>
                                    <GridItem xs={2} sm={2} md={2} onClick={() => {
                                        this.handleFocus(credit);
                                        this.handleControlModal("statesCreditModal");
                                    }} className={"cursor-pointer"}>
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            color="info"
                                            className="like"
                                        >
                                            <Create/>
                                        </Button>
                                        Estados
                                    </GridItem>
                                </GridContainer>
                            </div>
                        )
                    };
                })
            });
        }
    }

    handleGetCsv = () => {
        const {
            auth: {user}
        } = this.props;
        window.open(`${process.env.REACT_APP_API_URL}/credits/export?email=${user.correo}`, "_self");
    };

    handleFocus = (object) => {
        const {
            creditFocus
        } = this.props;
        creditFocus(object);
    };

    handleFilter = (value) => {
        const {
            credit: {filter},
            creditsGetRequesting
        } = this.props;
        if (value !== filter)
            creditsGetRequesting(this.token, value, 1);
    };

    handleGetData = (newPaginate) => {
        const {
            credit: {filter, paginate, hasMore, hasLess},
            creditsGetRequesting
        } = this.props;
        if (newPaginate === 1 && hasMore)
            creditsGetRequesting(this.token, filter, paginate + newPaginate);
        if (newPaginate === -1 && hasLess && paginate > 1)
            creditsGetRequesting(this.token, filter, paginate + newPaginate);
    };

    handleControlModal = (modal) => {
        const {
            creditControlModal,
        } = this.props;
        creditControlModal(modal);
    };

    handleGetInfo = (creditId, modal) => {
        const {
            creditClientRequesting,
            creditPolicyRequesting,
            creditPaymentPlansRequesting,
            creditDocumentsRequesting,
            creditPersonRequesting,
            creditFamilyRequesting,
            creditSupplementaryDataRequesting
        } = this.props;
        switch (modal) {
            case "clientModal":
                creditClientRequesting(this.token, creditId);
                break;
            case "policyModal":
                creditPolicyRequesting(this.token, creditId);
                break;
            case "paymentPlansModal":
                creditPaymentPlansRequesting(this.token, creditId);
                break;
            case "documentsModal":
                creditDocumentsRequesting(this.token, creditId);
                break;
            case "onerosoNotification":
                creditDocumentsRequesting(this.token, creditId);
                break;
            case "personModal":
                creditPersonRequesting(this.token, creditId);
                break;
            case "familyModal":
                creditFamilyRequesting(this.token, creditId);
                break;
            case "supplementaryDataModal":
                creditSupplementaryDataRequesting(this.token, creditId);
                break;
            default:
        }
    };

    handleChangeCredit = (creditId, e) => {
        const {
            creditChangeStateRequesting
        } = this.props;
        creditChangeStateRequesting(this.token, creditId, e.target.name);
    };

    handleFilterTable = (value) => {
        const {
            creditsTableFilter,
        } = this.props;
        creditsTableFilter(value);
    };

    render() {
        const {
            classes,
            state: {states},
            credit: {
                credits, filter, search, creditsSearch, hasMore, hasLess,
                clientModal, policyModal, paymentPlansModal, agentModal,
                personModal, familyModal, supplementaryDataModal,
                documentsModal, client, policy, paymentPlans, agent, documents, requesting,
                detailCreditModal, credit, statesCreditModal, family,
                error
            },
            familyMember: {familyMember},
            supplementaryData: {supplementaryData},
            clientFindIdentificationSuccess
        } = this.props;
        console.log("credit jejej: ", credit);
        const hasPerson = this.props.legalPerson.person.hasOwnProperty("id") || this.props.naturalPerson.person.hasOwnProperty("id");
        if (!this.token) {
            return (
                <Redirect to={"/"}/>
            );
        }

        return (
            <div>
                <GridContainer>
                    {clientModal && client.hasOwnProperty("id") && (
                        <InfoClientModal
                            controlModal={this.handleControlModal.bind(this)}
                            open={clientModal}
                            client={client}
                        />
                    )}

                    {personModal && hasPerson &&
                    <InfoPersonModal
                        controlModal={this.handleControlModal.bind(this)}
                        open={personModal}
                    />
                    }


                    {familyModal && family.length > 0 &&
                    <InfoFamilyModal
                        controlModal={this.handleControlModal.bind(this)}
                        open={familyModal}
                    />
                    }

                    {supplementaryDataModal && supplementaryData.hasOwnProperty("id") &&
                    <InfoSupplementaryDataModal
                        controlModal={this.handleControlModal.bind(this)}
                        open={supplementaryDataModal}
                    />
                    }
                    {/*<StatesModal
                    controlModal={this.handleControlModal.bind(this)}
                    open={statesCreditModal}
                    credit={credit}
                    changeState={this.handleInit.bind(this)}
                />*/}

                    {statesCreditModal && (
                        <StatesModal
                            controlModal={this.handleControlModal.bind(this)}
                            open={statesCreditModal}
                            credit={credit}
                            changeState={this.handleChangeCredit.bind(this)}
                            handleGetInfoProp={() => {
                                this.handleGetInfo(credit.id, 'onerosoNotification');
                                this.handleFocus(credit);
                            }}
                        />
                    )}

                    {policyModal && policy.hasOwnProperty("id") && (
                        <DatePolicyModal
                            controlModal={this.handleControlModal.bind(this)}
                            open={policyModal}
                        />
                    )}

                    {paymentPlansModal && paymentPlans.length > 0 && (
                        <DateCreditModal
                            controlModal={this.handleControlModal.bind(this)}
                            open={paymentPlansModal}
                            paymentPlans={paymentPlans}
                        />
                    )}

                    {documentsModal && (
                        <DocumentsModal
                            controlModal={this.handleControlModal.bind(this)}
                            open={documentsModal}
                            onChange={this.handleInit.bind(this)}
                        />
                    )}

                    {detailCreditModal && (
                        <DetailCreditModal
                            controlModal={this.handleControlModal.bind(this)}
                            open={detailCreditModal}
                        />
                    )}

                    {search !== "" && (
                        creditsSearch.length > 0
                            ? <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <ReactTable
                                            data={this.state.dataSearch}
                                            filterable
                                            columns={this.state.columns}
                                            defaultPageSize={5}
                                            showPaginationBottom={true}
                                            className="-striped -highlight"
                                            previousText={"Anterior"}
                                            nextText={"Siguiente"}
                                            pageText={"Pagina"}
                                            ofText={"de"}
                                            rowsText={"datos"}
                                            loading={requesting}
                                            loadingText={"Cargando"}
                                            //loading para cargar
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                            : <NotFound/>
                    )}

                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <AssignmentInd/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Mis solicitudes</h4>
                                <br/>
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel
                                                htmlFor="simple-select"
                                                className={classes.selectLabel}
                                            >
                                                {filter}
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                value={filter}
                                                onChange={(input) => this.handleFilter(input.target.value)}
                                                inputProps={{
                                                    name: "simpleSelect",
                                                    id: "simple-select"
                                                }}
                                            >
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value="Todos"
                                                >
                                                    Todos
                                                </MenuItem>
                                                {states.length > 0 && states.map((state, index) => (
                                                    <MenuItem key={index}
                                                              classes={{
                                                                  root: classes.selectMenuItem,
                                                                  selected: classes.selectMenuItemSelected
                                                              }}

                                                              value={state.nombre}
                                                    >
                                                        {state.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </GridItem>

                                    <GridItem xs={12} sm={3} md={3}>
                                        <FormControl fullWidth>
                                            <Button color="primary" className={classes.marginRight}
                                                    onClick={() => this.handleGetCsv()}>
                                                Descargar csv
                                            </Button>
                                        </FormControl>
                                    </GridItem>
                                </GridContainer>
                            </CardHeader>
                            <CardBody>
                                {credits.length <= 0 && <NotFound/>}
                                {credits.length > 0 && <ReactTable
                                    loading={requesting}
                                    data={this.state.data}
                                    filterable
                                    columns={this.state.columns}
                                    defaultPageSize={1}
                                    showPaginationBottom={true}
                                    className="-striped -highlight"
                                    previousText={"Anterior"}
                                    nextText={"Siguiente"}
                                    pageText={"Página"}
                                    ofText={"de"}
                                    showPageSizeOptions={false}
                                    showPageJump={false}
                                    onFilteredChange={(value) => this.handleFilterTable(value)}
                                    //loading para cargar
                                    manual
                                    noDataText={"No hay más datos para mostrar"}
                                    NextComponent={(nextText) => {
                                        return (
                                            <button disabled={!hasMore} className={""}
                                                    onClick={() => this.handleGetData(+1)}>
                                                {nextText.children}
                                            </button>
                                        );
                                    }}
                                    PreviousComponent={(previousText) => {
                                        return (
                                            <button disabled={!hasLess} className={""}
                                                    onClick={() => this.handleGetData(-1)}>
                                                {previousText.children}
                                            </button>
                                        );
                                    }}
                                    loadingText={"Cargando"}
                                />}
                            </CardBody>
                        </Card>
                    </GridItem>
                    {((familyModal && error) || (supplementaryDataModal && error) || (personModal && error) || (clientModal && error)) &&
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <AssignmentInd/>
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Completar información</h4>
                                <br/>
                            </CardHeader>
                            <CardBody>
                                {clientModal && error && <FormClient/>}
                                {familyModal && error && <FormFamily/>}
                                {supplementaryDataModal && error && <FormSupplementaryData/>}
                                {personModal && error && <FormPerson/>}
                            </CardBody>
                        </Card>
                    </GridItem>}
                    {this.state.oneroso && <FormOneroso
                        detail={this.state.detailOneroso}
                        title={'Guardar'}/>}
                </GridContainer>
                <div ref={this.container}/>
            </div>
        );
    }
}

//Usar el nombre que se puso arriba aca
MyRequests.propTypes = {
    classes: PropTypes.object
};

const mapStateToProps = state => ({
    client: state.client,
    auth: state.auth,
    state: state.state,
    credit: state.credit,
    documentType: state.documentType,
    oneroso: state.oneroso,
    legalPerson: state.legalPerson,
    naturalPerson: state.naturalPerson,
    familyMember: state.familyMember,
    supplementaryData: state.supplementaryData
});

const connected = connect(mapStateToProps, {
    creditsGetRequesting,
    statesCreditGetRequesting,
    creditFocus,
    creditControlModal,
    creditClientRequesting,
    creditPolicyRequesting,
    creditPaymentPlansRequesting,
    creditDocumentsRequesting,
    documentTypesGetRequesting,
    onerosoGetRequesting,
    creditChangeStateRequesting,
    creditPersonRequesting,
    creditFamilyRequesting,
    creditSupplementaryDataRequesting,
    creditsTableFilter,
    creditsSearchError,
    clientCleanValues,
    creditCleanValues,
    clientFindIdentificationSuccess
})(withStyles(styles)(MyRequests));
// Y aca ...
export default connected;
