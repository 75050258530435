import { all, fork } from "redux-saga/effects";
import LoginSagas from "./auth/login/sagas";
import LogoutSagas from "./auth/logout/sagas";
import RegisterSagas from "./auth/register/sagas";
import ClientSagas from "./auth/client/sagas";
import AuthSagas from "./auth/user/sagas";
import StateSagas from "./state/sagas";
import CitySagas from "./city/sagas";
import IdentificationTypeSagas from "./identificationType/sagas";
import DocumentTypeSagas from "./documentType/sagas";
import DocumentSagas from "./document/sagas";
import OnerosoSagas from './oneroso/sagas';
import NaturalPerson from './naturalPerson/sagas';
import LegalPerson from './legalPerson/sagas';
import FamilyMember from './familyMember/sagas';
import SupplementaryData from './supplementaryData/sagas';
import CreditSagas from "./credit/sagas";
import ClientsSagas from "./client/sagas";
import ConfigurationSagas from "./configuration/sagas";
import SureTypeFeatureSagas from "./sureTypeFeature/sagas";
import InsuranceSagas from "./insurance/sagas";
import SureTypeSagas from "./sureType/sagas";
import FeatureSagas from "./feature/sagas";
import PolicySagas from "./policy/sagas";
import PaymentPlanSagas from "./paymentPlan/sagas";
import VerifyEmailSagas from "./auth/verifyEmail/sagas";
import ForgotSagas from "./auth/forgot/sagas";

export function* IndexSagas() {
	yield all([
		fork(LoginSagas),
		fork(LogoutSagas),
		fork(RegisterSagas),
		fork(ClientSagas),
		fork(AuthSagas),
		fork(StateSagas),
		fork(CitySagas),
		fork(IdentificationTypeSagas),
		fork(DocumentTypeSagas),
		fork(DocumentSagas),
		fork(OnerosoSagas),
		fork(NaturalPerson),
		fork(LegalPerson),
		fork(FamilyMember),
		fork(SupplementaryData),
		fork(CreditSagas),
		fork(ClientsSagas),
		fork(ConfigurationSagas),
		fork(SureTypeFeatureSagas),
		fork(InsuranceSagas),
		fork(SureTypeSagas),
		fork(FeatureSagas),
		fork(PolicySagas),
		fork(PaymentPlanSagas),
		fork(VerifyEmailSagas),
		fork(ForgotSagas)
	]);
}