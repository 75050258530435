import {call, put, all, takeEvery} from "redux-saga/effects";
import {handleApiErrors} from "../../commons/errors/apiErrors";
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import React from "react";
import {Redirect} from "react-router-dom";
import {reset} from "redux-form";
import {
    creditAgentError,
    creditAgentSuccess,
    creditChangeStateError,
    creditChangeStateSuccess,
    creditClientError,
    creditClientSuccess,
    creditCreateError,
    creditCreateSuccess,
    creditClientRegister,
    creditDocumentsError,
    creditDocumentsSuccess, creditGetError, creditGetSuccess,
    creditPaymentPlansError,
    creditPaymentPlansSuccess,
    creditPolicyError,
    creditPolicySuccess,
    creditResetStates,
    creditsClientGetError,
    creditsClientGetSuccess,
    creditsGetError,
    creditsGetSuccess,
    creditsSearchError,
    creditsSearchSuccess,
    creditsUserGetError,
    creditsUserGetSuccess, creditUpdateError, creditUpdateSuccess, planPaymentsCreditReset,
    creditPersonSuccess,
    creditPersonError,
    creditFamilySuccess,
    creditFamilyError,
    creditSupplementaryDataSuccess,
    creditSupplementaryDataError
} from "./actions";
import { clientFillData } from './../client/actions';
import { legalPersonFillData , legalPersonCleanValues } from './../legalPerson/actions';
import { naturalPersonFillData , naturalPersonCleanValues } from './../naturalPerson/actions';
import { familyMemberFillData , familyMemberCleanValues } from './../familyMember/actions';
import { supplementaryDataFillData , supplementaryDataCleanValues } from './../supplementaryData/actions';
import {clientResetFocus} from "../client/actions";
import {policyResetFocus} from "../policy/actions";
import {
    CREDIT_AGENT_REQUESTING,
    CREDIT_CHANGE_STATE_REQUESTING,
    CREDIT_CLIENT_REQUESTING, CREDIT_CREATE_REQUESTING,
    CREDIT_DOCUMENTS_REQUESTING, CREDIT_GET_REQUESTING,
    CREDIT_PAYMENT_PLANS_REQUESTING,
    CREDIT_POLICY_REQUESTING, CREDIT_UPDATE_REQUESTING,
    CREDIT_PERSON_REQUESTING,
    CREDIT_FAMILY_REQUESTING,
    CREDIT_SUPPLEMENTARY_DATA_REQUESTING,
    CREDITS_CLIENT_GET_REQUESTING,
    CREDITS_GET_REQUESTING,
    CREDITS_SEARCH_REQUESTING,
    CREDITS_USER_GET_REQUESTING,
} from "./constants";

const baseUrl = `${process.env.REACT_APP_API_URL}/credits`;

const showError = (error) => {
    let toastOptions = {
        component: (
            <ErrorComponent message={error}/>
        )
    };
    toastr.error("Error", toastOptions);
};

const creditsUserGetApi = (token, userId) => {
    return fetch(`${baseUrl}/user/${userId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 400)
                throw [json.data];
            if (json.code === 422)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditsUserGetFlow(action) {
    try {
        const {token, userId} = action;
        const credits = yield call(creditsUserGetApi, token, userId);
        if (credits.length === 0)
            toastr.info("Sin creditos", "El agente no cuenta con creditos.");
        yield put(creditsUserGetSuccess(credits));
    } catch (error) {
        yield put(creditsUserGetError(error));
        showError(error);
    }
}

const creditsClientGetApi = (token, clientId) => {
    return fetch(`${baseUrl}/client/${clientId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 400)
                throw [json.data];
            if (json.code === 422)
                throw json.data;
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditsClientGetFlow(action) {
    try {
        const {token, clientId} = action;
        const credits = yield call(creditsClientGetApi, token, clientId);
        if (credits.length === 0)
            toastr.info("Sin creditos", "El cliente no cuenta con creditos.");
        yield put(creditsClientGetSuccess(credits));
    } catch (error) {
        yield put(creditsClientGetError(error));
        showError(error);
    }
}

const creditsGetApi = (token, filter, paginate) => {
    return fetch(`${baseUrl}/filter/${filter}?page=${paginate}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditsGetFlow(action) {
    try {
        const {token, filter, paginate} = action;
        const credits = yield call(creditsGetApi, token, filter, paginate);
        yield put(creditsGetSuccess(credits));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditsGetError(error));
    }
}

const creditsSearchApi = (token, values) => {
    let body = {
        search: values.search
    };
    return fetch(`${baseUrl}/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditsSearchFlow(action) {
    try {
        const {token, values} = action;
        const credits = yield call(creditsSearchApi, token, values);
        if (credits.length === 0)
            toastr.info("Sin resultados", "No se encontraron resultados para su parametro de busqueda,");
        yield put(creditsSearchSuccess(credits));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditsSearchError(error));
        showError(error);
    }
}

const creditChangeStateApi = (token, creditId, name) => {
    return fetch(`${baseUrl}/${name}/${creditId}`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditChangeStateFlow(action) {
    try {
        const {token, creditId, name} = action;
        const credit = yield call(creditChangeStateApi, token, creditId, name);
        yield put(creditChangeStateSuccess(credit));
        //toastr.success("Credito actualizado", "El credito fue actualizado con exito.");
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditChangeStateError(error));
        showError(error);
    }
}

const creditClientApi = (token, creditId) => {
    return fetch(`${baseUrl}Client/${creditId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditClientFlow(action) {
    try {
        const {token, creditId} = action;
        const client = yield call(creditClientApi, token, creditId);
        yield put(creditClientSuccess(client));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditClientError(error));
        showError(error);
    }
}

const creditPolicyApi = (token, creditId) => {
    return fetch(`${baseUrl}Policy/${creditId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditPolicyFlow(action) {
    try {
        const {token, creditId} = action;
        const policy = yield call(creditPolicyApi, token, creditId);
        yield put(creditPolicySuccess(policy));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditPolicyError(error));
        showError(error);
    }
}

const creditPaymentPlansApi = (token, creditId) => {
    return fetch(`${baseUrl}PaymentPlans/${creditId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditPaymentPlansFlow(action) {
    try {
        const {token, creditId} = action;
        const paymentPlans = yield call(creditPaymentPlansApi, token, creditId);
        if (paymentPlans.length === 0)
            toastr.info("Sin planes de pago", "El credito no cuenta con planes de pago registrados.");
        yield put(creditPaymentPlansSuccess(paymentPlans));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditPaymentPlansError(error));
        showError(error);
    }
}

const creditAgentApi = (token, creditId) => {
    return fetch(`${baseUrl}Agent/${creditId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditAgentFlow(action) {
    try {
        const {token, creditId} = action;
        const agent = yield call(creditAgentApi, token, creditId);
        yield put(creditAgentSuccess(agent));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditAgentError(error));
        showError(error);
    }
}

const creditDocumentsApi = (token, creditId) => {
    return fetch(`${baseUrl}Documents/${creditId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* clientExistsFillData(client){
    if (client.hasOwnProperty("persona")){
        if (client.persona.hasOwnProperty("tipo_persona") && client.persona.hasOwnProperty("compañia")){
            if (client.persona.tipo_persona.id == 1)
                yield put(naturalPersonFillData(client.persona, client.persona.compañia));

            if (client.persona.tipo_persona.id == 2)
                yield put(legalPersonFillData(client.persona.compañia));
        }
    }

    if (client.hasOwnProperty("familyMembers"))
        yield put(familyMemberFillData(client.familyMembers[0]));

    if (client.hasOwnProperty("supplementaryData"))
        yield put(supplementaryDataFillData(client.supplementaryData));
};

function* creditDocumentsFlow(action) {
    try {
        const {token, creditId} = action;
        const documents = yield call(creditDocumentsApi, token, creditId);
        if (documents.length === 0)
            toastr.info("Sin documentos", "El credito no cuenta con documentos registrados.");
        yield put(creditDocumentsSuccess(documents));
        yield put(creditResetStates());
    } catch (error) {
        yield put(creditDocumentsError(error));
        showError(error);
    }
}

const creditCreateApi = (token, values, policy, initialFeed, totalCredit, client, planPayments , oldCustomer) => {
    let body = new FormData();
    body.append("fees", values.cuotas || "");
    body.append("initialFee", initialFeed || 0);
    body.append("totalCredit", totalCredit || "");
    body.append("policy", policy.id || "");
    body.append("startDate", policy.fecha_inicio || "");
    body.append("oldCustomer" , oldCustomer ? 1 : 0);
    body.append("client_name", client.name);
    body.append("client_id", client.id);
    body.append("client_identification_type", client.type);
    body.append("planPayments", JSON.stringify(planPayments));
    Object.keys(values).map(key => {
        if (key.indexOf("document_") >= 0) {
            body.append(key.split("document_")[1].replace(/\s/g, ""), values[key][0].file);
        }
    });
    return fetch(`${baseUrl}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        },
        body: body
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            if (!json.code)
                return json.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditCreateFlow(action) {
    try {
        const {token, values, policy, initialFeed, totalCredit, client, planPayments , oldCustomer} = action;
        const credit = yield call(creditCreateApi, token, values, policy, initialFeed, totalCredit, client, planPayments , oldCustomer);
        if (credit.clientExists){
            yield put(creditClientRegister(credit));
            yield call(clientExistsFillData , credit.clientExists);
            toastr.success("Credito creado", `El credito ${credit.credit.id} fue creado con exito.`);
        }
        else{
            yield put(creditResetStates());
            yield put(clientResetFocus());
            yield put(creditCreateSuccess(credit));
            toastr.success("Credito creado", `El credito ${credit.id} fue creado con exito.`);
        }
        // yield put(policyResetFocus());
        // reset("credit");
    } catch (error) {
        yield put(creditCreateError(error));
        showError(error);
    }
}

const creditUpdateApi = (token, creditId, values) => {
    let body = {
        client: values.clientId,
        numberPay: values.numberPay,
    };
    return fetch(`${baseUrl}/${creditId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};


const creditPersonApi = (token, creditId) => {
    return fetch(`${baseUrl}Person/${creditId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        method: 'GET'
    })
    .then(response => response.json())
    .then(json => {
        if (json.code === 422)
            throw json.data;
        if (json.code === 400)
            throw [json.data];
        if (json.code === 200)
            return json.data.data;
        throw json.data;
    }).catch((error) => {
        throw error;
    });
};


const creditFamilyApi = (token, creditId) => {
    return fetch(`${baseUrl}Family/${creditId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        method: 'GET'
    })
    .then(response => response.json())
    .then(json => {
        if (json.code === 422)
            throw json.data;
        if (json.code === 400)
            throw [json.data];
        if (json.code === 200)
            return json.data.data;
        throw json.data;
    }).catch((error) => {
        throw error;
    });
};


const creditSupplementaryDataApi = (token, creditId) => {
    return fetch(`${baseUrl}SupplementaryData/${creditId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        method: 'GET'
    })
    .then(response => response.json())
    .then(json => {
        if (json.code === 422)
            throw json.data;
        if (json.code === 400)
            throw [json.data];
        if (json.code === 200)
            return json.data.data;
        throw json.data;
    }).catch((error) => {
        throw error;
    });
};

function* creditUpdateFlow(action) {
    try {
        const {token, creditId, values} = action;
        const credit = yield call(creditUpdateApi, token, creditId, values);
        yield put(creditUpdateSuccess(credit));
        yield put(creditResetStates());
        yield put(clientResetFocus());
        yield put(policyResetFocus());
        yield put(planPaymentsCreditReset());
        toastr.success("Solicitud enviada", `Solicitud enviada para validacion`);
        reset("credit");
    } catch (error) {
        yield put(creditUpdateError(error));
        showError(error);
    }
}


const creditGetApi = (token, id) => {
    return fetch(`${baseUrl}/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
        .then(handleApiErrors)
        .then(response => response.json())
        .then(json => {
            if (json.code === 422)
                throw json.data;
            if (json.code === 400)
                throw [json.data];
            if (json.code === 200)
                return json.data.data;
            throw json.data;
        }).catch((error) => {
            throw error;
        });
};

function* creditGetFlow(action) {
    try {
        const {token, id} = action;
        const credit = yield call(creditGetApi, token, id);
        yield put(creditGetSuccess(credit));
    } catch (error) {
        yield put(creditGetError());
    }
}


function* creditPersonFlow(action){
    try{
        const { token , creditId } = action;
        yield put(supplementaryDataCleanValues());
        yield put(legalPersonCleanValues());
        yield put(naturalPersonCleanValues());
        const client = yield call(creditPersonApi , token , creditId);
        yield put(clientFillData(client));
        yield call(clientExistsFillData , client);
        yield put(creditPersonSuccess());
    }catch (error){
        yield put(creditPersonError(error));
        showError(error);
    }
}


function* creditFamilyFlow(action){
    try{
        const { token , creditId } = action;
        //yield put(familyMemberCleanValues());
        const client = yield call(creditFamilyApi, token , creditId);
        //yield put(familyMemberFillData(client.familyMembers[0]));
        yield put(creditFamilySuccess(client.familyMembers, creditId));
        yield put(clientFillData(client));
    }catch (error){
        yield put(creditFamilyError(error));
        showError(error);
    }
}

function* creditSupplementaryDataFlow(action){
    try{
        const { token , creditId } = action;
        yield put(supplementaryDataCleanValues());
        const client = yield call(creditSupplementaryDataApi, token , creditId);
        yield put(supplementaryDataFillData(client.supplementaryData));
        yield put(creditSupplementaryDataSuccess());
        yield put(clientFillData(client));
    }catch (error){
        yield put(creditSupplementaryDataError(error));
        showError(error);
    }
}


function* creditWatcher() {
    yield all([
        takeEvery(CREDITS_USER_GET_REQUESTING, creditsUserGetFlow),
        takeEvery(CREDITS_CLIENT_GET_REQUESTING, creditsClientGetFlow),
        takeEvery(CREDITS_GET_REQUESTING, creditsGetFlow),
        takeEvery(CREDITS_SEARCH_REQUESTING, creditsSearchFlow),
        takeEvery(CREDIT_CHANGE_STATE_REQUESTING, creditChangeStateFlow),
        takeEvery(CREDIT_CLIENT_REQUESTING, creditClientFlow),
        takeEvery(CREDIT_POLICY_REQUESTING, creditPolicyFlow),
        takeEvery(CREDIT_PAYMENT_PLANS_REQUESTING, creditPaymentPlansFlow),
        takeEvery(CREDIT_AGENT_REQUESTING, creditAgentFlow),
        takeEvery(CREDIT_DOCUMENTS_REQUESTING, creditDocumentsFlow),
        takeEvery(CREDIT_CREATE_REQUESTING, creditCreateFlow),
        takeEvery(CREDIT_UPDATE_REQUESTING, creditUpdateFlow),
        takeEvery(CREDIT_GET_REQUESTING,creditGetFlow),
        takeEvery(CREDIT_PERSON_REQUESTING,creditPersonFlow),
        takeEvery(CREDIT_FAMILY_REQUESTING,creditFamilyFlow),
        takeEvery(CREDIT_SUPPLEMENTARY_DATA_REQUESTING,creditSupplementaryDataFlow)
    ]);
}

export default creditWatcher;
