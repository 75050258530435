import {
    ME_GET_ERROR,
    ME_GET_REQUESTING,
    ME_GET_SUCCESS,
    ME_UPDATE_ERROR,
    ME_UPDATE_REQUESTING,
    ME_UPDATE_SUCCESS
} from "./constants";

export const meGetRequesting = (token) => ({
    type: ME_GET_REQUESTING,
    token
});

export const meGetSuccess = (user) => ({
    type: ME_GET_SUCCESS,
    user,
});

export const meGetError = (error) => ({
    type: ME_GET_ERROR,
    error,
});

export const meUpdateRequesting = (token, values) => ({
    type: ME_UPDATE_REQUESTING,
    token, values
});

export const meUpdateSuccess = (user) => ({
    type: ME_UPDATE_SUCCESS,
    user
});

export const meUpdateError = (error) => ({
    type: ME_UPDATE_ERROR,
    error
});