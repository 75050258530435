/*!=========================================================
* Developer DigitalParchapp V.1
=========================================================*/

// Component
import MyRequests from "views/myRequests/myRequests";
// Page
import quote from "views/Pages/cuote.jsx";
import profile from "views/Pages/profile";
// core
// @material-ui/icons
import Done from "@material-ui/icons/Done";
import Date from "@material-ui/icons/DateRange";
import Profile from "@material-ui/icons/Contacts";

var dashRoutes = [

    ///////////
    {
        path: "/Perfil",
        name: "Tu perfil",
        component: profile,
        icon: Profile,
        layout: "/admin",
        visible: true
    },
    {
        path: "/cotizador",
        name: "Cotizador",
        component: quote,
        icon: Done,
        layout: "/admin",
        visible: true
    },
    {
        path: "/misSolicitudes",
        name: "Mis Solicitudes",
        icon: Date,
        component: MyRequests,
        layout: "/admin",
        visible: true
    },


];
export default dashRoutes;
