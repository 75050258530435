import {call, put, all, takeEvery} from 'redux-saga/effects';
import {ErrorComponent} from "../../commons/errors/errors";
import {toastr} from "react-redux-toastr";
import React from "react";
import {Redirect} from "react-router-dom";
import {reset} from "redux-form";
import {
	legalPersonCreateSuccess,
	legalPersonCreateError,
	legalPersonResetStates,
	legalPersonUpdateSuccess,
	legalPersonUpdateError,
	legalPersonFillDataSuccess,
	legalPersonFillDataError 
} from './actions';

import {
	LEGAL_PERSON_CREATE_REQUESTING,
	LEGAL_PERSON_UPDATE_REQUESTING,
	LEGAL_PERSON_FILL_DATA
} from './constants';

const baseUrl = `${process.env.REACT_APP_API_URL}/person/legal`;

const showError = (error) => {
    let toastOptions = {
        component: (
            <ErrorComponent message={error}/>
        )
    };
    toastr.error("Error", toastOptions);
};

const legalPersonCreateApi = (token , values , client) => {
	let body = {
		'name' : values.nombreDeLaEmpresa,
		'nit' : values.nit,
		'company_type_id' : values.tipo,
		'activity' : values.actividadEconomica,
		'ciiu' : values.ciiu,
		'total_monthly_income' : parseInt(values.totalIngresosMensuales.split('.').join('')),
		'total_monthly_expenses' : parseInt(values.totalEgresosMensuales.split('.').join('')),
		'number_of_employees' :  values.numeroDeEmpleados,
		'address_office' : values.direccionEmpresa,
		'neighborhood' : values.barrio,
		'departament' : values.departamento,
		'city' : values.ciudad,
		'landline_office' : values.telefono,
		'phone' : values.celular,
		'email' : values.correo,
		'person_type_id': values.personType
	};

	return fetch(`${baseUrl}/${client}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
	.then(response => response.json())
	.then(json => {
		if (json.code === 422)
			throw json.data;
		if (json.code === 400)
			throw [json.data];
		if (json.code === 200)
			return json.data.data;
		if (!json.code)
			return json.data;
		throw json.data;
	}).catch((error) => {
		throw error;
	});

};


const legalPersonUpdateApi = (token , values , client , id) => {
	let body = {
		'name' : values.nombreDeLaEmpresa,
		'nit' : values.nit,
		'company_type_id' : values.tipo,
		'activity' : values.actividadEconomica,
		'ciiu' : values.ciiu,
		'total_monthly_income' : parseInt(values.totalIngresosMensuales.split('.').join('')),
		'total_monthly_expenses' : parseInt(values.totalEgresosMensuales.split('.').join('')),
		'number_of_employees' :  values.numeroDeEmpleados,
		'address_office' : values.direccionEmpresa,
		'neighborhood' : values.barrio,
		'departament' : values.departamento,
		'city' : values.ciudad,
		'landline_office' : values.telefono,
		'phone' : values.celular,
		'email' : values.correo,
		'person_type_id': values.personType
	};

	return fetch(`${baseUrl}/${id}/${client}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
	.then(response => response.json())
	.then(json => {
		if (json.code === 422)
			throw json.data;
		if (json.code === 400)
			throw [json.data];
		if (json.code === 200)
			return json.data.data;
		if (!json.code)
			return json.data;
		throw json.data;
	}).catch((error) => {
		throw error;
	});

};

function* legalPersonCreateFlow(action){
	try{
		const {token, values, client} = action;
		const person = yield call(legalPersonCreateApi, token, values, client);
		yield put(legalPersonResetStates());
		yield put(legalPersonCreateSuccess(person));
		toastr.success("Registrado", "Información personal registrada exitosamente.");
	}catch (error) {
		yield put(legalPersonCreateError(error));
		showError(error);
	}
}

function* legalPersonUpdateFlow(action){
	try{
		const {token, values , client, id} = action;
		const person = yield call(legalPersonUpdateApi, token , values, client , id);
		yield put(legalPersonResetStates());
		yield put(legalPersonUpdateSuccess(person));
		toastr.success("Actualizado", "Información personal actualizada exitosamente.");
	}catch (error) {
		yield put(legalPersonUpdateError(error));
		showError(error);
	}
}

function* legalPersonFillDataFlow(action){
	try{
		const { person } = action;
		const legalPerson = {
			id: person.id,
			nombreDeLaEmpresa: person.nombre,
			nit: person.nit,
			tipo: person.tipo_compañia.id,
			tipo_persona: 2,
			actividadEconomica: person.actividad,
			ciiu: person.ciiu,
			totalIngresosMensuales: person.total_ingresos_mensuales,
			totalEgresosMensuales: person.total_egresos_mensuales,
			numeroDeEmpleados: person.numero_de_empleados,
			direccionEmpresa: person.direccion,
			barrio: person.barrio,
			departamento: person.departamento,
			ciudad: person.ciudad,
			telefono: person.telefono,
			celular: person.celular,
			correo: person.correo
		};
		yield put(legalPersonFillDataSuccess(legalPerson));
	}catch (error){
		yield put(legalPersonFillDataError(error));
	}
}

function* legalPersonWatcher(){
	yield all([
		takeEvery(LEGAL_PERSON_CREATE_REQUESTING, legalPersonCreateFlow),
		takeEvery(LEGAL_PERSON_UPDATE_REQUESTING, legalPersonUpdateFlow),
		takeEvery(LEGAL_PERSON_FILL_DATA, legalPersonFillDataFlow)
	]);
}

export default legalPersonWatcher;