import React from "react";
import { call, put, all, takeEvery } from "redux-saga/effects";
import { handleApiErrors } from "../../commons/errors/apiErrors";
import { ErrorComponent } from "../../commons/errors/errors";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import {
	clientChangeStateError,
	clientChangeStateSuccess,
	clientControlModal,
	clientCreateError,
	clientCreateSuccess, clientFindIdentificationError, clientFindIdentificationSuccess,
	clientResetStates,
	clientsGetError,
	clientsGetSuccess, clientsSearchError, clientsSearchSuccess, clientUpdateError, clientUpdateSuccess,
	clientFillDataSuccess, clientFillDataError 
} from "./actions";
import {
	CLIENT_CHANGE_STATE_REQUESTING,
	CLIENT_CREATE_REQUESTING, CLIENT_FIND_IDENTIFICATION_REQUESTING,
	CLIENT_UPDATE_REQUESTING,
	CLIENTS_GET_REQUESTING, CLIENTS_SEARCH_REQUESTING,
	CLIENT_FILL_DATA
} from "./constants";

moment.locale("es");

const clientUrl = `${process.env.REACT_APP_API_URL}/clients`;

const showError = (error) => {
	let toastOptions = {
		component: (
			<ErrorComponent message={error}/>
		)
	};
	toastr.error("Error", toastOptions);
};

const clientsGetApi = (token, filter, paginate) => {
	return fetch(`${clientUrl}/filter/${filter}?page=${paginate}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* clientsGetFlow(action) {
	try {
		const { token, filter, paginate } = action;
		const clients = yield call(clientsGetApi, token, filter, paginate);
		yield put(clientsGetSuccess(clients));
		yield put(clientResetStates());
	} catch (error) {
		yield put(clientsGetError(error));
		// showError(error);
	}
}

const clientCreateApi = (token, values, typeIdentification, creditId) => {
	let body = {
		firstName: values.nombres || "",
		lastName: values.apellidos || "",
		identification: values.identificacion || "",
		identificationType: typeIdentification || "",
		email: values.correo || "",
		phone: values.telefono || "",
		address: values.direccion || "",
		city: values.ciudad || "",
		dateBirth: values.hasOwnProperty("fecha_nacimiento") ? moment(values.fecha_nacimiento).format("YYYY-MM-DD") : ""
	};
	return fetch(`${clientUrl}/${creditId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* clientCreateFlow(action) {
	try {
		const { token, values, typeIdentification, creditId } = action;
		const client = yield call(clientCreateApi, token, values, typeIdentification, creditId);
		yield put(clientCreateSuccess(client));
		yield put(clientControlModal("addModal"));
		yield put(clientResetStates());
		toastr.success("Cliente creado", "El cliente fue creado con exito.");
	} catch (error) {
		yield put(clientCreateError(error));
		showError(error);
	}
}

const clientUpdateApi = (token, values) => {
	let body = {
		firstName: values.nombres || "",
		lastName: values.apellidos || "",
		identification: values.identificacion || "",
		identificationType: values.tipo_identificacion.hasOwnProperty("id") ? values.tipo_identificacion.id : values.tipo_identificacion,
		email: values.correo || "",
		phone: values.telefono || "",
		address: values.direccion || "",
		city: values.ciudad || "",
		dateBirth: values.fecha_nacimiento ? moment(values.fecha_nacimiento).format("YYYY-MM-DD") : null
	};
	return fetch(`${clientUrl}/${values.id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* clientUpdateFlow(action) {
	try {
		const { token, values } = action;
		const client = yield call(clientUpdateApi, token, values);
		//yield put(clientUpdateSuccess(client));
		yield put(clientControlModal("editModal"));
		yield put(clientResetStates());
		toastr.success("Cliente actualizado", "El cliente fue actualizado con exito.");
	} catch (error) {
		yield put(clientUpdateError(error));
		showError(error);
	}
}

const clientChangeStateApi = (token, clientId) => {
	return fetch(`${clientUrl}/state/${clientId}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`
		}
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* clientChangeStateFlow(action) {
	try {
		const { token, clientId } = action;
		const client = yield call(clientChangeStateApi, token, clientId);
		yield put(clientChangeStateSuccess(client));
		yield put(clientResetStates());
		toastr.success(`Cliente ${client.estado.nombre}`, `El cliente ha sido ${client.estado.nombre} con exito.`);
	} catch (error) {
		yield put(clientChangeStateError(error));
		showError(error);
	}
}

const clientsSearchApi = (token, values) => {
	let body = {
		search: values.search
	};
	return fetch(`${clientUrl}/search`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* clientsSearchFlow(action) {
	try {
		const { token, values } = action;
		const clients = yield call(clientsSearchApi, token, values);
		yield put(clientsSearchSuccess(clients));
		yield put(clientResetStates());
	} catch (error) {
		yield put(clientsSearchError(error));
		showError(error);
	}
}

const clientFindIdentificationApi = (token, values, typeIdentification , creditId) => {
	let body = {
		identificationType: typeIdentification,
		identification: values.identificacion
	};
	return fetch(`${clientUrl}FindIdentification/${creditId}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
		body: JSON.stringify(body)
	})
		.then(handleApiErrors)
		.then(response => response.json())
		.then(json => {
			if (json.code === 422)
				throw json.data;
			if (json.code === 400)
				throw [json.data];
			if (json.code === 200)
				return json.data.data;
			throw json.data;
		}).catch((error) => {
			throw error;
		});
};

function* clientFindIdentificationFlow(action) {
	try {
		const { token, values, typeIdentification , creditId } = action;
		const client = yield call(clientFindIdentificationApi, token, values, typeIdentification , creditId);
		yield put(clientFindIdentificationSuccess(client));
		yield put(clientResetStates());
	} catch (error) {
		if (error[0] !== "El cliente no se encuentra registrado.") {
			showError(error);
			yield put(clientFindIdentificationError(""));
		} else {
			yield put(clientFindIdentificationError(error));
		}
	}
}


function* clientFillDataFlow(action){
	try{
		const { client } = action;
		yield put(clientFillDataSuccess(client));
	}catch (error){
		yield put(clientFillDataError(error));
	}
}

function* clientWatcher() {
	yield all([
		takeEvery(CLIENTS_GET_REQUESTING, clientsGetFlow),
		takeEvery(CLIENT_CREATE_REQUESTING, clientCreateFlow),
		takeEvery(CLIENT_UPDATE_REQUESTING, clientUpdateFlow),
		takeEvery(CLIENT_CHANGE_STATE_REQUESTING, clientChangeStateFlow),
		takeEvery(CLIENTS_SEARCH_REQUESTING, clientsSearchFlow),
		takeEvery(CLIENT_FIND_IDENTIFICATION_REQUESTING, clientFindIdentificationFlow),
		takeEvery(CLIENT_FILL_DATA, clientFillDataFlow)
	]);
}

export default clientWatcher;
